<style lang="less" scoped >

  /deep/ .sel .el-select-dropdown__item {
    height:auto;
  }
.home-cont {
  position: relative;
  width: 100%;
  overflow-x:hidden;
  .hmap {
    width: 100%;
    /*height: 870px;*/
    padding-bottom:140px;
    background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
    opacity: 1;
    border-radius: 0px;
    p {
      text-align: center;
      padding-bottom:80px;
      height: 36px;
      font-size: 36px;
      font-weight: bold;
      line-height: 36px;
      color: #00B7D0;
      opacity: 1;
    }
  }
  .text1 {
    text-align: center;
    width: 960px;
    margin: 0 auto;
    padding: 136px 0;
    background-color: #fff;
    > p:nth-child(1) {
      font-weight: bold;
      color: #00242a;
      font-size: 46px;
    }
    > p:nth-child(2) {
      font-size: 14px;
      font-weight: 300;
      color: #00242a;
      letter-spacing: 1.5em;
      opacity: 0.4;
      margin-top: 20px;
    }
    > p:nth-child(3) {
      margin-top: 60px;
      font-size: 18px;
      font-weight: 300;
      line-height: 38px;
      color: #00242a;
    }

  }
  .ban {
    width: 960px;
    height: 540px;
    margin: 0 auto;
    margin-bottom: 136px;
  }
  .ckgd{
    display:inline-block;text-align:center;height:35px;line-height:35px;width:106px;background: #00B7D0;font-size: 16px;
    font-weight: 300;
    border-radius: 4px;margin-left:85px;
    color: #FFFFFF;
  }
  .head {
    margin-bottom: 80px;
    text-align: center;
    > p:first-child {
      font-size: 36px;
      font-weight: normal;
      color: #00b7d0;
      /*letter-spacing: 10px;*/
      position: relative;
      left: 3px;
    }
    > p:nth-child(2) {
      font-size: 46px;
      font-weight: bold;
      color: #00242a;
    }
  }
  .cooperation {
    width: 100%;
    height: auto;
    background-color: white;
    padding: 136px 0;
    text-align: center;
    /*margin-bottom: 138px;*/

    .items {
      display: flex;
      width: 1200px;
      margin: 0 auto;
      justify-content: space-between;

      > div {
        display:inline-block;
        width: 390px;
        height: 130px;
        border-radius: 8px;
        overflow: hidden;
        font-size: 18px;
        font-weight: 300;
        color: #8f8f8f;
        padding: 40px 24px;
        box-sizing: border-box;
        text-align: left;
        border-top: 10px solid #00b7d0;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        .blue {
          width: 100%;
          height: 10px;
          background: #00b7d0;
        }
        img {
          width: 150px;
          height: 40px;
          margin-left: 0;
          margin-bottom: 10px;
        }
      }
    }
  }
  .tips {
    // padding-top: 136px;
    display: flex;
    justify-content: space-between;
    width: 960px;
    margin: 0 auto;
    margin-top: 140px;
    > div {
      cursor: pointer;
      padding: 26px 0;
      box-sizing: border-box;
      height: 120px;
      width: 19.3%;
      text-align: center;
      font-size: 15px;
      font-weight: 400;
      color: #777777;
      /*margin-bottom: 136px;*/
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
      border-top: 10px solid #2b5c9d;
      overflow: hidden;
      border-radius: 10px;
      .head {
        margin-bottom: 18px;
        font-size: 24px;
        line-height:24px;
        height:24px;
        font-weight: 500;
        color: #3e3e3e;
      }
      .head:nth-child(2) {
        margin-bottom:9px;
      }
      .phone {
        margin-top:9px;
        height: 14px;
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }
    }
    > div:nth-child(2) {
      border-top: 10px solid #0086cd;
    }
    > div:nth-child(3) {
      border-top: 10px solid #7868a7;
    }
    > div:nth-child(4) {
      border-top: 10px solid #f79a6c;
    }
    > div:nth-child(5) {
      border-top: 10px solid #94C47E;
    }
  }
  .map {
    width: 960px;
    margin: 0 auto;
    > div:nth-child(1) {
      display: flex;
      justify-content: space-between;
      .tab {
        width: 350px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        overflow: hidden;
        transition: all, 1s;
        border-top: 10px solid #00b7d0;
        .active {
          border-radius: 10px;
          overflow: hidden;
          height: auto;
          padding-top:15px;
          padding-bottom:15px;
          > div {
            padding-right: 48px;
            display: block;
            font-size: 14px;
            line-height:40px;
            color: #00242a;
            font-weight: normal;
          }
        }
        > div {
          height: 66px;
          font-size: 18px;
          font-weight: bold;
          line-height: 60px;
          color: #00242a;
          text-align: left;
          border-bottom: 1px solid rgba(0, 0, 0, 0.16);
          padding-left: 24px;
          padding-right: 24px;
          box-sizing: border-box;
          position: relative;
          > div {
            display: none;
          }
          img {
            width: 20px;
            height: 9px;
            position: absolute;
            right: 37px;
            top: calc(50% - 4px);
            cursor: pointer;
          }
        }
      }
      .m {
        width: 596px;
        height:470px;
        // height: 100%;
        // background-color: chocolate;
      }
    }
  }
  .join {
    width: 100%;
    margin: 0 auto;
    margin-top: 140px;
    background: linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%);
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 138px;
    .head >p{
      font-weight: bold;
    }
    >div:nth-child(2){
      border-top: 10px solid #00b7d0;
    }
    .item {
      width: 1200px;
      height: 78px;
      line-height: 78px;
      background: white;
      display: flex;
      justify-content: flex-start;
      margin: 0 auto;
      margin-bottom: 2px;
      > div:first-child {
        width: 340px;
        margin-right: 32px;
        margin-left: 60px;
        text-align: left;
      }
      img {
        width: 18px;
        height: 18px;
        margin: auto 0;
        margin-right: 3px;
      }
      .op {
        margin-left: auto;
        margin-right: 60px;
        width: 20px;
        height: 9px;
      }
    }
  }
}
.banner{
  width: 100%;
  height: auto;
}
.descs{
  margin: 0px auto;
  width: 960px;
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  line-height: 38px;
  color: #003F52;
  opacity: 1;
}
.guanggao{
  width: 100%;
  min-width: 1200px;
}
.adds{
  // width: 100%;
  width: 960px;
  margin: 0px auto;
  background: url('../assets/images/about_b/accomplishment-achievement-adult-1059120@2x.png');
  background-size: 100% 100%;
  height: 285px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  border: none !important;

  >div:first-child{
    position: relative;
    box-sizing: border-box;
    padding-left:23px;
    padding-right:38px;
    width: 732px;
    height: 66px;
    line-height:66px;
    font-size:18px;
    color: #00242A;
    background: rgba(255,255,255,0.9);
    border-radius: 10px;
    span {
      position: absolute;
      top:0;
      left:23px;
      width: 70%;
    }
    img {
      position: absolute;
      top:50%;
      margin-top:-5px;
      right:38px;
    }
    .select_content {
      position: absolute;
      box-sizing: border-box;
      top:68px;
      left: 0;
      height: 400px;
      width:732px;
      background: white;
      border-radius: 10px;
      overflow-y: auto;
      .sel_item {
        box-sizing: border-box;
        width:100%;

        .sel_item_title {
          width:100%;
          padding-left:23px;
          padding-right:30px;
          box-sizing: border-box;
          height:66px;
          display:flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          span:nth-child(1) {
            position: static;
            width:80%;
          }
          span:nth-child(1):hover {
            cursor:auto;
          }
          span:nth-child(2) {
            position: static;
            width:20%;
            text-align: right;
          }
          span:nth-child(2):hover {
          cursor: pointer;
          }
        }
        .sel_item_title:hover {
          background: #cccccc;
        }

        .sel_item_content {
          width:100%;
          padding-left:23px;
          padding-right:30px;
          padding-bottom: 20px;
          box-sizing: border-box;
          .duty_title, .require_title{
            height: 14px;
            font-size: 14px;
            font-weight: bold;
            line-height: 14px;
            color: #003F52;
            opacity: 1;
          }
          .duty_item, .require_item {
            margin-top:20px;
            font-size: 14px;
            font-weight: 300;
            line-height: 18px;
            color: #003F52;
            opacity: 1;
          }
          .require_title {
            margin-top:20px;
          }

        }
        .sel_item_content:hover {
          background: #cccccc;
        }
      }
      .sel_item:hover {
        background: #ccc;
      }
    }
    /*/deep/.el-select{*/
    /*  width: 100%;*/
    /*  height: 100%;*/
    /*  background-color: transparent !important;*/
    /*}*/
    /*/deep/.el-input{*/
    /*  width: 100%;*/
    /*  height: 100%;*/
    /*  background-color: transparent !important;*/
    /*}*/
    /*/deep/.el-input__inner{*/
    /*  width: 100%;*/
    /*  height: 100%;*/
    /*  background-color: transparent !important;*/
    /*  border: none;*/
    /*  font-size: 24px;*/
    /*  font-weight: 400;*/
    /*  line-height: 0px;*/
    /*  color: #00242A;*/
    /*}*/

    /*/deep/ .el-select .el-select-dropdown__item {*/
    /*  height: 66px;*/
    /*  display:block;*/
    /*}*/

    /*/deep/ .el-select .el-select-dropdown__item:hover {*/
    /*  height:auto;*/

    /*}*/
  }
  /*.el-select-dropdown__item.selected {*/
  /*  width: 100%;*/
  /*}*/
  .btn{
    width: 142px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    background: rgba(0,183,208,0.9);
    border-radius: 10px;
    margin-left: 7px;
    font-size: 24px;
    font-weight: 400;
    color: #FFFFFF;
  }
}

.stabs{
  width: 960px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  padding-bottom: 128px;
  div{
    flex:1;
    /*font-size: 30px;*/
    /*font-weight: 300;*/
    /*line-height: 30px;*/
    /*color: #878787;*/
    /*opacity: 1;*/
    /*width: 0px;*/
    /*height: auto;*/
    cursor: pointer;
    position: relative;

    height: 30px;
    font-size: 30px;
    font-weight: 300;
    line-height: 0px;
    color: #878787;
    opacity: 1;
  }
  .active{

    font-size: 30px;
    font-weight: bold;
    color: #00242A;
  }
  .active::before{
    content: '';
    position: absolute;
    bottom: -36px;
    left: 50%;
    margin-left: -23px;
    width: 40px;
    height: 10px;
    background: #F37442;
    opacity: 1;
    border-radius: 10px;
  }
}
.imgs {
    width: 960px;
    margin: auto;
    .swiper-slide {
      height: 126px;
      padding-top:6px;
    }
    img {
      width: 220px;
      height: 114px;
      margin-left: 0;
      background: #FFFFFF;
      opacity: 1;
      border-radius: 8px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
          /*margin-bottom: 10px;*/
    }
    .aa {
      display:inline-block;
      width: 192px;
      height: 90px;
      border-radius: 8px;
      overflow: hidden;
      font-size: 18px;
      font-weight: 300;
      color: #8f8f8f;
      padding: 40px 24px;
      box-sizing: border-box;
      text-align: left;
      border-top: 10px solid #00b7d0;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        .blue {
          width: 100%;
          height: 10px;
          background: #00b7d0;
        }
        // img {
        //   width: 150px;
        //   height: 40px;
        //   margin-left: 0;
        //   margin-bottom: 10px;
        // }
      }
}
</style>
<style>
 .popper__arrow::after{
display: none;
}
.el-select-dropdown__item{
  display: flex;
  align-items: center;
}
.el-select-dropdown{
  margin-top: 0px !important;
  background-color: rgba(255,255,255,0.9) !important;
}
.el-select-dropdown__item{
  background-color: transparent !important;
  padding: 28px 15px;
  box-sizing: border-box;
  font-size: 24px;
  font-weight: 300;
  color: #AAAAAA;

}
.el-select-dropdown__item.selected{
  color:#ACF3FF;
}
.el-select__caret{
  color: #00B7D0 !important;
}
</style>

<template>
  <div class="home-cont">
    <navs :active='6'></navs>
    <img src="../assets/images/about_b/关于我们.jpg" class="banner">

    <div class="text1">
      <div class="head">
        <p style="font-weight: bold;">公司简介</p>
      </div>
      <div class="descs">



        <p>
          SynSense时识科技（原名aiCTX）创立于2017年，是全球领先的类脑智能与应用解决方案提供商。SynSense时识科技专注类脑智能的研究与开发， 以苏黎世大学和苏黎世联邦理工学院20+年全球领先的研发经验和技术积累为基石，聚焦边缘计算应用场景，提供超低功耗、超低延时的全栈式解决方案与服务，是全球唯一横跨感知与计算两界的类脑科技公司。
        </p>
        <!-- <p>

        </p> -->
        <p style="margin-top: 38px">
          SynSense时识科技率先实现了类脑芯片商业化应用零的突破，为人工智能向认知智能发展，万物互联向万物智联发展迈出了关键一步。SynSense将和伙伴一起，共同构建“端到芯”“物与物”“物与人”万物智联的认知生态，引领全球类脑智能应用与发展，为人类未来美好生活创造福祉。
        </p>
        <!-- <P>

        </P> -->
      </div>

    </div>
    <img class="ban" src="../assets/images/about_b/1-1.png" alt="" />

    <img class="guanggao" src="../assets/images/about_b/1-2ch.png" alt="" />
    <div class="cooperation">
      <!-- <div class="head"> -->
        <!-- <p>PARTNER</p> -->
        <!-- <p>COOPERATIVE PARTNER</p>
        <p>合作伙伴</p> -->
      <!-- </div> -->
      <div class="stabs">
        <div :class="stabs==0?'active':''" @click="sliderSwitch(0)">投资机构</div>
        <div :class="stabs==1?'active':''" @click="sliderSwitch(1)">产业生态</div>
        <div :class="stabs==2?'active':''" @click="sliderSwitch(2)">学术合作</div>
      </div>
      <!-- <div class="items">
        <div>
          <div style="display:inline-block">
            <img class="ban" style="display:inline-block;" src="../assets/images/about_b/1-1@2x.png" alt="" />
            <span class="ckgd">查看更多</span>
          </div>
        </div>
        <div>
          <div>
            <img class="ban" src="../assets/images/about_b/1-2@2x.png" alt="" style="display:inline-block"/>
            <span class="ckgd">查看更多</span>
          </div>
        </div>
        <div>
          <div>
            <img class="ban" src="../assets/images/about_b/1-3@2x.png" alt="" style="display:inline-block"/>
            <span class="ckgd">查看更多</span>
          </div>
        </div>
      </div> -->
      <div class="imgs" >
        <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions3">
          <swiper-slide v-for="(item, i) in slides" :key="i">
              <img class="ban2" style="display:inline-block;" :src="item" alt="" />
          </swiper-slide>
        </swiper>
      </div>
      <!-- <div class="imgs" v-if="stabs==2">
        <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions3">
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-1@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-2@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-3@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-4@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-5@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-6@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-7@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-8@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-9@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-10@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-11@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-12@2x.png" alt="" />
          </swiper-slide>
        </swiper>
      </div> -->
    </div>
    <div class="hmap">
      <p>联系我们</p>
      <div class="map">
        <div>
          <div class="tab">
            <div :class="{ active: mtab == 1 }" @click="goLocation(5)">
              苏黎世
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(1)"
              />
              <div>瑞士苏黎世图尔高大街40号</div>
            </div>
            <div :class="{ active: mtab == 2 }" @click="goLocation(0)">
              成都
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(2)"
              />
              <div>四川省成都市高新区天府大道中段1577号</div>
            </div>
            <div :class="{ active: mtab == 3 }" @click="goLocation(1)">
              上海
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(3)"
              />
              <div>上海市浦东新区纳贤路800号B栋402-18</div>
            </div>
            <div :class="{ active: mtab == 4 }" @click="goLocation(2)">
              深圳
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(4)"
              />
              <div>深圳市福田中心区福华三路星河发展中心1701室</div>
            </div>
            <div :class="{ active: mtab == 5 }" @click="goLocation(3)">
              苏州
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(5)"
              />
              <div>江苏省苏州市苏州工业园区若水路398号</div>
            </div>
            <div :class="{ active: mtab == 6 }" @click="goLocation(4)">
              南京
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(6)"
              />
              <div>南京市浦口区丹桂路22号-98</div>
            </div>




          </div>
          <div class="m" id="container">
<!--            <amap :zoom="11" :center="[117.000923, 36.675807]">-->
<!--              <amap-marker :position="[117.000923, 36.675807]" />-->
<!--            </amap>-->
          </div>
        </div>
      </div>
      <div class="tips">
        <div>
          <div class="head">商务合作</div>
          <div>business@synsense.ai</div>
        </div>
        <div>
          <div class="head" style="margin-bottom:9px;">销售咨询</div>
          <div>sales@synsense.ai</div>
          <div class="phone">0755-8382 9305</div>
        </div>
        <div>
          <div class="head">媒体垂询</div>
          <div>media@synsense.ai</div>
        </div>
        <div>
          <div class="head">简历投递</div>
          <div>hr.cn@synsense.ai</div>
        </div>
        <div>
          <div class="head">售后服务</div>
          <div>fae@synsense.ai</div>
        </div>

    </div>

    </div>


    <div class="join">
      <div class="head">
        <p>加入我们</p>
      </div>

      <!-- <img src="../assets/images/about_b/accomplishment-achievement-adult-1059120@2x.png" alt="" class="adds"/> -->
      <div class="adds">
        <div class="">
          <span ref="selectText"></span>
          <img @click="goSwitch" src="../assets/images/about_b/down_arr.png" width="20" height="9">
          <div class="select_content" ref="selectContent" style="display: none;">
            <div class="sel_item" v-for="(item, i) in options" :key="i"  @click="goPicker(i)">
              <div class="sel_item_title">
                <span>{{ item.label }}</span>
                <span @click.prevent.stop="goSlide(i)">{{item.isShow ? '收起' : '展开' }}</span>
              </div>
              <div class="sel_item_content" v-if="item.isShow">
                <div class="duty_title">岗位职责</div>
                <div v-for="(value, index) in item.duty" class="duty_item">{{value}}}</div>
<!--                <div>2、负责管理维护我们的开源Python库，以及内部库</div>-->
<!--                <div>3、负责构建算法开发软件工具链，确保算法开发过程顺利执行</div>-->
<!--                <div>4、期望您能利用您的机器学习经验和背景，优化我们的算法开发流程</div>-->
<!--                <div>5、编写代码、单元测试和文档，确保我们的Python库达到世界一流水平</div>-->
<!--                <div>6、搭建CI和部署环境</div>-->
                <div class="require_title">岗位要求</div>
                <div v-for="(rValue, index) in item.requires" class="require_item">{{rValue}}}</div>
              </div>
            </div>
          </div>
<!--          <el-select v-model="value" placeholder="请选择" class="sel">-->
<!--          <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--            <div style="display:block;            background: #FFFFFF;-->
<!-- height:auto;width:100%;">-->
<!--              <div style="height:176px;">-->
<!--                <span style="-->
<!--            float:left;-->
<!--            width:90%;-->
<!--            height: 66px;-->
<!--            opacity: 1;-->
<!--            line-height:66px;-->
<!--            font-size: 18px;-->
<!--            color: #00242A;-->
<!--">{{item.label}}</span>-->
<!--                <span style="-->
<!--              float:right;-->
<!--              height:66px;-->
<!--              font-size:18px;-->
<!--              line-height:66px;-->
<!--              color: #00242A;-->
<!--            ">-->
<!--                  展开-->
<!--                </span>-->
<!--              </div>-->
<!--              <div>1112sdfasdfasdfdsdas2333</div>-->
<!--            </div>-->



<!--          </el-option>-->
<!--        </el-select>-->
        </div>
      </div>
      <!-- <div class="item"> -->
      <!-- </div> -->

    </div>
    <div class="stories-block" style="display: none">
      <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions2">
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智能玩具1-未选中@2x.png" alt="" />
            <img src="../assets/image/智能玩具1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智慧养殖1-未选中@2x.png" alt="" />
            <img src="../assets/image/智慧养殖1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智能家居1-未选中@2x.png" alt="" />
            <img src="../assets/image/智能家居1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智能安防1-未选中@2x.png" alt="" />
            <img src="../assets/image/智能安防1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>

      </swiper>
    </div>
    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navs.vue";
import bases from "@/components/bases.vue"
export default {
  name: "Home",
  components: {
    navs,
    bases
  },
  data() {
    return {
      selShow: false,
      isShow: false,
      value:"",
      options: [
        {
          value: '选项1',
          label: '机器学习算法工程师（信号处理方向）',
          duty: [
            '利用我们自研类脑芯片，开发实时机器学习应用演示及原型机。',
            '与在苏黎世和成都的研究团队合作',
            '分享你过去在神经网络模型和架构方面的经验，与我们共同成长。',
            '与我们的硬件工程师合作，根据需求调整今后类脑芯片的硬件设计',
            '撰写白皮书和文件，与客户和投资者沟通你的成果',
            '进行Python开发',
            '与外部学术及商业研究伙伴合作，完成外部资助的研究项目',
            '紧跟当前的机器学习趋势和研究',
            '有可能经常出差参加合作或会议',
          ],
          isShow: false,
          requires: [
            '1、信号处理背景(电子工程等相关学位)',
            '2、专业的编码技能(精通Python)',
            '3、良好的英语口语和书写能力',
          ]
        },{
          value: '选项21',
          label: '机器学习算法工程师（视觉处理方向）',
          isShow: false,

          duty: [
            '利用我们自研类脑芯片，开发实时机器学习应用演示及原型机。',
            '与在苏黎世和成都的研究团队合作',
            '分享你过去在神经网络模型和架构方面的经验，与我们共同成长。',
            '与我们的硬件工程师合作，根据需求调整今后类脑芯片的硬件设计',
            '撰写白皮书和文件，与客户和投资者沟通你的成果',
            '进行Python开发',
            '与外部学术及商业研究伙伴合作，完成外部资助的研究项目',
            '紧跟当前的机器学习趋势和研究',
            '有可能经常出差参加合作或会议',
          ],
          requires: [
            '1、基于神经网络的视觉或信号处理经验',
            '2、机器学习、电子工程、计算机科学或类似的背景与经验',
            '3、专业的编码技能(Python优先)',
            '4、良好的英语口语和书写能力',
          ]
        },{
          value: '选项31',
          label: '类脑算法工程师',
          isShow: false,

          duty: [
            '1、与世界一流的SynSense苏黎世及成都的软件/算法科学家团队研发基于类脑计算的人工智能应用算法和训练算法；',
            '2、研发基于事件相机（DVS）， 低维信号（语音， ECG等）的产品级解决方案；',
            '3、类脑算法的开发与应用， 指导和建议类脑芯片的功能设计；',
            '4、配合IC和系统整合团队完成类脑算法到芯片上的映射和测试；',
            '5、实现新的算法功能/特性，以及文档和单元测试；',
            '6、根据需求完成算法框架的Bug修复及重构；',
            '7、为应用开发人员和用户编写文档。',
          ],
          requires: [
            '1、硕士或博士学历。计算机科学，信号处理和人工智能等相关专业背景；',
            '2、良好的英语听说读写能力；',
            '3、熟练至少一种深度学习框架PyTorch/Tensorflow， 熟悉C++/C/python语言中的一种或多种。了解常用算法并可以应用CUDA和GPU运算。 熟悉Linux操作平台和git等操作；',
            '4、对神经形态计算、 脉冲神经网络，视听觉/低维信号处理等领域有深刻理解， 并可以跟踪当前主流类脑计算/深度学习算法；',
            '5、具备学术写作能力并在相关领域的学术会议和期刊上以第一作者发表过论文。',
          ]
        },{
          value: '选项41',
          label: '高级C++工程师',
          isShow: false,

          duty: [
            'C++高级软件工程师需能处理从设备驱动程序到基于事件的数据处理以及数据可视化的所有事情， 为公司（SynSense）硬件开发和产品开发提供支撑， 以及为算法团队提供工具链以及库，支持基于公司全新硬件（芯片）的算法及应用开发。',
            'Samna是专为公司(SynSense)芯片产品工具链及实时交互环境服务的开发者接口工具，其核心基于C++语言编写，对外提供了Python API和数据可视化工具（界面）。',
            '通过Samna，用户可以非常方便的操控硬件平台（芯片里）的脉冲神经网络并实时处理基于事件的数据流。本岗位（高级软件工程师）需要你参与后端工具链的开发及为Samna提供各种动态库支持。',
          ],
          requires: [
            '有为开发人员构建软件产品和工具的经验，如SDK、库或IDE；',
            '5年以上modern C++经验（11, 14, 17）；',
            '熟悉Linux系统，有构建工具（CMake，Make）和git的经验；',
            '良好的英语听说能力；',
            '有敏捷开发经验；',
            '计算机科学、数学、软件、电子等相关领域硕士及以上学位。',
          ]
        },{
          value: '2',
          label: '嵌入式工程师',
          isShow: false,

          duty: [
            '1、参与基于SynSense类脑处理器相关的驱动和平台开发、测试；',
            '2、为公司客户及公司算法团队提供软件支持；',
            '3、为开发人员和用户编写文档。',
          ],
          requires: [
            '计算机科学、数学、微电子等相关领域本科及以上学历；',
            '本科3年以上（硕士2年以上）嵌入式软件开发经验，拥有扎实的C/C++语言基础；',
            '熟悉linux下软件开发流程，具备良好的编程习惯和版本管理，有构建系统（CMake/Make）和git的经验；',
            '熟悉FreeRTOS、uC/OS-II等任意一种实时操作系统开发经验；',
            '熟悉物联网产品底层驱动程序的设计与开发；',
            '良好的英语听说能力。',
          ]
        },{
          value: '3',
          label: 'FPGA工程师',
          isShow: false,

          duty: [
            '负责FPGA项目中系统设计或模块开发，包括方案设计、代码编写、仿真验证、上板调试、系统联调；',
            '分析并解决开发过程中的问题，优化FPGA资源及时序，提高系统性能；',
            '配合嵌入式软件、硬件工程师进行板级调试及系统级联调工作；',
          ],
          requires: [
            '统招本科及以上学历，微电子、通信、自动化等相关专业；',
            '3年以上（硕士2年以上）FPGA开发经验，能独立开发和移植FPGA功能模块；',
            '熟练掌握Verilog HDL/VHDL，熟悉FPGA整体的开发流程；会SpinalHDL的优先；',
            '熟悉主流厂商FPGA器件，熟悉其开发工具、环境、资源及其常用IP；',
            '熟悉仿真验证方法及相关EDA工具；',
            '硬件功底扎实，有较强的debug能力，逻辑分析能力；',
            '良好的学习和沟通能力、英语听说能力佳，高度的责任感。',
          ]
        },{
          value: '4',
          label: 'DSP工程师',
          isShow: false,

          duty: [
            '负责信号处理算法：实现、仿真和白盒测试，及SynSense雷达（射频）处理器芯片的原型验证工作；',
            '负责雷达（射频）信号处理算法在FPGA/DSP上的实现，支持DSP算法与雷达芯片上ADC接口的功能性验证；',
            '配合IC和算法同事进行功能开发、调试和性能优化；',
            '负责系统级射频电路（10GHz以下）的设计和调试，评估射频元器件的选型，指导射频硬件电路设计和PCB Layout；',
            '负责项目中DSP相关技术方案和文档的编写。',
          ],
          requires: [
            '3年或以上DSP算法开发经验，硕士或博士学历，电磁场和微波技术、信号与处理、数学、通信等专业；',
            '熟悉调频连续波雷达（FWCW-Radar）在UWB频段的工作原理及其他UWB应用；',
            '具备较强的DSP相关数学理论和系统级建模能力，能用Python等脚本语言实现数字信号处理（一维和二维FFT、数字滤波器等），并在FPGA或DSP硬件平台上转换实现者优先；',
            '熟悉示波器、信号发生器、频谱仪/网络数据分析仪、逻辑分析仪等硬件设备及FPGA或嵌入式调试工具（仿真器、调试器等）；',
            '学习能力强，有较强进取心和抗压能力，良好的沟通能力和团队合作能力；',
          ]
        },{
          value: '5',
          label: '算法应用工程师',
          isShow: false,

          duty: [
            '研发及部署基于类脑计算的人工智能应用算法；',
            '实现新的算法功能/特性，以及文档和单元测试，优化训练算法；',
            '参与新产品项目的评审、立项工作，参与技术及流程建设和技术风险评估；',
            '负责项目产品系统方案及方案论证、需求分解、CBB策略，负责类脑智能CBB构建；',
            '配合IC和系统整合团队完成类脑算法到芯片上的映射和测试；',
            '制订产品测试策略核计划，为现场应用工程师（FAE）和用户编写文档。',
          ],
          requires: [
            '硕士及以上学历。数学，计算机科学，信号处理和人工智能等相关专业背景；',
            '有使用深度学习框架的经验如：PyTorch/Tensorflow；',
            '有一定的Python单元测试框架经验、Python文档框架经验，良好的Python代码编写能力；',
            '热爱技术，有高度的责任心、良好的沟通能力与团队合作能力.',
          ]
        },{
          value: '6',
          label: 'C++工程师',
          isShow: false,

          duty: [
            '参与软件开发的全过程，包括系统需求分析、实现、测试、文档编写及维护；',
            '系统集成，包含编写测试集及收集验证数据集；',
            '基于公司神经拟态处理器开发平台的搭建，为公司客户及公司算法团队提供软件支持；',
            '与算法团队中的机器学习专家合作，了解他们的需求以及改进他们的工作流程；',
          ],
          requires: [
            '1年以上modern C++经验（11, 14, 17）；',
           '熟悉Linux系统，有构建工具（CMake，Make）和git的经验；',
            '良好的英语听说能力；',
            '有敏捷开发经验；',
            '计算机科学、数学、软件、电子等相关领域硕士及以上学位。',
          ]
        }],
      stabs:0,
      isfixed: false,
      mtab: 1,
      tablelistIndex: 0,
      center: [
        [104.071097,30.536551],
        [121.605045,31.178305],
        [114.061539,22.533728],
        [120.671057,31.301196],
        [118.55277,31.954632],
        [8.551858,47.415059]
      ],
      swiperOptions: {
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".rightIcon",
          prevEl: ".leftIcon",
        },
        speed: 1000,
        // on: {
        //   // 监听滑动切换事件，返回swiper对象
        //   slideChange: () => {
        //     const i = this.swiper.activeIndex
        //     this.changepicture(i)
        //   }
        // }
        // Some Swiper option/callback...
      },
      swiperOptions2: {
        slidesPerView: 3,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
        observer:true,
        observerParents:true,
      },
      swiperOptions3: {
        slidesPerView: 4,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 500,
        observer:true,
        observerParents:false
      },
      slides: [],
      slidesOne: [
        require('../assets/images/cooper/11-1@2x.png'),
        require('../assets/images/cooper/11-2@2x.png'),
        require('../assets/images/cooper/11-3@2x.png'),
        require('../assets/images/cooper/11-4@2x.png'),
        require('../assets/images/cooper/11-5@2x.png'),
        require('../assets/images/cooper/11-6@2x.png'),
        require('../assets/images/cooper/11-7@2x.png'),
        require('../assets/images/cooper/11-8@2x.png'),
        require('../assets/images/cooper/11-9@2x.png'),
        require('../assets/images/cooper/11-10@2x.png'),
      ],
      slidesTwo: [
        require('../assets/images/cooper/2-1@2x.png'),
        require('../assets/images/cooper/2-2@2x.png'),
        require('../assets/images/cooper/2-3@2x.png'),
        require('../assets/images/cooper/2-4@2x.png'),
        require('../assets/images/cooper/2-5@2x.png'),
        require('../assets/images/cooper/2-6@2x.png'),
        require('../assets/images/cooper/2-7@2x.png'),
        require('../assets/images/cooper/2-8@2x.png'),
        require('../assets/images/cooper/2-9@2x.png'),
        require('../assets/images/cooper/2-10@2x.png'),
        require('../assets/images/cooper/2-11@2x.png'),
        require('../assets/images/cooper/2-12@2x.png'),
        require('../assets/images/cooper/2-13@2x.png'),
        require('../assets/images/cooper/2-14@2x.png'),
      ],
      slidesThree: [
        require('../assets/images/cooper/3-1@2x.png'),
        require('../assets/images/cooper/3-2@2x.png'),
        require('../assets/images/cooper/3-3@2x.png'),
        require('../assets/images/cooper/3-4@2x.png'),
        require('../assets/images/cooper/3-5@2x.png'),
        require('../assets/images/cooper/3-6@2x.png'),
        require('../assets/images/cooper/3-7@2x.png'),
        require('../assets/images/cooper/3-9@2x.png'),
        require('../assets/images/cooper/3-8@2x.png'),
        require('../assets/images/cooper/3-10@2x.png'),
        require('../assets/images/cooper/3-11@2x.png'),
        require('../assets/images/cooper/3-12@2x.png'),
      ]
    };
  },
  mounted() {
    this.sliderSwitch(this.stabs);
    window.addEventListener("scroll", this.scrollToTop);
    // const that = this;
    // window.onresize = () =>
    //   (() => {
    //     that.setBGblock();
    //   })();
    // this.setBGblock();

    window._AMapSecurityConfig = {
      serviceHost: "http://yslk.jiu.mzdxj.cn/_AMapService",
      // 例如 ：serviceHost:'http://1.1.1.1:80/_AMapService',
    };

    var map = new AMap.Map("container", {
      resizeEnable: false,
      center: this.center[5],
      // center: [113.263298, 23.123622],
      zoom: 16,
      vectorMapForeign:'style_zh_cn',
    });
    // window.map = map;

    //建创Marker实例
    var marker = new AMap.Marker({
      position: new AMap.LngLat(this.center[5][0], this.center[5][1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      title: '1577号',
    });

    map.add(marker);

    //实时路况图层
    var trafficLayer = new AMap.TileLayer.Traffic({
      zIndex: 10,
    });
    trafficLayer.setMap(map);
  },
  // mounted() {
  //   this.sliderSwitch(this.stabs);
  //   window.addEventListener("scroll", this.scrollToTop);
  //   // const that = this;
  //   // window.onresize = () =>
  //   //   (() => {
  //   //     that.setBGblock();
  //   //   })();
  //   // this.setBGblock();
  //
  //   window._AMapSecurityConfig = {
  //     serviceHost: "http://yslk.jiu.mzdxj.cn/_AMapService",
  //     // 例如 ：serviceHost:'http://1.1.1.1:80/_AMapService',
  //   };
  //
  //   var map = new AMap.Map("container", {
  //     resizeEnable: false,
  //     center: this.center[5],
  //     // center: [113.263298, 23.123622],
  //     zoom: 16,
  //     vectorMapForeign:'style_zh_cn',
  //   });
  //   // window.map = map;
  //
  //   //建创Marker实例
  //   var marker = new AMap.Marker({
  //     position: new AMap.LngLat(this.center[5][0], this.center[5][1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
  //     title: '1577号',
  //   });
  //
  //   map.add(marker);
  //
  //   //实时路况图层
  //   var trafficLayer = new AMap.TileLayer.Traffic({
  //     zIndex: 10,
  //   });
  //   trafficLayer.setMap(map);
  // },
  methods: {
    goPicker(index) {
      this.$refs.selectText.innerHTML = this.options[index].label;
      this.$refs.selectContent.style.display = "none";
      this.selShow = 0;
    },
    goSlide(index) {
      let temp = !this.options[index].isShow;

      for(let i = 0 ; i < this.options.length; i++) {
        this.options[i].isShow = false;
      }
      this.options[index].isShow = temp;

    },
    goSwitch() {
      if(this.selShow) {
        this.$refs.selectContent.style.display = "none";
        this.selShow = 0;
        for(let i = 0 ; i < this.options.length; i++) {
          this.options[i].isShow = false;
        }
      } else {
        this.$refs.selectContent.style.display = "block";
        this.selShow = 1;
      }
    },
    goLocation(idx) {
      console.log(1);
      // this.center = [114.061539,22.533728];
      // this.center = [113.263298, 23.123622];
      // center: [113.263298, 23.123622],

      var map = new AMap.Map("container", {
        resizeEnable: false,
        center: this.center[idx],
        zoom: 16,
        vectorMapForeign:'style_zh_cn',
      });
      // window.map = map;

      //建创Marker实例
      var marker = new AMap.Marker({
        position: new AMap.LngLat(this.center[idx][0], this.center[idx][1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        // title: '1577号',
      });

      map.add(marker);

      //实时路况图层
      var trafficLayer = new AMap.TileLayer.Traffic({
        zIndex: 10,
      });
      trafficLayer.setMap(map);
    },

    tabs(a) {
      this.mtab = a;
    },
    scrollToTop() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 30) {
        this.isfixed = true;
      } else {
        this.isfixed = false;
      }
    },
    setBGblock() {
      this.$refs.bgBlcok.style.width = `${
        380 + this.$refs.aboutUsCont.getBoundingClientRect().left
      }px`;
    },
    sliderSwitch(i){

      this.stabs = i;
      if(i === 1){
        this.slides = this.slidesTwo;
      }
      if(i === 2){
        this.slides = this.slidesThree;
      }
      if(i === 0){
        this.slides = this.slidesOne;
      }
      this.$refs.mySwiper.swiper.destroy()   //先销毁
      this.$refs.mySwiper.mountInstance()
      console.log(this.slides);
    }
  },
};
</script>


