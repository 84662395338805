<style scoped lang="less">

  .brief {
    width: 100%;
    height: 490px;
    background: url(../assets/images/technical/渐变过渡@2x.png) no-repeat;
    background-size: 100% 100%;
    opacity: 1;
    border-radius: 0px;
    .title {
      padding-top:140px;
      padding-bottom:80px;
      height: 36px;
      font-size: 36px;
      font-weight: bold;
      line-height: 36px;
      color: #FFFFFF;
      opacity: 1;
    }
    .txt-area {
      width: 960px;
      margin: 0 auto;
      text-align: center;
      font-size: 18px;
      font-weight: 300;
      line-height: 38px;
      color: #FFFFFF;
      opacity: 1;
    }
  }

  .bt {
    height: 40px;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    line-height: 40px;
    padding: 140px 0 80px 0;
    color: #00B7D0;
    opacity: 1;
  }

  .bc {
    width: 960px;
    margin: 0 auto;
    padding-bottom: 80px;
    text-align: center;
    line-height: 38px;
    color: #003F52;
    opacity: 1;
    font-size: 18px;
    font-weight: 300;
  }

  .img_container{
    width: 960px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    .item {
      box-sizing: border-box;
      width: 224px;
      height: 152px;
      /*background: #FFAAAA;*/
      border-radius: 0px;
    }
  }

  .bot_container {
    width: 100%;
    margin-top:100px;
    opacity: 1;
    border-radius: 0px;
    height: 588px;
    background: linear-gradient(180deg, #00B7D0 0%, #003F52 100%);
    .title {
      padding: 140px 0 80px 0;
      margin: 0 auto;
      text-align: center;
      height: 36px;
      font-size: 36px;
      font-weight: bold;
      line-height: 36px;
      opacity: 1;
      color: #FFFFFF;
    }
    .item_container {
      width: 960px;
      height: 210px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .item {
        width:320px;
        height: 100%;
        display:flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        .text_area {
          .text {
            text-align: center;
            height: 28px;
            font-size: 28px;
            font-weight: bold;
            line-height: 28px;
            color: #FFFFFF;
            opacity: 1;
          }
          .text:nth-child(2) {
            height: 24px;
            font-size: 24px;
            font-weight: 300;
            line-height: 24px;
            margin-top:27px;
          }
        }

        img {
          width:89px;
          height:89px;
        }
      }
    }
  }
  .aa{
    width: 1200px;
    height: 632px;
    display: flex;
    justify-content: space-between;
    position: relative;
    >div{
      width: 590px;
      height: 100%;
      background: linear-gradient(135deg, #FFFFFF 0%, #F4F4F4 100%);
      border-radius: 20px;
      text-align: center;
    }
    .aa_l{
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      justify-content: flex-end;
      div{
        margin-bottom: -60px;
        position: relative;
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        color: #00242A;
      }
      img{
        width: 100%;
        height: auto;
      }
    }
    .aa_r{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px 68px;
      box-sizing: border-box;
      font-size: 32px;
      font-weight: 300;
      line-height: 50px;
      color: #00242A;
      img{
        position: absolute;
        right: 28px;
        bottom: 28px;
      }
    }
  }
  .img-row{
    min-width: 1200px;
    width: 100%;
    height: auto;
    margin-top: -100px;
  }
</style>

<template>
  <div class="home-cont" style="overflow: hidden;">
    <navs :active='1'></navs>
    <!-- <div class="swiper-block"> -->
        <img class="img-row" src="../assets/images/technical/核心技术.jpg"/>
    <!-- </div> -->
<!--    <div class="brief">-->
<!--      <div class="title">世界领先的类脑技术 </div>-->
<!--      <div class="txt-area">-->
<!--        类脑计算又被称为神经形态计算（Neuromorphic Computing）。 <br />-->
<!--        是借鉴生物神经系统信息处理模式和结构的计算理论、体系结构、芯片设计以及应用模型与算法的总称。<br />-->
<!--        类脑智能是受脑神经机制和认知行为机制启发，通过软硬件协同实现的机器智能。-->
<!--      </div>-->
<!--    </div>-->
    <p class="bt">SynSense时识科技</p>
    <div class="bc">
      SynSense时识科技开发的纯数字以及数模混合神经形态处理器/智能传感器，克服了传统冯诺依曼架构局限性 <br />
      面向智能机器人、智能安防、智慧康养、智能驾驶、智能玩具、智能家居等领域<br />
      提供高效、超低功耗和超低延迟性能的类脑智能芯片及应用解决方案
    </div>
    <div class="img_container">
      <img class="item" src="../assets/images/technical/01.png"/>
      <img class="item" src="../assets/images/technical/02.png"/>
      <img class="item" src="../assets/images/technical/03.png"/>
      <img class="item" src="../assets/images/technical/04.png"/>
    </div>
    <div class="bot_container">
      <div class="title">极致性能</div>
      <div class="item_container">
        <div class="item">
          <img src="../assets/images/technical/超低延迟.png">
          <div class="text_area">
            <div class="text">实时性</div>
            <div class="text">提升10-100X</div>
          </div>
        </div>
        <div class="item">
          <img src="../assets/images/technical/超低功耗.png">
          <div class="text_area">
            <div class="text">功耗</div>
            <div class="text">降低100-1000X</div>
          </div>
        </div>
        <div class="item">
          <img src="../assets/images/technical/超低成本.png">
          <div class="text_area">
            <div class="text">成本</div>
            <div class="text">降低10X</div>
          </div>
        </div>
      </div>
    </div>
    <bases></bases>
  </div>
</template>
<script>
import navs from "@/components/navs.vue"
import bases from "@/components/basest.vue"
export default {
  name: 'Home',
  components: {
    navs,
    bases
  },
  data() {
    return {

    };
  },
  mounted() {
  },
  methods: {

    setBGblock() {
      this.$refs.bgBlcok.style.width = `${380 + this.$refs.aboutUsCont.getBoundingClientRect().left}px`;
    },
  },
};
</script>

<style lang="less" scoped>
.dx_2 {
    animation: dx 2s linear;
}
.opc_3 {
    animation: opc 3s linear;
}

@keyframes dx {
    0% {
        transform: scale(1.5);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes opc {
    0% {
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

  /deep/.header{
    position: relative ;
    top: 0px !important;
  }
  .item-right , .item-left{
    // border: 1px solid red;
    min-height: 100px;
    margin: 20px 0px;
    background-image: linear-gradient(to right, #FFFFFF , #F4F4F4);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.item-right{
  padding: 20px;
  box-sizing: border-box;

  .item-title{
    font-size: 36px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    line-height: 36px;
    color: #00242A;
    opacity: 1;
    margin-bottom: 60px;
  }
  .item-desc{
    font-size: 28px;
    font-weight: 300;
    line-height: 50px;
    color: #00242A;
    opacity: 1;
  }
  .right{
    text-align: right;
    .more{
      width: 28px;
      height: 28px;
      display: inline-block;
    }
  }
}
.item-left{
    position: relative;
    height:632px;
  .right{
    height: 100%;
    box-sizing: border-box;
    text-align: right;
    box-sizing: border-box;
    font-size: 32px;
    font-weight: 300;
    line-height: 50px;
    color: #00242A;
    display: flex;
    flex-flow: column nowrap;
    p{
      text-align: center;
      width: 454px;
    }
    .more{
      width: 28px;
      height: 28px;
      display: inline-block;
      position: absolute;
      right: 28px;
      bottom: 28px;
    }
  }
}

  .item-right>div,
  .item-left>div
  {
    display: inline-block;
    width: 49%;
    min-height: 300px;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .item-right>div>img,
  .item-left>div>img
  {
    width: 100%;
    height: auto;
  }
  .item-right > .left{
    font-size: 36px;
font-weight: bold;
line-height: 36px;
color: #00242A;
  }
  .marbtn-136{
    margin-bottom: 130px;
  }
.swiper-block{
  position: relative;
  .banner-center{
    width: 1200px;
    height: 267px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -600px;
    margin-top: -133.5px;
    z-index: 10;
  }
  .swiperimg{
    width: 100%;
  }

  .swiper-pagination{
    width: 100%;
    bottom: 15%;
    /deep/.swiper-pagination-bullet{
      width: 26px;
      height: 26px;
      background-color: #FFFFFF;
      opacity: 1;
      margin: 0px 29px;
    }
    /deep/.swiper-pagination-bullet-active{
      background-color: #0086CD;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
        border: 4px solid #0086CD;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
      }
    }
  }
}
.title{
  text-align: center;
  p:nth-child(1){
    color: #0086CD;
    font-size: 20px;
    margin-bottom: 5px;
  }
  p:nth-child(2){
    color: #00242A;
    font-size: 46px;
    font-weight: bold;
  }
}
.about-us{
  width: 100%;
  background-color: #FFF;
  height: auto;
  padding-bottom: 122px;
  overflow: hidden;
  .about-us-cont{
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    position: relative;
    .about-us-left{
      width: 100%;
      // padding-right: 190px;
      box-sizing: border-box;
      color: #17181A;
      font-size: 18px;
      text-align: center;
      .title{
        text-align: center;
        margin-top: 122px;
        letter-spacing: 5px;
      }
      .texts{
        width: 630px;
        height: 180px;
        font-size: 30px;
        font-weight: 500;
        line-height: 60px;
        color: #17181A;
        opacity: 1;
        margin: 10px auto 0;
      }
      .text1{
        margin-top: 38px;
      }
      .text2{
        margin-top: 25px;
      }
      .img1{
        width: 180px;
        margin-top: 139px;
      }
      .img2{
        width: 477px;
        margin-top: 69px;
      }
    }
    .bg-blcok{
      position: absolute;
      width: 380px;
      height: 100%;
      left: 820px;
      background-color: rgba(0, 0, 0, 0.8);
      img{
        width: 57%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
      }
    }
  }
}
.stories-block{
  width: 1200px;
  height: auto;
  margin: auto;
  .title{
    margin-top: 78px;
  }
  .tablelist{
    display: flex;
    justify-content: space-between;
    margin-top: 67px;
    li{
      width: 290px;
      cursor: pointer;
      img{
        width: 100%;
      }
    }
  }
  .cont-img{
    width: 1180px;
    margin: auto;
    margin-top: 91px;
  }
  .iconList{
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    li{
      text-align: center;
      width: 220px;
      cursor: pointer;
      color: #00242A;
      >div{
        width: 220px;
        height: 220px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px dashed #505156;
        border-radius: 100%;
        img{
          width: 90px;
        }
        .img1{
          display: block;
        }
        .img2{
          display: none;
        }
      }
      p:nth-child(2){
        font-size: 38px;
        margin-top: 38px;
        font-weight: bold;
      }
      p:nth-child(3){
        font-size: 30px;
        margin-top: 5px;
      }
      // &:hover{
      //   >div{
      //     border:none;
      //     background-color: #0086CD;
      //     .img1{
      //       display: none;
      //     }
      //     .img2{
      //       display: block;
      //     }
      //   }
      // }
    }
    .chooseLi{
      >div{
          border:none;
          background-color: #0086CD;
          .img1{
            display: none ;
          }
          .img2{
            display: block;
          }
        }
    }
  }
}
.features-list{
  display: flex;
  li{
    flex: 1;
    position: relative;
    .basemap{
      width: 100%;
    }
    .mask{
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      left: 0px;
      top: 0px;
      color: #FFFFFF;
      opacity: 1;
      transition: all 1s;
      p{
        margin: auto;
      }
      p:nth-child(1){
        width: 292px;
        max-width: 100%;
        height: 84px;
        text-align: center;
        font-size: 30px;
        margin-top: 207px;
      }
      p:nth-child(2){
        width: 354px;
        max-width: 90%;
        text-align: center;
        font-size: 20px;
        margin-top: 57px;
      }
    }
    .search-block{
      width: 156px;
      height: 156px;
      border-radius: 100%;
      background-color: #0086CD;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
      img{
        width: 50px;
      }
    }
    &:hover{
      .mask{
        opacity: 0;
      }
      .search-block{
        transition: all 1s;
        opacity: 1;
      }
    }
  }
}
.btnmore{
  width: 208px;
  height: 54px;
  border: 2px solid #0086CD;
  border-radius: 27px;
  font-size: 22px;
  line-height: 54px;
  text-align: center;
  color: #0086CD;
  margin: 71px auto;
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  box-sizing: border-box;
  span{
    position: relative;
    z-index: 10;
  }
}
.btnmore:hover{
  border: none;
  line-height: 56px;
  color: #fff;
  animation: 1s color1 linear;
}
.btnmore:hover::before{
  content: "";
  width: 120%;
  height: 100%;
  position: absolute;
  left: -10%;
  top: 0;
  background-color: #0086CD;
  z-index: -1px;
  animation: 1s lrs linear;
}
.btnmore:hover::after{
  content: "";
  width: 120%;
  height: 100%;
  position: absolute;
  left: 110%;
  top: 0;
  background-color: #0086CD;
  z-index: -1px;
  animation: 1s lrs2 linear;
}
@keyframes lrs {
  0%{
    left: -120%;
  }
  100%{
    left: -10%;
  }
}
@keyframes lrs2 {
  0%{
    left: 100%;
  }
  100%{
    left: -10%;
  }
}
@keyframes color1 {
  0%{
    color: #0086CD;
  }
  100%{
    color: #FFFFFF;
  }
}
.news-block{
  width: 100%;
  height: auto;
  margin: auto;
  // margin-top: 120px;
  padding-top: 134px;
  padding-bottom: 153px;
  background: #FFFFFF;
  overflow: hidden;
  .news-block-cont{
    width: 1200px;
    margin: auto;
    .title{
      text-align: left;
    }
    .news-list{
      display: flex;
      position: relative;
      left: 0px;
      margin-top: 92px;
      justify-content: space-between;
      li{
        width: 482px;
        height: 400px;
        margin-right: 22px;
        background-color: red;
        flex-shrink: 0;
        border-radius: 10px;
        background-color: #F8F8F8;
        overflow: hidden;
        .img-block{
          position: relative;
          width: 100%;
          height: 272px;
          img{
            width: 100%;
            height: 272px;
          }
          div{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 1;
          }
        }
        &:hover{
          cursor: pointer;
          background-color: #FFFFFF;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
          .img-block{
            div{
              opacity: 0;
            }
          }
        }
        p{
          padding: 0px 20px;
        }
        p:nth-child(2){
          color: #1D1D1F;
          margin-top: 25px;
          word-break:keep-all;/* 不换行 */
          white-space:nowrap;/* 不换行 */
          overflow:hidden;
          text-overflow:ellipsis;
        }
        p:nth-child(3){
          color: #6E6E73;
          font-size: 12px;
          margin-top: 42px;
        }
      }
    }
  }
}
.Partner{
  width: 100%;
  height: 558px;
  background: #fff;
  padding-top: 140px;
  box-sizing: border-box;
  .imgs{
    width: 1200px;
    margin: auto;
    margin-top: 116px;
    img{
      width: 300px;
      margin-left: 46px;
    }
  }
}
.achievement-block-para{
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, #FFFFFF 0%, #E6E6E6 100%);
}
.achievement-block{
  width: 1200px;
  height: auto;
  margin: 0 auto;
  // background-image: url('../assets/image/订阅-底@2x.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  padding-top: 114px;
  padding-bottom: 85px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  >.sr{
    width: 730px;
    height: 62px;
    // padding: 4px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-left: 178px;
    input{
      flex: 1;
      height: 54px;
      line-height: 54px;
      padding: 0px 23px;
      border:none;
    }
    img{
      width: 150px;
      height: 54px;
    }
  }
  >.wz{
    >div:first-child{
      width: auto;
      height: auto;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #00242A;
      letter-spacing: 5px;
      opacity: 1;
    }
    >div:last-child{
      font-size: 46px;
      font-weight: bold;
      line-height: 58px;
      color: #00242A;
      letter-spacing: 0.6px;
      opacity: 1;
      margin-top: 8px;
    }
  }
}
.footer{
  width: 100%;
}
.sj-news-list{
  margin-top: 51px;
  padding: 0px 41px;
  box-sizing: border-box;
  img{
    cursor: pointer;
    width: 350px;
    height: 364px;
  }
  img:nth-child(2){
    margin-top: 19px;
  }
  img:hover{
    box-shadow: 0px 0px 10px #dfdfdf;
    transform: translateY(-5px);
  }
  // img+img{
  //   margin-left: 32px;
  // }
}
.about-us-img{
  width: 100%;
  height: auto;
  position: relative;
  transform: scale(1.2);
  img{
    width: 100%;
    height: auto;
  }
  .cont_img{
    transform:translateY(-500px) scale(0.3);
    position: absolute;
    left:50%;
    top:50%;
    margin-left: -229px;
    margin-top: -49px;
    width: 458px;
    height: 98px;
    opacity: 0;
  }
}
.yyly3{
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  margin-top: 30px;
  font-size: 32px;
  font-weight: 300;
  line-height: 68px;
  color: #00242A;
}

.stories-block-para{
  width: 100%;
  height: auto;
  overflow: hidden;
  // /* background-attachment: fixed; */
  // background-size:auto 114%;
  // background-repeat: no-repeat;
  // background-position: 700% -70px;
  // background-image: url('../assets/image/tiny speck@2x.png');
}
</style>
