<style scoped lang="less">
.main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.banner{
  width: 100%;
  height: auto;
  margin-top: -75px;
}
.mast_title{
  width: 100%;
  height: auto;
  text-align: center;
  margin: 135px 0 80px 0;
  div{
    width: 100%;
    font-weight: bold;
    margin-top: 15px;
    height: 36px;
    font-size: 36px;
    line-height: 36px;
    color: #00B7D0;
    opacity: 1;
  }
}
.group{
  width: 1366px;
  height: 540px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .big{
    width: 816px;
    height: 100%;
    background: url('../assets/images/solve/图片7@2x.png') no-repeat left top;
    background-size: 100% 100%;
    div{
      opacity: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 90px 70px;
      box-sizing: border-box;
      background-color: rgba(0,0,0,0.7);
      font-size: 30px;
      font-weight: 300;
      line-height: 63px;
      color: #FFFFFF;
    }
  }
  .big:hover>div{
    animation: 0.5s opc linear;
    opacity: 1;
  }
  .big2{
    background: url('../assets/images/solve/语音信号处理配图@2x.png') no-repeat left top;
  }
  .big3{
    background: url('../assets/images/solve/身体信号检测配图@2x.png') no-repeat left top;
  }
  @keyframes opc {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  .little{
    width: 550px;
    height: 100%;
    background-size: 100% 100%;
    background: #00242A;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 46px;
    font-weight: bold;
    line-height: 46px;
    color: #FFFFFF;
  }
}

  .desc{
    margin: 0px auto;
    width: 840px;
    height: auto;
    font-weight: 300;
    opacity: 1;

    font-size: 18px;
    line-height: 38px;
    color: #003F52;
    opacity: 1;
    p{
      margin-bottom: 30px;
      text-align: center;
    }
  }
  .advertising{
    max-width: 1920px;
    min-width: 1200px;
    margin: 200px auto;
  }
  .item-row{
    margin: 0px auto;
    width: 960px;
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 166px;
      .item-div{
        width: 48%;
        // min-height: 100px;
        text-align: center;
        flex: 1;
        .item-title{
          width:470px;
          font-size: 26px;
          font-weight: bold;
          line-height: 26px;
          color: #00242A;
          opacity: 1;
        }
        .item-desc{
          font-weight: 300;
          opacity: 1;
          margin: 40px 20px 40px 20px;

          width: 406px;
          height: 142px;
          font-size: 14px;
          line-height: 32px;
          color: #003F52;
        }
        .item-btn{
          color: #00B7D0;
          display: inline-block;
          width: 180px;
          height: 50px;
          font-size: 18px;
          opacity: 1;
          line-height: 50px;
          cursor: pointer;
          background: #FFFFFF;
          border: 2px solid #00B7D0;
          border-radius: 8px;
        }
        .item-btn:hover{
          background-color:#00B7D0;
          color: #ffffff;
        }
      }
      .item-img-row{

        img{
          width: 470px;
          height: 300px;
          border-radius: 4px;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
  .dowload{
    width: 700px;
    height: 410px;
    background: rgba(255,255,255,0.6);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 20px;
    position: fixed;
    left: 50%;
    top: 50%;
    margin-left: -350px;
    margin-top: -205px;
    padding: 7px;
    box-sizing: border-box;
    .nei{
      width: 686px;
      height: 396px;
      background: #FFFFFF;
      border: 1px solid #00B7D0;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      opacity: 1;
      border-radius: 18px;
      box-sizing: border-box;
      position: relative;
      .xx{
        height: 20px;
        position: absolute;
        right: 23px;
        top: 14px;
        width: 20px;cursor: pointer;
      }
      .title{
        width: 100%;
        height: auto;
        box-sizing: border-box;
        text-align: center;
        font-size: 24px;
        font-weight: 400;
        line-height: 24px;
        color: #00B7D0;
        padding-top: 52px;
        padding-bottom: 17px;
      }
      .inp{
        width: 466px;
        height: 40px;
        margin: 0 auto;
        margin-top: 34px;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border: 1px solid #DDDFE1;
        box-sizing: border-box;
        border-radius: 20px;
        overflow: hidden;
        div{
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          width: 91px;
          height: 100%;
          justify-content: center;
          border-right: 1px solid #DDDFE1;
          box-sizing: border-box;
          white-space: nowrap;
          img{
            width: 16px;
            height: 16px;
            margin-right: 8px;
          }
        }
        input{
          flex:1;
          width: 0px;
          height: 100%;
          border:none;
          outline: 0;
          padding: 0 20px;
          box-sizing: border-box;
          font-size: 14px;
          color: #C5C9CD;
        }
        input::-webkit-input-placeholder {
          font-size: 14px;
          color: #C5C9CD;
        }

        /* Firefox 4-18 */
        input:-moz-placeholder {
          font-size: 14px;
          color: #C5C9CD;
        }

        /* Firefox 19-50 */
        input::-moz-placeholder {
          font-size: 14px;
          color: #C5C9CD;
        }

        /* - Internet Explorer 10–11
          - Internet Explorer Mobile 10-11 */
        input:-ms-input-placeholder {
          font-size: 14px !important;
          color: #C5C9CD !important;
        }

        /* Edge (also supports ::-webkit-input-placeholder) */
        input::-ms-input-placeholder {
          font-size: 14px;
          color: #C5C9CD;
        }

        /* CSS Working Draft */
        input::placeholder {
          font-size: 14px;
          color: #C5C9CD;
        }
      }
      .btn{
        width: 152px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background: #00B7D0;
        opacity: 1;
        border-radius: 8px;
        margin: 62px auto auto;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        letter-spacing: 3px;
      }
    }
  }
</style>
<template>
  <div class="main">
    <navs :active='2'></navs>
    <img src="../assets/images/solve/解决方案.jpg" class="banner">
    <div class="mast_title">
      <div>类脑智能解决方案</div>

    </div>
     <div class="desc">
        <p>
          SynSense时识科技是全球领先的类脑智能与应用解决方案提供商。SynSense时识科技丰富的低功耗神经形态电路研发经验，以及在神经形态运算、皮层计算和机器学习方面的专业知识，
          为我们提供了超越当今技术所能实现的独特视角和愿景。
        </p>
        <p>
          SynSense时识科技的全定制神经形态处理器为人工智能边缘运算赋能，
          落地场景包括智能家居人机交互、智能机器人、可穿戴设备、智能安防、工业物联网等。
          作为一家全栈式神经形态工程公司，SynSense时识科技提供完整的解决方案，
          包括可满足特定应用需求的定制硬件和软件配置。
        </p>
      </div>
   <div class='advertising'>
    <img src="../assets/images/solve/advertising.png">
   </div>
   <div class='item-row'>
     <div class="item">
       <div class="item-div">
         <div class="item-title">智能视觉处理</div>
         <div class="item-desc">SynSense正在开发用于实时视觉处理的专用事件驱动神经形态处理器。我们处理器的超低功耗和超低延迟为永远在线的物联网设备和边缘计算应用（如手势识别、面部或物体检测、定位、跟踪和监视）铺平了道路。我们的处理器专为与最先进的基于事件的图像传感器集成而设计。</div>
         <div class="item-btn" @click="open = true">下载文档</div>
       </div>
       <div class="item-div item-img-row">
          <img src="../assets/images/solve/solve1.png">
       </div>
     </div>
     <div class="item">
       <div class="item-div item-img-row">
          <img src="../assets/images/solve/solve2.png">
       </div>
       <div class="item-div">
         <div class="item-title">语音信号处理</div>
         <div class="item-desc">SynSense时识科技开发基于脉冲神经网络（SNN）的低功耗的语音识别与口令识别。SynSense的技术能够以新颖的脉冲神经网络算法实时处理传感器信息，为物联网设备的低功耗语音识别赋能。</div>
         <div class="item-btn" @click="open = true">下载文档</div>
       </div>
     </div>
     <div class="item">
       <div class="item-div">
         <div class="item-title">身体信号检测与可穿戴设备</div>
         <div class="item-desc">SynSense时识科技开发用于储层神经形态计算的硬件解决方案。SynSense的解决方案通过连续监测可穿戴设备的心电信号、肌电信号等，实现低功率（sub mW）边缘感测处理和异常的瞬时检测。</div>
         <div class="item-btn" @click="open = true">下载文档</div>
       </div>
       <div class="item-div item-img-row">
          <img src="../assets/images/solve/solve3.png">
       </div>
     </div>

    </div>
    <div class="dowload" v-if="open">
      <div class="nei">
        <img src="../assets/images/jj/icon-关闭@2x.png" class="xx" @click="open=false">
        <div class="title">填写信息</div>
        <div class="inp">
          <div>
            <img src="../assets/images/jj/icon-姓名@2x.png">
            姓名</div>
          <input placeholder="请输入姓名">
        </div>
        <div class="inp">
          <div>
            <img src="../assets/images/jj/邮箱@2x.png">
            邮箱</div>
          <input placeholder="请输入邮箱地址">
        </div>
        <div class="btn">下载</div>
      </div>
    </div>
    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navs.vue"
import bases from "@/components/bases.vue"
export default {
  data(){
    return{
      open:false
    }
  },
  components: {
    navs,
    bases
  },
}
</script>

