<style scoped lang="less">
.main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.t_b {
  width:1920px;
  height:auto;
}
.dx_2 {
    animation: dx 2s linear;
}
.opc_3 {
    animation: opc 3s linear;
}

@keyframes dx {
    0% {
        transform: scale(1.5);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes opc {
    0% {
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


  .marbtn-136{
    margin-bottom: 130px;
  }

.title{
  text-align: center;
  p:nth-child(1){
    /*color: #0086CD;*/
    /*font-size: 20px;*/
    /*margin-bottom: 5px;*/
    color: #00B7D0;
    font-size: 40px;
    font-weight: bold;
  }
  p:nth-child(2){
    color: #00242A;
    font-size: 40px;
    font-weight: bold;
  }
}

@keyframes lrs {
  0%{
    left: -120%;
  }
  100%{
    left: -10%;
  }
}
@keyframes lrs2 {
  0%{
    left: 100%;
  }
  100%{
    left: -10%;
  }
}
@keyframes color1 {
  0%{
    color: #0086CD;
  }
  100%{
    color: #FFFFFF;
  }
}



.yyly3{
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  margin-top: 30px;
  font-size: 32px;
  font-weight: 300;
  line-height: 68px;
  color: #00242A;
}

.stories-block-para{
  width: 100%;
  height: auto;
  // border: 1px solid red;
  padding-top: 136px;
  // overflow: hidden;
  // /* background-attachment: fixed; */
  // background-size:auto 114%;
  // background-repeat: no-repeat;
  // background-position: 700% -70px;
  // background-image: url('../assets/image/tiny speck@2x.png');
}

.swiper-block {
  position: relative;
  .banner-center {
    width: 1920px;
    height: 267px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -600px;
    margin-top: -133.5px;
    z-index: 10;
  }
  .swiperimg {
    width: 100%;
  }
  .swiper-pagination {
    width: 100%;
    bottom: 15%;
    /deep/.swiper-pagination-bullet {
      width: 26px;
      height: 26px;
      background-color: #ffffff;
      opacity: 1;
      margin: 0px 29px;
    }
    /deep/.swiper-pagination-bullet-active {
      background-color: #0086cd;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
        border: 4px solid #0086cd;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
      }
    }
  }
}
.item-row1{
  width: 960px;
  margin: 0px auto;
  margin-top: 80px;
  .item{
    height: 430px;
    display: flex;
    justify-content:space-between;
    margin-bottom: 30px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    // border: 1px solid red;
    .item-child{
      width: 49%;
      position: relative;
      .bot-area {
        width: 100%;
        height: 100px;
        text-align: center;
        line-height: 100px;
        font-size: 24px;
        font-weight: bold;
        color: rgb(0, 36, 42);
        opacity: 1;
      }
      img{
        width: 100%;
        height: 100%;
      }
      .item-child-text-center{
        position: absolute;
        top: 55px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        line-height: 36px;
        color: #00242A;
        opacity: 1;
      }
      .item-child-text-left{
        position: absolute;
        top: 55px;
        left: 0;
        width: 100%;
        padding-left: 47px;
        box-sizing: border-box;
        text-align: left;
        font-size: 24px;
        font-weight: bold;
        line-height: 36px;
        color: #00242A;
        opacity: 1;
      }
      /*p {*/
      /*  padding: 36px 41px 60px;*/
      /*  font-size: 14px;*/
      /*  font-weight: 300;*/
      /*  line-height: 32px;*/
      /*  color: #FFFFFF;*/
      /*  opacity: 1;*/
      /*}*/
      div {

      }

    }
    .text-child{
      padding-top: 96px;
      padding-left: 60px;
      padding-right: 71px;
      box-sizing: border-box;
      text-align: center;
      p:first-child{
        margin: 0;
        padding:0 0 10px 0;
        font-size: 18px;
        font-weight: bold;
        line-height: 32px;
        color: #00242A;
        opacity: 1;
      }
      p:last-child{
        padding: 0;
        font-size: 18px;
        font-weight: 300;
        line-height: 32px;
        color: #003F52;
        opacity: 1;
      }
    }
  }
}

.item-row2{
  width: 960px;
  margin: 0px auto;
  margin-top: 80px;
  flex-wrap: wrap;
  display: flex;
  justify-content:space-between;

  .item2{
    width: 49%;
    background: #F4F4F4;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 20px;
    margin-bottom: 30px;
    div:first-child>img{
      width: 470px;
      height: auto;
      opacity: 1;
      border-radius: 16px 16px 0px 0px;
    }
    .title{
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
      color: #00242A;
      opacity: 1;
      margin-top: 35px;
    }
    .bottoms{
      display: flex;
      justify-content:space-between;
      padding: 21px 34px;
      box-sizing: border-box;
      background:white;
      /*margin-top:24px;*/
      border-radius: 0 0 18px 18px;
      span{
        display: inline-block;
        width: 180px;
        height: 54px;
        border: 2px solid #00B7D0;
        opacity: 0.8;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 500;
        color: #00B7D0;
        opacity: 1;
        text-align: center;
        line-height: 54px;
      }
      span:hover {
        cursor:pointer;
      }
    }
  }
}
.yyly4{

  margin-top: 100px;
  text-align: center;
  .mix {
    width: 960px;
    margin: 0px auto;
    margin-top:80px;
    height:466px;
    background: linear-gradient(135deg, #FFFFFF 0%, #F4F4F4 100%);
    opacity: 1;
    border-radius: 20px;
    display:flex;
    flex-flow: row;
    justify-content: space-between;
    box-sizing: border-box;
    .text {
      box-sizing: border-box;
      width: 50%;
      padding:72px 57px;
      font-size: 14px;
      font-weight: 300;
      line-height: 26px;
      color: #003F52;
      opacity: 1;
    }
    .img {
      width:50%;
      height:auto;
      img {
        width:480px;
      }
    }
  }
  .btn {
    width: 350px;
    height: 66px;
    margin: 0 auto;
    margin-bottom: 140px;
    margin-top: 140px;
    background: #00B7D0;
    opacity: 1;
    border-radius: 0px;
    font-size: 24px;
    font-weight: bold;
    line-height: 66px;
    color: #FFFFFF;
    opacity: 1;
    box-shadow: 4px 4px 0 #003F52;
    text-transform: uppercase;
  }

  .btn:hover {
    cursor: pointer;
  }
  .title{
    font-size: 30px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
    color: #00242A;
    opacity: 1;
    margin-bottom: 50px;
    text-transform: uppercase;
  }
  .desc{
    font-size: 32px;
    font-weight: 300;
    line-height: 63px;
    color: #00242A;
    opacity: 1;
  }
  .help{
    font-size: 19px;
    font-weight: 300;
    line-height: 63px;
    color: #00242A;
    opacity: 1;
  }
  .img2-5{
    margin: 100px 0px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.item-row3{
  width: 100%;
  margin: 0px auto;
  /*padding: 0px 70px;*/
  box-sizing: border-box;
  .table-row{
    width: 100%;
    .th1{
      display: flex;
      justify-content: space-between;
      div{
        width: 30%;
        text-align: center;
        border-bottom: 2px solid #D2D2D7;
        padding-bottom: 50px;
        box-sizing: border-box;
        font-size: 24px;
        font-weight: bold;
        line-height: 0px;
        color: #00242A;
        opacity: 1;
      }
    }
  }
}
.item-row4{
  box-sizing: border-box;
  width: 100%;
  background: #00B7D0;
  opacity: 1;
  margin: 100px auto;
  .condition {
    padding-top: 140px;
    margin: 0 auto;
    height: 46px;
    font-size: 46px;
    font-weight: bold;
    line-height: 46px;
    color: #FFFFFF;
    opacity: 1;
    text-transform: uppercase;
  }
  .items {
    box-sizing: border-box;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 50px;
    width:960px;
    text-transform: uppercase;
    height: 26px;
    font-size: 24px;
    font-weight: bold;
    line-height: 26px;

    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: #FFFFFF;
    opacity: 1;

    span {
      width:300px;
      text-align:center;
    }
  }

  .hori_line_container {
    margin: 0 auto;
    box-sizing: border-box;
    width:960px;
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    .hori_line {
      background: #FFFFFF;
      width: 300px;
      height: 1px;
    }
  }

  .middle_content {
    width: 960px;
    height:504px;
    margin: 0 auto;
    display: flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    .hard_demand {
      box-sizing: border-box;
      display: flex;
      flex-flow:column;
      justify-content: space-between;
      align-items: center;
      width:300px;
      height:100%;
      padding: 50px 0 50px 0;
      .icon_text_container {
        height:70px;
        font-size: 14px;
        font-weight: 300;
        color: #FFFFFF;
        opacity: 1;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 40px;
          height: 40px;
        }
        .text {
          line-height:14px;
          height:14px;
        }
      }
      .icon_text_container:nth-child(4) {
        width: 228px;
      }
    }
    .soft_demand {
      width:300px;
      margin-top:58px;
      display:flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      color: #FFFFFF;

      div:nth-child(1){
        height: 24px;
        font-size: 22px;
        font-weight: normal;
        line-height: 24px;
        text-transform: uppercase;
      }
      div:nth-child(2) {
        margin-top:23px;
        height: 15px;
        font-size: 14px;
        font-weight: normal;
        line-height: 15px;
      }
    }

    .down_area {
      width:300px;
      display:flex;
      flex-flow:column;
      justify-content: flex-start;

      .down_content {
        display: flex;
        flex-flow:column;
        width:100%;
        margin-top:58px;
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF;
        opacity: 1;
        .txt1 {
          width:186px;
          height: 24px;
          font-size: 22px;
          font-weight: normal;
          text-transform: uppercase;
        }
        .txt2 {
          height: 14px;
          font-size: 14px;
          font-weight: 300;
          line-height: 14px;
          margin-top:17px;
        }
        .down_btn {
          margin-top:23px;
          width:106px;
          height:28px;
          background: #003F52;
          opacity: 1;
          border-radius: 4px;
          .btn_name {
            height: 28px;
            font-size: 14px;
            font-weight: 300;
            line-height: 28px;
            color: #FFFFFF;
            text-transform: uppercase;
          }
        }
        .down_btn:hover {
          cursor: pointer;
        }
      }
      .down_content:nth-child(2) {
        margin-top:62px;
      }
    }

  }





  /*div:first-child{*/
  /*  background: linear-gradient(135deg, #FFFFFF 0%, #F4F4F4 100%);*/
  /*  opacity: 1;*/
  /*  border-radius: 10px;*/
  /*  position: relative;*/
  /*  .title4{*/
  /*    position: absolute;*/
  /*    top:220px;*/
  /*    left: 79px;*/
  /*    font-size: 36px;*/
  /*    font-weight: bold;*/
  /*    line-height: 36px;*/
  /*    color: #00242A;*/
  /*    opacity: 1;*/
  /*  }*/
  /*}*/
  .item4-child{
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    div{
      width: 49%;
    }
  }
}


.bg-linear{
    background: linear-gradient(135deg, #FFFFFF 0%, #F4F4F4 100%);
    border-radius: 20px;
}

.tab {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
.tab .item {
  display:block;
  width:480px;
  height:60px;
  text-align:center;
  font-size: 26px;
  font-weight: 300;
  line-height: 26px;
  color: #848484;
  opacity: 1;
  text-transform: uppercase;
  .menu2 {
    height:52px;
  }
}

.tab .active {
  width:480px;
  /*height: 32px;*/
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
  color: #00242A;
  opacity: 1;
}


.tab .item .bot{
  display:none;
  /*visibility: hidden;*/
  height: 32px;
  font-size: 32px;
  font-weight: bold;
  line-height: 0px;
  color: #00242A;
  opacity: 1;
}

.tab .active .bot {
  display:block;
  width: 46px;
  height: 12px;
  background: #F37442;
  opacity: 1;
  border-radius: 10px;
  margin-left:214px;
  margin-top:20px;
}

.tab .item:hover {
  cursor:pointer;
}

#block1 {
  background: url(../assets/images/goods/01@2x.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  div {
    background: #003F52;
    opacity: 0;
    border-radius: 16px;
    color:white;
    padding: 70px;
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
  }
  div:hover {
    opacity: 0.8;
  }
}
#block2 {
    background: url(../assets/images/goods/02@.png);
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    width:100%;
    margin-right: 1%;
    div {
      background: #003F52;
      opacity: 0;
      border-radius: 16px;
      color:white;
      padding: 33px;
      font-size: 14px;
      font-weight: 300;
      line-height: 21px;
    }
    div:hover {
      opacity: 0.8;
    }
  }

#block3 {
  background: url(../assets/images/goods/03@2x.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width:100%;
  margin-left:1%;
  div {
    background: #003F52;
    opacity: 0;
    border-radius: 16px;
    color:white;
    padding: 33px;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
  }
  div:hover {
    opacity: 0.8;
  }
}
#block4 {
  background: url(../assets/images/goods/04@2x.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width:100%;
  margin-right:1%;
  div {
    background: #003F52;
    opacity: 0;
    border-radius: 16px;
    color:white;
    padding: 33px;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
  }
  div:hover {
    opacity: 0.8;
  }
}
#block5 {
  background: url(../assets/images/goods/05@2x.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width:100%;
  margin-left: 1%;
  div {
    background: #003F52;
    opacity: 0;
    border-radius: 16px;
    color:white;
    padding: 33px;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
  }
  div:hover {
    opacity: 0.8;
  }
}
</style>
<template>
  <div class="main">
    <navs :active='3'></navs>
    <img style="width:100%;" src="../assets/images/goods/产品矩阵-en.jpg" />

    <div class="stories-block-para">
      <div class="stories-block">
        <div class="title">
          <p>HARDWARE DESIGN</p>
        </div>
        <div class="item-row1">
          <div class='item bg-linear' id="block1">
            <div>
              Speck is a fully event-driven neuromorphic vision SoC. Speck is able to support large-scale spiking convolutional neural network (SCNN) with an fully asynchronous chip architecture. Speck is fully configurable with the spiking neuron capacity of 32 million. Furthermore, it integrates the state-of-art dynamic vision sensor (DVS) that enables fully event-driven based, real-time, highly integrated solution for varies dynamic visual scene. For classical applications, speck can provide intelligence upon the scene at only mWs with a response latency in few ms.
            </div>
          </div>

          <div class='item'>

            <div class='item-child bg-linear' id="block2">
              <div class='item bg-linear mask' style="">
                DYNAP-CNN is a scalable, fully-configurable digital event-driven neuromorphic processor with 1M ReLU spiking neurons per chip for implementing Spiking Convolutional Neural Networks (SCNN). This technology is ideal for always-on, ultra-low power and ultra-low latency event-driven sensory processing applications. With a dedicated interface for dynamic-vision-sensors, it allows direct input of event streams from most of the advanced dynamic-vision-senors in the world, enabling seamless integration and rapid prototyping of models. DYNAP-CNN is fully configurable and supports various types of CNN layers (like ReLU, Cropping, Padding and Pooling) and network models (like LeNet, ResNet and Inception). It provides complete control of your models with extensive programmablility of all of its parameters. In addition, DYNAP-CNN is scalable, enabling implementation of deep neural networks with unlimited number of layers over multiple interconnected DYNAP-CNNs.
              </div>
            </div>
            <div class='item bg-linear' id="block3">
              <div>Xylo is a ultra-low power, always on, low dimentional signal processing dedicated processor. Xylo combines the analog front end(AFE) that can efficiently provide pre-processing functionality to input analog signals. Xylo has is highly re-configurable and scalable, which supports feed-forward, recurrent and reservior and other complex neural network structure. Xylo can be easily combined with various common sensors such as: MEMS microphone , thermal sensor, pressure sensor, vibration sensor, IMU, Gyro, PPG sensor etc.</div>
            </div>
          </div>
          <div class='item'>
            <div class='item bg-linear' id="block4">
              <div>Our latest SE family neuromorphic chip DYNAP-SE2 combines the impeccable energy efficiency of its previous generations with new features for low latency, real-time end-to-end applications using general purpose feed-forward, recurrent and reservoir networks. It’s integrated bio-signal amplifiers make the DYNAP-SE2 a perfect fit for mobile health and robotic applications. Each Chip features 1k redesigned adaptive exponential integrate-and-fire analog ultra low-power spiking neurons and 65k enhanced synapses with configurable delay, weight and short term plasticity. The innovative asynchronous low latency communication infrastructure (patented) enables each neuron to communicate with up to 230k surrounding neurons and infinite scalability via relay neurons for truly large scale networks.</div>
            </div>
            <div class='item bg-linear mask' id="block5">
              <div>Our next-generation neuromorphic chip, DYNAP-SEL, features 1k analog low-power spiking neurons and upto 80k configurable synaptic connections, including 8k synapses with integrated spike-based learning rules. The fan-in and fan-out capabilities of DYNAP-SEL are comparable to the connectivity architectures observed in mammalian cerebral cortex, and permit networks with biologically realistic connectivity to be emulated using DYNAP-SEL.Our technology enables implementation of spiking neural networks with on-chip learning and large fan-in/out network connectivity at an extremely low power-budget in real-time on DYNAP-SEL.</div>
            </div>
<!--            <div @mouseenter="goEnter4" ref="hover7" class='item-child bg-linear'>-->
<!--              <img class="swiperimg" src="../assets/images/goods/6-2.png" style="height:auto;width:100%;" />-->
<!--&lt;!&ndash;              <div class="item-child-text-left ">DYNAP-SEL</div>&ndash;&gt;-->
<!--              <div class="bot-area">DYNAP-SEL</div>-->

<!--            </div>-->
          </div>

        </div>
      </div>
    </div>
    <div class="stories-block-para">
      <div class="stories-block">
        <div class="title">
          <p style="text-transform: uppercase">Software Support</p>
        </div>
        <div class="item-row2">
            <div class="item2">
              <div>
                <img class="swiperimg" src="../assets/images/goods/st01@2x.png" />
              </div>
<!--              <div class='title'>SANMA</div>-->
              <div  class='bottoms'>
                <span @click="goToLink('https://synsense-sys-int.gitlab.io/samna/')">
                  Documentation
                </span>
                <span @click="goToLink('https://synsense-sys-int.gitlab.io/samna/')">
                  Repository
                </span>
              </div>
            </div>
            <div class="item2">  <div>
                <img class="swiperimg" src="../assets/images/goods/st02@2x.png" />
              </div>
<!--              <div class='title'>ROCKPOOL</div>-->
              <div  class='bottoms'>
                <span @click="goToLink('https://synsense.gitlab.io/rockpool/')">
                  Documentation
                </span>
                <span @click="goToLink('https://gitlab.com/synsense/rockpool')">
                  Repository
                </span>
              </div></div>
            <div class="item2">  <div>
                <img class="swiperimg" src="../assets/images/goods/st03@2x.png" />
              </div>
<!--              <div class='title'>SINABS</div>-->
              <div  class='bottoms'>
                <span @click="goToLink('https://synsense.gitlab.io/sinabs/')">
                  Documentation
                </span>
                <span @click="goToLink('https://gitlab.com/synsense/sinabs')">
                  Repository
                </span>
              </div></div>
            <div class="item2">
              <div>
                <img class="swiperimg" src="../assets/images/goods/st04@2x.png" />
              </div>
<!--              <div class='title'>TONIC</div>-->

              <div  class='bottoms'>
                <span @click="goToLink('https://tonic.readthedocs.io/en/latest/about/release_notes.html')">
                  Documentation
                </span>
                <span @click="goToLink('https://github.com/synsense/tonic')">
                  Repository
                </span>
              </div>
            </div>
        </div>
      </div>

    </div>
    <div class="stories-block-para">
      <div class="tab">
        <div :class=c1 @click="goToCont1">
          <div>
            <div style="height:60px">
              <div>DYNAP™-CNN</div>
              <div>development kit</div>
            </div>
            <div class="bot">
            </div>
          </div>
        </div>
        <div :class=c2 @click="goToCont2">
<!--          <div>-->
<!--            <div class="menu2">-->
<!--              Speck tiny Dev_kit-->
<!--            </div>-->
<!--            <div class="bot"></div>-->
<!--          </div>-->
          <div>
            <div style="height:60px">
              <div>Speck tiny</div>
              <div>development kit</div>
            </div>
            <div class="bot">
            </div>
          </div>

        </div>
      </div>

      <div class="yyly4">
        <div ref="content1" :style="{display: showContent1? 'block': 'none'}">
            <p class="title">DYNAP™-CNN development kit</p>
            <div class="mix">
              <div class="text">
                The DYNA™-CNN development kit is powered by SynSense
                DYNAP™-CNN cores, which brings the flexibility of convolutional vision processing to milliwatt energy budgets. It provides the capabilities for real-time presence detection, real-time gesture recognition, real-time object classification, all with mW average energy use. The devkit supports event-based vision applications via direct input, or input via USB. Development of up to nine-layer convolutional networks is made easy with
                <br />
                our open-source Python library SINABS.
              </div>
              <div class="img">
                <img src="../assets/images/goods/mix.png">
              </div>
            </div>
          <div class="item-row4" style="height:1105px;">
            <!--            <img class="swiperimg" src="../assets/images/goods/3-7.png" style="width:100%;"/>-->
            <div class="condition">Requirements</div>
            <div class="items">
              <span>Hardware</span>
              <span>Software</span>
              <span>Tools</span>
            </div>
            <div class="hori_line_container">
              <div class="hori_line"></div>
              <div class="hori_line"></div>
              <div class="hori_line"></div>
            </div>
            <div class="middle_content" style="height:639px;">
              <div class="hard_demand">
                <div class="icon_text_container">
                  <img class="icon" src="../assets/images/goods/icon-内存.png">
                  <span class="text">At least 4GB of physical memory</span>
                </div>
                <div class="icon_text_container">
                  <img class="icon" src="../assets/images/goods/icon-USB.png">
                  <span class="text">USB 3.0 type-A ×2</span>
                </div>
                <div class="icon_text_container">
                  <img class="icon" src="../assets/images/goods/icon-显示器.png">
                  <span class="text">Screen</span>
                </div>
                <div class="icon_text_container" style="height:84px;">
                  <img class="icon" src="../assets/images/goods/icon-01.png">
                  <span class="text" style="height:30px;">Equipped with an integrated/discrete graphics card</span>
                </div>
                <div>
                  <div style="height: 24px;
                                font-size: 22px;
                                font-weight: normal;
                                color:white;
                                line-height: 22px;"
                  >Inivation DVXplorer Lite</div>
                  <div style="font-size: 14px;
                                margin-top:21px;
                                height:15px;
                                font-weight: 300;
                                line-height: 15px;
                                color: #FFFFFF;
                      ">DVS options</div>
                </div>
              </div>
              <div class="sof_demand_container">
                <div class="soft_demand">
                  <div>Ubuntu</div>
                  <div>Ubuntu 18.04/20.04</div>
                </div>
                <div class="soft_demand" style="margin-top:43px;">
                  <div>arch</div>
                  <div>Arch</div>
                </div>
                <div class="soft_demand" style="margin-top:49px;">
                  <div>mac</div>
                  <div>Mac >= 10.15</div>
                </div>
                <div class="soft_demand" style="margin-top:44px;">
                  <div>python</div>
                  <div>Python >= 3.5</div>
                </div>
              </div>

              <div class="down_area">
                <div class="down_content">
                  <div class="txt1">
                    samna
                  </div>
                  <div class="txt2">
                    drive
                  </div>
                </div>
                <div class="down_content">
<!--                  <div class="txt1">-->
<!--                    sinabs-->
<!--                  </div>-->
                  <div class="txt1">
                    sinabs-dynapcnn
                  </div>
                </div>
                <div class="down_content">
                  <div class="txt1">
                    aermanager
                  </div>
                </div>

              </div>
            </div>
            <div class="hori_line_container">
              <div class="hori_line"></div>
              <div class="hori_line"></div>
              <div class="hori_line"></div>
            </div>

          </div>
          <div class="btn" @click="goToLink('/en/dev-kit')">
              Start Experimenting
            </div>
          </div>

        </div>
        <div class="yyly4">
          <div ref="content2" :style="{display: showContent2? 'block' : 'none'}">
            <p class="title">Speck tiny development kit</p>
            <div class="mix"  >
              <div class="text">
                Speck Tiny Kit is powered by SynSense Speck chip, which brings the flexibility of convolutional vision processing to milliwatt energy budgets. It provides the capabilities for real-time presence detection, real-time gesture recognition, real-time object classification within a the size of a USB flash drive.
                <br />
                Development of up to nine-layer convolutional networks with internal dynamic-version-sensor is made easy with our open-source Python library Sinabs and SynSense device toolchain Samna.
              </div>
              <div class="img">
                <img  src="../assets/images/goods/mix2.png"/>
              </div>
            </div>
            <div class="item-row4" style="height:970px;">
              <!--            <img class="swiperimg" src="../assets/images/goods/3-7.png" style="width:100%;"/>-->
              <div class="condition">REQUIREMENTS</div>
              <div class="items">
                <span>hardware</span>
                <span>Software</span>
                <span>TOOLS</span>
              </div>
              <div class="hori_line_container">
                <div class="hori_line"></div>
                <div class="hori_line"></div>
                <div class="hori_line"></div>
              </div>
              <div class="middle_content">
                <div class="hard_demand">
                  <div class="icon_text_container">
                    <img class="icon" src="../assets/images/goods/icon-内存.png">
                    <span class="text">At least 4GB of available physical memory</span>
                  </div>
                  <div class="icon_text_container">
                    <img class="icon" src="../assets/images/goods/icon-USB.png">
                    <span class="text">2 USB 3.0 type-A interfaces</span>
                  </div>
                  <div class="icon_text_container">
                    <img class="icon" src="../assets/images/goods/icon-显示器.png">
                    <span class="text">Screen</span>
                  </div>
                  <div class="icon_text_container">
                    <img class="icon" src="../assets/images/goods/icon-01.png">
                    <span class="text">Equipped with an integrated/discrete graphics card</span>
                  </div>
                </div>
                <div class="soft_demand">
                  <div>Ubuntu</div>
                  <div>Ubuntu 18.04/20.04</div>
                </div>
                <div class="down_area">
                  <div class="down_content">
                    <div class="txt1">
                      samna
                    </div>
                    <div class="txt2">
                      drive
                    </div>
                    <div class="down_btn" @click="goToLink('https://synsense-sys-int.gitlab.io/samna/')">
                      <div class="btn_name">download</div>
                    </div>
                  </div>
                  <div class="down_content">
                    <div class="txt1">
                      sinabs
                    </div>
<!--                    <div class="txt1">-->
<!--                      sinabs-dynapcnn-->
<!--                    </div>-->
                    <div class="down_btn" @click="goToLink('https://sinabs.ai/index.html')">
                      <div class="btn_name">download</div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="hori_line_container">
                <div class="hori_line"></div>
                <div class="hori_line"></div>
                <div class="hori_line"></div>
              </div>

            </div>
            <div class="btn" @click="goToLink('/en/dev-kit')">
              Start Experimenting
            </div>
          </div>


        </div>
      </div>

    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navsen.vue"
import bases from "@/components/basesen.vue"
export default {
  data() {
    return {
      swiperOptions: {
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".rightIcon",
          prevEl: ".leftIcon",
        },
        speed: 300,
        // on: {
        //   // 监听滑动切换事件，返回swiper对象
        //   slideChange: () => {
        //     const i = this.swiper.activeIndex
        //     this.changepicture(i)
        //   }
        // }
        // Some Swiper option/callback...
      },
      showContent1:true,
      showContent2:false,
      c1:['item', 'active'],
      c2:['item'],
    }
  },
  methods: {
    goToLink(value) {
      window.open(value);
    },
    goToCont1() {
      this.c1 = ['item', 'active']
      this.c2 = ['item']
      this.showContent1 = true;
      this.showContent2 = false;
    },
    goToCont2() {
      this.c1 = ['item'];
      this.c2 = ['item', 'active'];
      this.showContent1=false;
      this.showContent2=true;
    },
  },
  components: {
    navs,
    bases
  },
}
</script>

