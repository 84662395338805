<style scoped lang="less">
.main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.banner{
  width: 100%;
  height: auto;
  margin-top: -75px;
}
.one{
  width: 980px;
  height: 550px;
  background:url('../assets/images/mediaCenter/1-0.png') no-repeat left top;
  background-size: 100% 100%;
  margin: 99px auto 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  box-sizing: border-box;
  .info-area {
    box-sizing: border-box;
    width: 980px;
    height: 150px;
    background: #003F52;
    opacity: 0.76;
    border-radius: 0px 0px 16px 16px;
    padding: 21px 33px;
    display:flex;
    flex-flow:column;
    justify-content: space-between;
    .title {
      display: -webkit-box;
      height:74px;
      font-size: 26px;
      font-weight: bold;
      line-height: 36px;
      color: #FFFFFF;
      opacity: 1;
      overflow:hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: normal !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .time {
      height: 14px;
      font-size: 14px;
      font-weight: 300;
      line-height: 14px;
      color: #FFFFFF;
      opacity: 1;
    }
  }
}
.two{
  width: 980px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
}
.item_para{
  .item{
    width: 474px;
    height: auto;
    margin-top: 30px;
    border-radius: 25px;
    overflow: hidden;
    >img{
      width: 100%;
      height: 268px;
    }
    .item_d{
      width: 100%;
      height: auto;
      background-color: #F8F8F8;
      padding: 25px 30px;
      box-sizing: border-box;
      .type{
        font-size: 14px;
        font-weight: 300;
        color: #77777B;
        letter-spacing: 3px;
      }
      .text{
        width: 100%;
        margin-top: 16px;
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
        color: #1D1D1F;
        /*letter-spacing: 2px;*/
        height: 60px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .time{
        width: 100%;
        height: auto;
        margin-top: 25px;
        font-size: 14px;
        font-weight: 400;
        color: #6E6E73;
        letter-spacing: 3px;
      }
    }
  }
  .item:nth-child(even){
    margin-left: 32px;
  }
}
.three{
  width: 980px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 80px;
  .item{
    width: 304px;
    border-radius: 16px;
    .item_d{
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 20px 18px;
      box-sizing: border-box;
    }
    >img{
      height: 164px;
    }
    .text{
      margin-top: 11px;
      font-size: 16px;
      line-height: 24px;
      height: 48px;
    }
    .time{
      margin-top: 35px;
      font-size: 14px;
      letter-spacing: 3px;
    }
  }
  .item+.item{
    margin-left: 34px;
  }
}
.more_news {
  height: 18px;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  color: #00B7D0;
  opacity: 1;
  padding-bottom:140px;
  text-transform: uppercase;
  img {
    margin-top:18px;
    padding-left: 50%;
    margin-left:-8px;
    height: 16px;
    width: 16px;
  }
}
.more_news:hover {
  cursor: pointer;
}
</style>
<template>
  <div class="main">
    <navs :active='4'></navs>
    <img src="../assets/images/mediaCenter/媒体中心-en.jpg" class="banner">
    <div class="one"  @click="goDetial(9)">
      <div class="info-area">
        <div class="title">
          SynSense and Prophesee partner to combine neuromorphic engineering expertise for developing one-chip event-based smart sensing solution for ultra-low power edge-AI       </div>
        <div class="time">
          <span>Latest</span>
          <span style="margin-left: 24px">2021.10.22</span>
        </div>
      </div>
    </div>
    <div class="two item_para">
      <div class="item" @click="goDetial(10)">
        <img src="../assets/images/mediaCenter/en/1-2.png">
        <div class="item_d">
          <div class="type">Latest</div>
          <div class="text">
            SynSense taking an active role in development of open source project Tonic: 21Q4
          </div>
          <div class="time">2021.11.3</div>

        </div>
      </div>
      <div class="item" @click="goDetial(11)">
        <img src="../assets/images/mediaCenter/en/1-3.png">
        <div class="item_d">
          <div class="type">Latest</div>
          <div class="text">
            Europe spends €3 million in neuromorphic R&D around SynSense</div>
          <div class="time">2021.5.8</div>
        </div>
      </div>
      <div class="item" @click="goDetial(12)">
        <img src="../assets/images/mediaCenter/en/1-4.png">
        <div class="item_d">
          <div class="type">Latest</div>
          <div class="text">
            SynSense applies their technology to bi-directional brain implants</div>
          <div class="time">2020.9.22</div>
        </div>
      </div>
      <div class="item" @click="goDetial(13)">
        <img src="../assets/images/mediaCenter/en/1-5.png">
        <div class="item_d">
          <div class="type">Latest</div>
          <div class="text">
            SynSense’s Smart Vision Sensor Speck recognized by World Artificial Intelligence Conference 2019</div>
          <div class="time">2019.9.2</div>
        </div>
      </div>
    </div>
    <div class="item_para three">
        <div class="item" @click="goDetial(14)">
          <img src="../assets/images/mediaCenter/en/2-1.png">
          <div class="item_d">
            <div class="type">Latest</div>
            <div class="text">SynSense’s event-driven CNN architecture recognised with CES innovation award</div>
            <div class="time">2019.7.5</div>
          </div>
        </div>
        <div class="item" @click="goDetial(15)">
          <img src="../assets/images/mediaCenter/en/2-2.png">
          <div class="item_d">
            <div class="type">Latest</div>
            <div class="text">
              DYNAP-CNN — the World’s First 1M Neuron, Event-Driven Neuromorphic AI Processor for Vision Processing</div>
            <div class="time">2019.4.9</div>
          </div>
        </div>
        <div class="item" @click="goDetial(16)">
          <img src="../assets/images/mediaCenter/en/2-3.png">
          <div class="item_d">
            <div class="type">Latest</div>
            <div class="text">SynSense awarded competitive EU startup grant</div>
            <div class="time">2019.3.20</div>
          </div>
        </div>
    </div>
    <div class="more_news">
      <div>learn More</div>
      <img src="../assets/images/mediaCenter/down.png" width="16" height="16" alt="">
    </div>
    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navsen.vue"
import bases from "@/components/basesen.vue"
export default {
  components: {
    navs,
    bases
  },
  methods: {
    goDetial(item_id){
      console.log('详情id: ',item_id);
      this.$router.push('mediaDetail?id=' + item_id)
      
    }
  }
}
</script>

