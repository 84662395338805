<style scoped lang="less">
.main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.banner{
  width: 100%;
  height: auto;
  margin-top: -75px;
}
.one{
  width: 980px;
  height: 550px;
  background:url('../assets/images/mediaCenter/1-0.png') no-repeat left top;
  background-size: 100% 100%;
  margin: 99px auto 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  box-sizing: border-box;
  .info-area {
    box-sizing: border-box;
    width: 980px;
    height: 150px;
    background: #003F52;
    opacity: 0.76;
    border-radius: 0px 0px 16px 16px;
    padding: 21px 33px;
    .title {
      font-size: 26px;
      font-weight: bold;
      line-height: 36px;
      color: #FFFFFF;
      opacity: 1;
    }
    .time {
      height: 14px;
      font-size: 14px;
      font-weight: 300;
      line-height: 58px;
      color: #FFFFFF;
      opacity: 1;
    }
  }
}
.two{
  width: 980px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
}
.item_para{
  .item{
    width: 474px;
    height: auto;
    margin-top: 30px;
    border-radius: 25px;
    overflow: hidden;
    >img{
      width: 100%;
      height: 268px;
    }
    .item_d{
      width: 100%;
      height: auto;
      background-color: #F8F8F8;
      padding: 25px 30px;
      box-sizing: border-box;
      .type{
        font-size: 14px;
        font-weight: 300;
        color: #77777B;
        letter-spacing: 3px;
      }
      .text{
        width: 100%;
        margin-top: 16px;
        font-size: 18px;
        font-weight: bold;
        line-height: 30px;
        color: #1D1D1F;
        /*letter-spacing: 2px;*/
        height: 60px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .time{
        width: 100%;
        height: auto;
        margin-top: 25px;
        font-size: 14px;
        font-weight: 400;
        color: #6E6E73;
        letter-spacing: 3px;
      }
    }
  }
  .item:nth-child(even){
    margin-left: 32px;
  }
}
.three{
  width: 980px;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 80px;
  .item{
    width: 304px;
    border-radius: 16px;
    .item_d{
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
      padding: 20px 18px;
      box-sizing: border-box;
    }
    >img{
      height: 164px;
    }
    .text{
      margin-top: 11px;
      font-size: 16px;
      line-height: 24px;
      height: 48px;
    }
    .time{
      margin-top: 35px;
      font-size: 14px;
      letter-spacing: 3px;
    }
  }
  .item+.item{
    margin-left: 34px;
  }
}
  .more_news {
    height: 18px;
    margin: 0 auto;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    color: #00B7D0;
    opacity: 1;
    padding-bottom:140px;
    img {
      margin-top:18px;
      padding-left: 50%;
      margin-left:-8px;
      height: 16px;
      width: 16px;
    }
  }
  .more_news:hover {
    cursor: pointer;
  }





</style>
<template>
  <div class="main">
    <navs :active='4'></navs>
    <img src="../assets/images/mediaCenter/媒体中心-1.jpg" class="banner">
    <div class="one" @click="goDetial(1)">
      <div class="info-area" >
        <div class="title">
          SynSense时识科技与普诺飞思达成战略合作
        </div>
        <div class="time">
          <span>最新</span>
          <span style="margin-left: 24px">2021.10.15</span>
        </div>
      </div>
    </div>
    <div class="two item_para">
      <div class="item" @click="goDetial(2)">
        <img src="../assets/images/mediaCenter/1-1.png">
        <div class="item_d">
          <div class="type">最新</div>
          <div class="text">
            第六届中国国际人工智能领袖峰会年度评选获奖名单出炉，SynSense时识科技获重磅大奖</div>
          <div class="time">2021.12.16</div>
        </div>
      </div>
      <div class="item" @click="goDetial(3)">
        <img src="../assets/images/mediaCenter/1-2.png">
        <div class="item_d">
          <div class="type">最新</div>
          <div class="text">
            SynSense时识科技亮相Edge AI边缘人工智能峰会，聚焦AIoT，助力万物智联</div>
          <div class="time">2021.11.30</div>
        </div>
      </div>
      <div class="item" @click="goDetial(4)">
        <img src="../assets/images/mediaCenter/1-3.png">
        <div class="item_d">
          <div class="type">最新</div>
          <div class="text">

            SynSense时识科技与苏州能斯达签署战略合作协议，类脑技术赋能机器人皮肤智能感知</div>
          <div class="time">2021.11.30</div>
        </div>
      </div>
      <div class="item" @click="goDetial(5)">
        <img src="../assets/images/mediaCenter/1-4.png">
        <div class="item_d">
          <div class="type">最新</div>
          <div class="text">
            SynSense时识科技再度上榜毕马威中国“芯科技” 新锐企业50评选榜单</div>
          <div class="time">2021.11.8</div>
        </div>
      </div>
    </div>
    <div class="item_para three">
        <div class="item" @click="goDetial(6)">
          <img src="../assets/images/mediaCenter/2-1.png">
          <div class="item_d">
            <div class="type">最新</div>
            <div class="text" style="font-size:16px;">
              行业资讯 | DARPA授予雷神公司一份价值近900万美元的智能光学感知及处理研发合同</div>
            <div class="time">2021.8.16</div>
          </div>
        </div>
        <div class="item" @click="goDetial(7)">
          <img src="../assets/images/mediaCenter/2-2.png">
          <div class="item_d">
            <div class="type">最新</div>
            <div class="text" style="font-size:16px;">神经拟态芯片渐成主流</div>
            <div class="time">2021.8.4</div>
          </div>
        </div>
        <div class="item" @click="goDetial(8)">
          <img src="../assets/images/mediaCenter/2-3.png">
          <div class="item_d">
            <div class="type">最新</div>
            <div class="text" style="font-size:16px;">

              Yole发布2021年类脑市场与技术分析报告，SynSense时识科技C位引领行业发展</div>
            <div class="time">2021.6.11</div>
          </div>
        </div>
    </div>
    <div class="more_news">
      <div>点击查看更多新闻</div>
      <img src="../assets/images/mediaCenter/down.png" width="16" height="16" alt="">
    </div>

    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navs.vue"
import bases from "@/components/bases.vue"
export default {
  components: {
    navs,
    bases
  },
  methods: {
    goDetial(item_id){
      console.log('详情id: ',item_id);
      this.$router.push('mediaDetail?id=' + item_id)
      
    }
  }
}
</script>

