<style scoped lang="less">
.main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.banner {
  width: 100%;
  height: auto;
  margin-top: -75px;
}
.mast_title {
  width: 100%;
  height: auto;
  text-align: center;
  margin: 135px 0 80px 0;
  div {
    width: 100%;
    margin-top: 15px;
    height: 36px;
    font-size: 36px;
    font-weight: bold;
    line-height: 36px;
    color: #00B7D0;
    opacity: 1;
  }
}
.inp {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 960px;
  margin: 0 auto;
  .inp_item {
    width: 470px;
    height: 66px;
    display: flex;
    flex-flow: row nowrap;
    background: #ffffff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    align-items: center;
    padding: 0 28px;
    box-sizing: border-box;
    > div:first-child {
      width: auto;
      height: auto;
      font-size: 18px;
      font-weight: 300;
      line-height: 50px;
      color: #212121;
      opacity: 1;
      position: relative;
      white-space: nowrap;
    }
    .xing {
      color: #ff6a6f;
      font-size: 18px;
      margin-top: 6px;
    }
    > input, select,
    label {
      border: none;
      flex: 1;
      outline: 0;
      margin-left: 10px;
      font-size: 18px;
      height: 100%;
      color: #212121;
    }
    textarea {
      border: none;
      resize: none;
      flex: 1;
      outline: 0;
      margin-left: 10px;
      font-size: 18px;
      height: 100%;
      line-height: 30px;
      color: #212121;
    }
    label {
      line-height: 66px;
      cursor: pointer;
      // border:1px solid red;
    }
  }
  .inp_item + .inp_item {
    margin-left: 20px;
  }
}
.text-area {
  width: 960px;
  // border: 1px solid red;
}
.text-area .inp_item {
  width: 1200px;
  height: 226px;
  display: flex;
  flex-flow: row nowrap;
  background: #ffffff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  align-items: flex-start;
  padding: 0 28px;
  box-sizing: border-box;
  padding: 25px;
  box-sizing: border-box;
}
.text-area .inp_item > div:first-child {
  line-height: 24px;
}
.inp + .inp {
  margin-top: 20px;
}
.btn {
  box-sizing: border-box;
  width: 180px;
  height: 66px;
  line-height: 66px;
  margin: 76px auto 136px auto;
  background: #00b7d0;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 400;
  color: #f0f0f0;
  opacity: 1;
  text-align: center;
}

.inp_file{
  .inp_item+.inp_item{
    width: 280px;
    input{
      width: 0;
    }
  }
  #file1{
    display: none;
  }
  label{
    width: 180px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    background: #00B7D0;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>
<template>
  <div class="main">
    <navs  :active="8"></navs>
    <img src="../assets/images/exploit/开发套件.jpg" class="banner">
    <div class="mast_title">
      <div>申请开发套件</div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>姓名</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>电话</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>邮箱</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>国家</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>公司</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>职位</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp inp_file">
      <div class="inp_item">
        <div>所属行业</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>上传附件</div>
        <div class="xing">*</div>
        <input type="text" id="wjm" readonly>
      </div>

      <label for="file1">选择文件</label>
      <input type="file" id='file1'  style="line-height: 80px;">
    </div>
    <div class="inp">
      <div class="inp_item"  style="flex:1;">
        <div>请选择开发套件</div>
        <div class="xing">*</div>
        <select>
          <option></option>
          <option> DYNAP™-CNN开发板 </option>
          <option> Speck开发板</option>
        </select>
      </div>
    </div>
    <div class="inp text-area">
      <div class="inp_item">
        <div>申请用途</div>
        <div class="xing" style="margin:2px">*</div>
        <textarea > </textarea>
        <!-- <input type="text"> -->
      </div>
    </div>
    <div class="btn">发送</div>
    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navs.vue";
import bases from "@/components/bases.vue";
export default {
  components: {
    navs,
    bases,
  },
};
</script>

