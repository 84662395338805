<style scoped lang="less">
.main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.banner{
  width: 100%;
  height: auto;
  margin-top: -75px;
}
.mast_title{
  width: 100%;
  height: auto;
  text-align: center;
  margin: 135px 0 80px 0;
  div{
    width: 100%;
    font-weight: bold;
    margin-top: 15px;
    height: 36px;
    font-size: 36px;
    line-height: 36px;
    color: #00B7D0;
    opacity: 1;
  }
}
.group{
  width: 1366px;
  height: 540px;
  margin: 0 auto;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  .big{
    width: 816px;
    height: 100%;
    background: url('../assets/images/solve/图片7@2x.png') no-repeat left top;
    background-size: 100% 100%;
    div{
      opacity: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 90px 70px;
      box-sizing: border-box;
      background-color: rgba(0,0,0,0.7);
      font-size: 30px;
      font-weight: 300;
      line-height: 63px;
      color: #FFFFFF;
    }
  }
  .big:hover>div{
    animation: 0.5s opc linear;
    opacity: 1;
  }
  .big2{
    background: url('../assets/images/solve/语音信号处理配图@2x.png') no-repeat left top;
  }
  .big3{
    background: url('../assets/images/solve/身体信号检测配图@2x.png') no-repeat left top;
  }
  @keyframes opc {
    0%{
      opacity: 0;
    }
    100%{
      opacity: 1;
    }
  }
  .little{
    width: 550px;
    height: 100%;
    background-size: 100% 100%;
    background: #00242A;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 46px;
    font-weight: bold;
    line-height: 46px;
    color: #FFFFFF;
  }
}

  .desc{
  margin: 0px auto;
  width: 960px;
  height: auto;
  font-weight: 300;
  opacity: 1;

  font-size: 18px;
  line-height: 38px;
  color: #003F52;
  opacity: 1;
  p{
    margin-bottom: 30px;
    text-align: center;
  }
}
  .advertising{
    max-width: 1920px;
    min-width: 1200px;
    margin: 200px auto;
  }
  .item-row{
    margin: 0px auto;
    width: 960px;
    .item{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 166px;
      .item-div{
        width: 48%;
        height:354px;
        // min-height: 100px;
        text-align: center;
        flex: 1;
        .item-title{
          width:470px;
          text-transform: uppercase;
          /*width: 384px;*/
          height: 29px;
          font-size: 26px;
          font-weight: bold;
          line-height: 36px;
          color: #00B7D0;
          opacity: 1;
        }
        .item-desc{
          font-weight: 300;
          opacity: 1;
          margin: 40px 20px 40px 20px;

          width: 436px;
          font-size: 14px;
          line-height: 24px;
          color: #003F52;
        }
        .item-btn{
          color: #00B7D0;
          display: inline-block;
          width: 300px;
          height: 50px;
          font-size: 18px;
          opacity: 1;
          line-height: 50px;
          cursor: pointer;
          background: #FFFFFF;
          border: 2px solid #00B7D0;
          border-radius: 8px;
          text-transform: uppercase;
        }
        .item-btn:hover{
          background-color:#00B7D0;
          color: #ffffff;
        }
      }
      .item-img-row{

        img{
          width: 470px;
          height: 354px;
          border-radius: 4px;
          box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16);
        }
      }
    }
  }
</style>
<template>
  <div class="main">
    <navs :active='2'></navs>
    <img src="../assets/images/solve/解决方案-en.jpg" class="banner">
    <div class="mast_title">
      <div >Neuromorphic Solutions</div>

    </div>
     <div class="desc">
       <p>
         SynSense is the world's leading supplier of neuromorphic intelligence and application solutions. At SynSense, our vast expertise in designing low-power neuromorphic circuits, and our knowledge in theory of neuromorphic, cortical computation and machine learning, gives us a unique perspective and vision beyond what is believed to be possible with today’s technologies.
         <p>
         Our full-custom neuromorphic processors will be used in a variety of artificial intelligence edge computing applications that require ultra-low-power and ultra-low-latency features, including autonomous robots, always-on co-processors for mobile and embedded devices, wearable healthcare systems, security, IoT applications, and computing at the network edge. As a “full-stack” neuromorphic engineering company, SynSense delivers complete solutions, including custom hardware and software configurations to meet specific application needs.
      </p>
      </div>
   <div class='advertising'>
    <img src="../assets/images/solve/advertising.png">
   </div>
   <div class='item-row'>
     <div class="item">
       <div class="item-div">
         <div class="item-title">Smart Vision Processing</div>
         <div class="item-desc">
           SynSense is developing dedicated event-driven neuromorphic processors for real-time vision processing. The ultra-low-power and ultra-low-latency of our processors pave the path for always-on IoT devices and edge-computing applications like gesture recognition, face or object detection, location, tracking and surveillance. Our processors are specifically designed for integration with most state-of-the-art event-based image sensors.         </div>
         <div class="item-btn">Download document</div>
       </div>
       <div class="item-div item-img-row">
          <img src="../assets/images/solve/solve1.png">
       </div>
     </div>
     <div class="item">
       <div class="item-div item-img-row">
          <img src="../assets/images/solve/solve2.png">
       </div>
       <div class="item-div">
         <div class="item-title">Autonomous Navigation</div>
         <div class="item-desc">SynSense is exploring novel algorithms and dedicated processors for processing 3D point-cloud data in real-time. We are investigating segmentation, tracking and recognition of objects in 3D. The low latency of our processors allows us to exploit the various advantages of fast solid-state LiDAR. We can use our technology to perform event detection and control for autonomous vehicle navigation.</div>
         <div class="item-btn">Download document</div>
       </div>
     </div>
     <div class="item">
       <div class="item-div">
         <div class="item-title">Auditory Processing</div>
         <div class="item-desc">SynSense is working on ultra-low-power always-on key-word and command detection based on Spiking Neural Networks (SNNs). Our technology enables us to process data adjacent to the sensor using novel algorithms specially tailored for our processors. This enables IoT devices powered by our processors to have smart audio capabilities at ultra-low power and latency.</div>
         <div class="item-btn">Download document</div>
       </div>
       <div class="item-div item-img-row">
          <img src="../assets/images/solve/solve3.png">
       </div>
     </div>

    </div>
    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navsen.vue"
import bases from "@/components/basesen.vue"
export default {
  components: {
    navs,
    bases
  },
}
</script>

