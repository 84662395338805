<style scoped lang="less">
.main {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.new {
  width: 960px;
  margin: 0 auto;
  padding-top: 140px;
  padding-bottom: 316px;
  .header {
    width: 100%;
    text-align: center;
    height: 14px;
    font-size: 14px;
    font-weight: 300;
    line-height: 14px;
    color: #86868b;
    opacity: 1;
    span:nth-child(2) {
      margin-left: 10px;
      border-left: 1px solid;
      padding-left: 10px;
    }
  }
  .title {
    margin: 0 auto;
    margin-top: 40px;
    font-size: 30px;
    width: 806px;
    text-align: center;
    font-weight: bold;
    line-height: 51px;
    color: #003f52;
    opacity: 1;
  }
  .content {
    margin-top: 40px;
    overflow-y: auto;
  }
}
</style>
<template>
  <div class="main">
    <navs></navs>
    <div class="new">
      <div class="header">
        <span>发布时间</span>
        <span>{{ article.publish_date }}</span>
      </div>
      <div class="title">
        {{ article.title }}
      </div>
      <div class="content" v-html="article.content"></div>
    </div>

    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navs.vue";
import bases from "@/components/bases.vue";
import axios from "axios";
export default {
  components: {
    navs,
    bases,
  },
  data() {
    return {
      article: "",
    };
  },
  methods: {
    getArticle(id) {
      axios
        .post("https://pm-gs.mzdxj.cn/api/article/show", { article_id: id })
        .then((response) => {
          this.article = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
  mounted() {
    console.log(this.$route.query);
    function myScroll() {
      undefined;
      var x = document.body.scrollTop || document.documentElement.scrollTop;
      window.scrollTo(0, 0);
    }
    myScroll()
    this.getArticle(this.$route.query.id);
  },
};
</script>

