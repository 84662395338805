<template>
  <div class="home-cont" style="overflow: hidden;">
    <header class="header" :class="{'isfixed': isfixed}">
        <div>
          <img class="logo" src="../assets/image/组 73@2x.png" alt="" />
          <div>
            <div class="text choose">首页</div>
            <div class="text">核心技术</div>
            <div class="text">解决方案</div>
            <div class="text">产品矩阵</div>
            <div class="text">媒体中心</div>
            <div class="text">类脑社区</div>
            <div class="text">关于我们</div>
            <div class="text">商务合作</div>
            <div class="text">开发套件</div>
            <div class="circle"></div>
            <div class="text">CN</div>
            <img class="search" src="../assets/image/搜索@2x11.png" alt="">
          </div>
        </div>
      </header>
    <div class="swiper-block">
      <swiper style="width: 100%;" ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="swiperimg dx_2" src="../assets/image/banner@2x.png" />
          <img class="banner-center opc_3" src="../assets/image/字@2x.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/banner02@2x (1)11.png" />
          <!-- <img class="banner-center" src="../assets/image/字@2x.png" /> -->
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/banner@2x.png" />
          <img class="banner-center" src="../assets/image/字@2x.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/banner02@2x (1)11.png" />
          <!-- <img class="banner-center" src="../assets/image/字@2x.png" /> -->
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="about-us">
      <div class="about-us-cont" ref="aboutUsCont">
        <div class="about-us-left">
          <div class="title">
            <p style="margin-bottom: 10px;">ABOUT US</p>
            <p>
              <!-- SynSense -->
              <img src="../assets/image/SynSense@2x.png" style="width:235px;height:51px;margin:0 auto;">
            </p>
          </div>
          <p class="texts">
            全球领先的类脑智能与应用解决方案提供商
            <br/>
            以苏黎世大学类脑科学为基础，聚焦边缘计算
            <br/>
            提供横跨感知与计算的智能应用解决方案
          </p>
          <!-- <p class="text1">SynSense时识科技是全球领先的类脑智能与应用解决方案提供商。</p>
          <p class="text2">以苏黎世大学类脑科学为基础，聚焦边缘计算， 提供横跨感知与计算的智能应用解决方案。</p> -->
          <!-- <img class="img1" src="../assets/image/查看更多@2x.png" alt="">
          <img class="img2"  src="../assets/image/icon01@2x.png" alt=""> -->
        </div>
        <!-- <div class="bg-blcok" ref="bgBlcok">
          <img src="../assets/image/价值链接世界@2x.png" alt="">
        </div> -->
      </div>
    </div>
    <div class="about-us-img" ref="about">
      <img src="../assets/image/黑底@2x.png">
      <img class="cont_img" src="../assets/image/组 44@2x.png">
      <img class="cont_img" src="../assets/image/SynSense@2x.png">
    </div>
    <div class="stories-block-para">
      <div class="stories-block">
      <div class="title">
        <p style="letter-spacing: 5px;">SUCCESS STOPRIES</p>
        <p>应用领域</p>
      </div>
      <div class="yyly">
        <img src="../assets/image/yyly1111.png" >
        <!-- <img class="positionbg" src="../assets/image/芯片底图@2x.png"/> -->
        <div class="yylyfiexd"></div>
        <!-- <img src="../assets/image/tiny speck@2x.png" > -->
      </div>
      <div class="yyly2">
        <div class="yylyfiexd2"></div>
        <img src="../assets/image/yyly4444.png" >
        <!-- <img class="positionbg" src="../assets/image/芯片底图@2x.png"/> -->
        <!-- <img src="../assets/image/tiny speck@2x.png" > -->
      </div>
      <!-- <ul class="tablelist">
        <li @click="tablelistIndex = 0">
          <img v-if="tablelistIndex === 0" src="../assets/image/智能玩具-选中@2x.png" alt="">
          <img v-else src="../assets/image/智能玩具-未选中@2x.png" alt="">
        </li>
        <li @click="tablelistIndex = 1">
          <img v-if="tablelistIndex === 1" src="../assets/image/智能养殖-选中@2x.png" alt="">
          <img v-else src="../assets/image/智能养殖-未选中@2x.png" alt="">
        </li>
        <li @click="tablelistIndex = 2">
          <img v-if="tablelistIndex === 2" src="../assets/image/智能家居-选中@2x.png" alt="">
          <img v-else src="../assets/image/智能家居-未选中@2x.png" alt="">
        </li>
        <li @click="tablelistIndex = 3">
          <img v-if="tablelistIndex === 3" src="../assets/image/智能安防-选中@2x.png" alt="">
          <img v-else src="../assets/image/智能安防-未选中@2x.png" alt="">
        </li>
      </ul>
      <img v-if="tablelistIndex === 0" class="cont-img" src="../assets/image/场景-智能玩具@2x.png" alt="">
      <img v-if="tablelistIndex === 1" class="cont-img" src="../assets/image/场景-智慧养殖@2x.png" alt="">
      <img v-if="tablelistIndex === 2" class="cont-img" src="../assets/image/场景-智能家居@2x.png" alt="">
      <img v-if="tablelistIndex === 3" class="cont-img" src="../assets/image/场景-智能安防@2x.png" alt="">
      <ul class="iconList">
        <li :class="{'chooseLi' : tablelistIndex === 0 || tablelistIndex === 2}">
          <div>
            <img class="img1" style="width:41.83px" src="../assets/image/闪电@2x.png" alt="">
            <img class="img2" style="width:41.83px" src="../assets/image/闪电1@2x.png" alt="">
          </div>
          <p>能效降低</p>
          <p>100-1000倍</p>
        </li>
        <li :class="{'chooseLi' : tablelistIndex === 0 || tablelistIndex === 1}">
          <div>
            <img class="img1" style="width:68.67px" src="../assets/image/成本@2x.png" alt="">
            <img class="img2" style="width:68.67px" src="../assets/image/成本1@2x.png" alt="">
          </div>
          <p>成本降低</p>
          <p>100-1000倍</p>
        </li>
        <li :class="{'chooseLi' : tablelistIndex === 0
          || tablelistIndex === 2 || tablelistIndex === 3}">
          <div>
            <img class="img1" style="width:85.42px" src="../assets/image/生物@2x.png" alt="">
            <img class="img2" style="width:85.42px" src="../assets/image/生物1@2x.png" alt="">
          </div>
          <p>像生物一样思考</p>
        </li>
      </ul> -->
    </div>
    </div>
    <div class="title" style="padding-bottom:100px;">
        <p style="letter-spacing: 5px;">SOLUTION</p>
        <p>解决方案</p>
    </div>
    <ul class="features-list">
      <li>
        <img class="basemap" src="../assets/image/30@2x.png" alt="">
        <div class="mask">
          <p>智能视觉处理</p>
          <p>时识科技开发事件驱动的神经形态处理器，用于实时视觉处理。SynSense时识科技低功耗。</p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="">
        </div>
      </li>
      <li>
        <img class="basemap" src="../assets/image/20@2x.png" alt="">
        <div class="mask">
          <p>语音信号处理</p>
          <p>SynSense时识科技开发基于脉冲神经网络（SNN）的低功耗的语音识别与口令识别。</p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="">
        </div>
      </li>
      <li>
        <img class="basemap" src="../assets/image/10@2x.png" alt="">
        <div class="mask">
          <p>智能服务助手</p>
          <p>SynSense时识科技开发基于脉冲神经网络（SNN）的低功耗的语音识别与口令识别。</p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="">
        </div>
      </li>
      <li>
        <img class="basemap" src="../assets/image/17@2x.png" alt="">
        <div class="mask">
          <p>智能可穿戴设备</p>
          <p>SynSense时识科技开发用于储层神经形态计算的硬件解决方案。</p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="">
        </div>
      </li>
    </ul>
    <div class="btnmore">
      <span>查看全部项目</span>
    </div>
    <div class="news-block">
      <div class="news-block-cont">
        <div class="title">
          <p style="letter-spacing: 5px;">LATEST MEWE</p>
          <p>最新资讯</p>
        </div>
        <ul class="news-list clearfix">
          <li>
            <div class="img-block">
              <img src="../assets/image/微信截图_20220111165000@2x.png" alt="">
              <div></div>
            </div>
            <p>SynSense时识科技获全国颠覆性技术创新大赛优胜项目</p>
            <p>2022.1.5  09:36</p>
          </li>
          <li>
            <div class="img-block">
              <img src="../assets/image/VCG41523357070@2x.png" alt="">
              <div></div>
            </div>
            <p>SynSense时识科技获全国颠覆性技术创新大赛优胜项目</p>
            <p>2022.1.5  09:36</p>
          </li>
          <li>
            <div class="img-block">
              <img src="../assets/image/微信截图_20220111164159@2x.png" alt="">
              <div></div>
            </div>
            <p>2021 Great Tech Awards 正式揭晓，SynSense时识科技获“杰出科技创新奖”</p>
            <p>2022.1.5  09:36</p>
          </li>
        </ul>
        <!-- <div class="news-list sj-news-list">
          <img src="../assets/image/新闻01@2x.png">
          <img src="../assets/image/新闻02@2x.png">
          <img src="../assets/image/新闻03@2x.png">
        </div> -->
      </div>
    </div>
    <div class="Partner">
      <div class="title">
        <p style="letter-spacing: 5px;">PARTNER</p>
        <p>合作伙伴</p>
      </div>
      <!-- <div class="imgs">
        <img src="../assets/image/01-彩色@2x.png" alt="">
        <img src="../assets/image/02-彩色@2x.png" alt="">
        <img src="../assets/image/03-彩色@2x.png" alt="">
        <img src="../assets/image/04-彩色@2x.png" alt="">
      </div> -->
      <div class="imgs">
        <swiper style="width: 100%;" ref="mySwiper" :options="swiperOptions1">
          <swiper-slide>
            <img src="../assets/image/logo01@2x.png" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo02@2x.png" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo03@2x.png" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo04@2x.png" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo05@2x.png" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo01@2x.png" alt="">
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo02@2x.png" alt="">
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="achievement-block-para">
      <div class="achievement-block">
        <div class="wz">
          <div>SUBSCRIBE</div>
          <div>订阅最新成果</div>
        </div>
        <div class="sr">
          <input type="text" placeholder="请输入您的邮箱">
          <img src="../assets/image/发送@2x.png" alt="">
        </div>
      </div>
    </div>
    <img class="footer" src="../assets/image/底部@2x11.png" alt="">
  </div>
</template>
<script>
export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      isfixed: false,
      tablelistIndex: 0,
      swiperOptions: {
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        pagination: {
          el: '.swiper-pagination',
        },
        navigation: {
          nextEl: '.rightIcon',
          prevEl: '.leftIcon',
        },
        speed: 300,
        // on: {
        //   // 监听滑动切换事件，返回swiper对象
        //   slideChange: () => {
        //     const i = this.swiper.activeIndex
        //     this.changepicture(i)
        //   }
        // }
        // Some Swiper option/callback...
      },
      swiperOptions1: {
        slidesPerView: 4,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollToTop);
    // const that = this;
    // window.onresize = () => (() => {
    //   that.setBGblock();
    // })();
    // this.setBGblock();
  },
  methods: {
    scrollToTop() {
      const scrollTop = window.pageYOffset
        || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop >= 30) {
        this.isfixed = true;
      } else {
        this.isfixed = false;
      }
      let top = document.getElementsByClassName('about-us-img')[0].offsetTop - 95;
      let top2 = top - 300;
      if(scrollTop >top2 &&  scrollTop <= top){
        let num = top - scrollTop;

        document.getElementsByClassName('about-us-img')[0].style.transform = 'scale('+Number((1+((num / 300)*0.3)).toFixed(2)) +')'
        // document.getElementsByClassName('cont_img')[0].style.opacity = 1-(num / 300);
      }
      if(scrollTop >= top ){
        document.getElementsByClassName('about-us-img')[0].style.transform = 'scale(1)'
        // document.getElementsByClassName('cont_img')[0].style.opacity = 1;
        document.getElementsByClassName('cont_img')[0].style.transform = 'translateY(0px) scale(1)'
        document.getElementsByClassName('cont_img')[1].style.transform = 'translateY(0px) scale(1)'
      }
      let yy = document.getElementsByClassName('yyly')[0].offsetTop - 900;
      let yy2 = yy + 1000+900
      let yynum = yy2 - scrollTop
      if(scrollTop >= yy && scrollTop <= yy2){
        document.getElementsByClassName('yylyfiexd')[0].style.backgroundPositionY = Number((((yynum / 1000)*-200)).toFixed(2))+"px"
        // console.log(Number((((yynum / 3050)*50)+20).toFixed(2)))
      }
      let yyly = document.getElementsByClassName('yyly2')[0].offsetTop - 900;
      let yyly2 = yyly + 1200
      let yylynum = yyly2 - scrollTop
      if(scrollTop >= yyly && scrollTop <= yyly2){
        document.getElementsByClassName('yylyfiexd2')[0].style.backgroundPositionY = Number((((yylynum / 1200)*600)).toFixed(2))+"px"
        // console.log(Number((((yynum / 3050)*50)+20).toFixed(2)))
      }


      let wztop = document.getElementsByClassName('about-us-img')[0].offsetTop - 95;
      let opc = document.getElementsByClassName('texts')[0].offsetTop - 95;
      let wztop2 = wztop - 500;
      if(scrollTop >wztop2 && scrollTop >= opc){
        document.getElementsByClassName('cont_img')[0].style.opacity = 1;
        document.getElementsByClassName('cont_img')[1].style.opacity = 0;
      }else{
        document.getElementsByClassName('cont_img')[0].style.opacity = 0;
        document.getElementsByClassName('cont_img')[1].style.opacity = 0;
      }
      if(scrollTop >wztop2 &&  scrollTop <= wztop){
        let num = wztop - scrollTop;
        let dax = Number((1-(num / 500)).toFixed(2))
        let opc = Number((1-(num / 500)).toFixed(2))
        let opc1 = Number(((num / 500)).toFixed(2))
        if(dax<=0.4){
          dax = 0.4
        }
        document.getElementsByClassName('cont_img')[0].style.transform = 'translateY('+Number(-num)+'px) scale('+dax+')'
        document.getElementsByClassName('cont_img')[1].style.transform = 'translateY('+Number(-num)+'px) scale('+dax+')'
        document.getElementsByClassName('cont_img')[0].style.opacity = opc;
        document.getElementsByClassName('cont_img')[1].style.opacity = opc1;
        // console.log( 'translateY('+Number(-num)+'px) scale('+dax+')')
      }
    },
    setBGblock() {
      this.$refs.bgBlcok.style.width = `${380 + this.$refs.aboutUsCont.getBoundingClientRect().left}px`;
    },
  },
};
</script>

<style lang="less" scoped>
// @font-face {
//   font-family: 'en';  //重命名字体名
//   src: url('../assets/font/gothambook.otf');  //引入字体
// }
// @font-face {
//   font-family: 'cn';  //重命名字体名
//   src: url('../assets/font/思源黑体 KR-Regular.TTF');  //引入字体
// }
*{
  font-family: en,cn;
}
.dx_2 {
    animation: dx 2s linear;
}
.opc_3 {
    animation: opc 3s linear;
}

@keyframes dx {
    0% {
        transform: scale(1.5);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes opc {
    0% {
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.header{
  background-color: rgba(0, 0, 0, 0);
  color: #00242A;
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  padding: 17px 0px;
  width: 100%;
  >div{
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    >div{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .logo{
    width: 210px;
    height: 57px;
  }
  .text{
    margin-right: 24px;
  }
  .choose{
    color: #0086CD;
    position: relative;
    &::after{
      content: '';
      height: 3px;
      position: absolute;
      width: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
      left: 50%;
      bottom: -10px;
      background-color: #0086CD;
    }
  }
  .circle{
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #00242A;
    margin-right: 8px;
  }
  .search{
    width: 16px;
  }
}
.isfixed{
  position: fixed;
  top: 0px;
  background-color: #fff;
}
.swiper-block{
  position: relative;
  .banner-center{
    width: 1200px;
    height: 267px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -600px;
    margin-top: -133.5px;
    z-index: 10;
  }
  .swiperimg{
    width: 100%;
  }
  .swiper-pagination{
    width: 100%;
    bottom: 15%;
    /deep/.swiper-pagination-bullet{
      width: 26px;
      height: 26px;
      background-color: #FFFFFF;
      opacity: 1;
      margin: 0px 29px;
    }
    /deep/.swiper-pagination-bullet-active{
      background-color: #0086CD;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
        border: 4px solid #0086CD;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
      }
    }
  }
}
.title{
  text-align: center;
  p:nth-child(1){
    color: #0086CD;
    font-size: 20px;
    margin-bottom: 5px;
  }
  p:nth-child(2){
    color: #00242A;
    font-size: 46px;
    font-weight: bold;
  }
}
.about-us{
  width: 100%;
  background-color: #FFF;
  height: auto;
  padding-bottom: 122px;
  overflow: hidden;
  .about-us-cont{
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    position: relative;
    .about-us-left{
      width: 100%;
      // padding-right: 190px;
      box-sizing: border-box;
      color: #17181A;
      font-size: 18px;
      text-align: center;
      .title{
        text-align: center;
        margin-top: 122px;
        letter-spacing: 5px;
      }
      .texts{
        width: 630px;
        height: 180px;
        font-size: 30px;
        font-weight: 500;
        line-height: 60px;
        color: #17181A;
        opacity: 1;
        margin: 10px auto 0;
      }
      .text1{
        margin-top: 38px;
      }
      .text2{
        margin-top: 25px;
      }
      .img1{
        width: 180px;
        margin-top: 139px;
      }
      .img2{
        width: 477px;
        margin-top: 69px;
      }
    }
    .bg-blcok{
      position: absolute;
      width: 380px;
      height: 100%;
      left: 820px;
      background-color: rgba(0, 0, 0, 0.8);
      img{
        width: 57%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
      }
    }
  }
}
.stories-block{
  width: 1200px;
  height: auto;
  margin: auto;
  .title{
    margin-top: 128px;
  }
  .tablelist{
    display: flex;
    justify-content: space-between;
    margin-top: 67px;
    li{
      width: 290px;
      cursor: pointer;
      img{
        width: 100%;
      }
    }
  }
  .cont-img{
    width: 1180px;
    margin: auto;
    margin-top: 91px;
  }
  .iconList{
    display: flex;
    justify-content: space-between;
    margin-top: 70px;
    li{
      text-align: center;
      width: 220px;
      cursor: pointer;
      color: #00242A;
      >div{
        width: 220px;
        height: 220px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 4px dashed #505156;
        border-radius: 100%;
        img{
          width: 90px;
        }
        .img1{
          display: block;
        }
        .img2{
          display: none;
        }
      }
      p:nth-child(2){
        font-size: 38px;
        margin-top: 38px;
        font-weight: bold;
      }
      p:nth-child(3){
        font-size: 30px;
        margin-top: 5px;
      }
      // &:hover{
      //   >div{
      //     border:none;
      //     background-color: #0086CD;
      //     .img1{
      //       display: none;
      //     }
      //     .img2{
      //       display: block;
      //     }
      //   }
      // }
    }
    .chooseLi{
      >div{
          border:none;
          background-color: #0086CD;
          .img1{
            display: none ;
          }
          .img2{
            display: block;
          }
        }
    }
  }
}
.features-list{
  display: flex;
  li{
    flex: 1;
    position: relative;
    .basemap{
      width: 100%;
    }
    .mask{
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      left: 0px;
      top: 0px;
      color: #FFFFFF;
      opacity: 1;
      transition: all 1s;
      p{
        margin: auto;
      }
      p:nth-child(1){
        width: 292px;
        max-width: 100%;
        height: 84px;
        text-align: center;
        font-size: 30px;
        margin-top: 207px;
      }
      p:nth-child(2){
        width: 354px;
        max-width: 90%;
        text-align: center;
        font-size: 20px;
        margin-top: 57px;
      }
    }
    .search-block{
      width: 156px;
      height: 156px;
      border-radius: 100%;
      background-color: #0086CD;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
      img{
        width: 50px;
      }
    }
    &:hover{
      .mask{
        opacity: 0;
      }
      .search-block{
        transition: all 1s;
        opacity: 1;
      }
    }
  }
}
.btnmore{
  width: 208px;
  height: 54px;
  border: 2px solid #0086CD;
  border-radius: 27px;
  font-size: 22px;
  line-height: 54px;
  text-align: center;
  color: #0086CD;
  margin: 71px auto;
  margin-bottom: 0px;
  cursor: pointer;
  position: relative;
  background-color: transparent;
  overflow: hidden;
  box-sizing: border-box;
  span{
    position: relative;
    z-index: 10;
  }
}
.btnmore:hover{
  border: none;
  line-height: 56px;
  color: #fff;
  animation: 1s color1 linear;
}
.btnmore:hover::before{
  content: "";
  width: 120%;
  height: 100%;
  position: absolute;
  left: -10%;
  top: 0;
  background-color: #0086CD;
  z-index: -1px;
  animation: 1s lrs linear;
}
.btnmore:hover::after{
  content: "";
  width: 120%;
  height: 100%;
  position: absolute;
  left: 110%;
  top: 0;
  background-color: #0086CD;
  z-index: -1px;
  animation: 1s lrs2 linear;
}
@keyframes lrs {
  0%{
    left: -120%;
  }
  100%{
    left: -10%;
  }
}
@keyframes lrs2 {
  0%{
    left: 100%;
  }
  100%{
    left: -10%;
  }
}
@keyframes color1 {
  0%{
    color: #0086CD;
  }
  100%{
    color: #FFFFFF;
  }
}
.news-block{
  width: 100%;
  height: auto;
  margin: auto;
  // margin-top: 120px;
  padding-top: 134px;
  padding-bottom: 153px;
  background: #FFFFFF;
  overflow: hidden;
  .news-block-cont{
    width: 1200px;
    margin: auto;
    .title{
      text-align: left;
    }
    .news-list{
      display: flex;
      position: relative;
      left: 0px;
      margin-top: 92px;
      justify-content: space-between;
      li{
        width: 482px;
        height: 400px;
        margin-right: 22px;
        background-color: red;
        flex-shrink: 0;
        border-radius: 10px;
        background-color: #F8F8F8;
        overflow: hidden;
        .img-block{
          position: relative;
          width: 100%;
          height: 272px;
          img{
            width: 100%;
            height: 272px;
          }
          div{
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 1;
          }
        }
        &:hover{
          cursor: pointer;
          background-color: #FFFFFF;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
          .img-block{
            div{
              opacity: 0;
            }
          }
        }
        p{
          padding: 0px 20px;
        }
        p:nth-child(2){
          color: #1D1D1F;
          margin-top: 25px;
          word-break:keep-all;/* 不换行 */
          white-space:nowrap;/* 不换行 */
          overflow:hidden;
          text-overflow:ellipsis;
        }
        p:nth-child(3){
          color: #6E6E73;
          font-size: 12px;
          margin-top: 42px;
        }
      }
    }
  }
}
.Partner{
  width: 100%;
  height: 558px;
  background: #fff;
  padding-top: 140px;
  box-sizing: border-box;
  .imgs{
    width: 1200px;
    margin: auto;
    margin-top: 116px;
    img{
      width: 300px;
      margin-left: 46px;
    }
  }
}
.achievement-block-para{
  width: 100%;
  height: auto;
  background: linear-gradient(180deg, #FFFFFF 0%, #E6E6E6 100%);
}
.achievement-block{
  width: 1200px;
  height: auto;
  margin: 0 auto;
  // background-image: url('../assets/image/订阅-底@2x.png');
  // background-repeat: no-repeat;
  // background-size: 100% 100%;
  padding-top: 114px;
  padding-bottom: 85px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  >.sr{
    width: 730px;
    height: 62px;
    // padding: 4px;
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    border-radius: 8px;
    margin-left: 178px;
    input{
      flex: 1;
      height: 54px;
      line-height: 54px;
      padding: 0px 23px;
      border:none;
    }
    img{
      width: 150px;
      height: 54px;
    }
  }
  >.wz{
    >div:first-child{
      width: auto;
      height: auto;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      color: #00242A;
      letter-spacing: 5px;
      opacity: 1;
    }
    >div:last-child{
      font-size: 46px;
      font-weight: bold;
      line-height: 58px;
      color: #00242A;
      letter-spacing: 0.6px;
      opacity: 1;
      margin-top: 8px;
    }
  }
}
.footer{
  width: 100%;
}
.sj-news-list{
  margin-top: 51px;
  padding: 0px 41px;
  box-sizing: border-box;
  img{
    cursor: pointer;
    width: 350px;
    height: 364px;
  }
  img:nth-child(2){
    margin-top: 19px;
  }
  img:hover{
    box-shadow: 0px 0px 10px #dfdfdf;
    transform: translateY(-5px);
  }
  // img+img{
  //   margin-left: 32px;
  // }
}
.about-us-img{
  width: 100%;
  height: auto;
  position: relative;
  transform: scale(1.2);
  img{
    width: 100%;
    height: auto;
  }
  .cont_img{
    transform:translateY(-500px) scale(0.3);
    position: absolute;
    left:50%;
    top:50%;
    margin-left: -229px;
    margin-top: -49px;
    width: 458px;
    height: 98px;
    opacity: 0;
  }
}
.yyly{
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  // justify-content: center;
  margin-top: 80px;
  // padding-bottom: 128px;
  // margin-bottom: 50px;
  img:first-child{
    width: 416px;
    height: 918px;
  }
  position: relative;
  .yylyfiexd{
    width: 766px;
    height: 1000px;
    margin-left: 16px;
    // background-attachment: fixed;
    background-size:100% 1376px;
    background-repeat: repeat;
    background-position: center center;
    background-image: url('../assets/image/ht11.png');
    // background-position-y: 219.37%;
  }
  .positionbg{
    // width: 1711px !important;
    // height: auto !important;
    // position: absolute;
    // right: 40%;
    // top: -235px;
    // transform: translateX(100%);
  }
}
.yyly2{
  width: 100%;
  height: auto;
  display: flex;
  // justify-content: center;
  margin-top: 80px;
  padding-bottom: 50px;
  margin-bottom: 50px;
  overflow: hidden;
  img{
    width: 415px;
    height: 918px;
  }
  position: relative;
  .yylyfiexd2{
    margin-right: 95px;
    width: 690px;
    height: 1200px;
    // background-attachment: fixed;
    background-size:100% 1648px;
    background-repeat: repeat;
    background-position: center center;
    background-image: url('../assets/image/ht22.png');
    // background-position-y: 219.37%;
    margin-top: -150px;
  }
  .positionbg{
    // width: 1711px !important;
    // height: auto !important;
    // position: absolute;
    // right: 40%;
    // top: -235px;
    // transform: translateX(100%);
  }
}
.stories-block-para{
  width: 100%;
  height: auto;
  overflow: hidden;
  // /* background-attachment: fixed; */
  // background-size:auto 114%;
  // background-repeat: no-repeat;
  // background-position: 700% -70px;
  // background-image: url('../assets/image/tiny speck@2x.png');
}
</style>
