<style scoped lang="less">
.main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.banner{
  width: 100%;
  height: auto;
  margin-top: -75px;
}
.mast_title{
  width: 100%;
  height: auto;
  text-align: center;
  margin: 140px 0 80px 0;
  font-size: 36px;
  font-family: Gotham;
  font-weight: bold;
  line-height: 0px;
  color: #00B7D0;
  opacity: 1;
  // div:first-child{
  //   width: 100%;
  //   height: auto;
  //   font-size: 20px;
  //   font-weight: normal;
  //   line-height: 30px;
  //   color: #00B7D0;
  //   letter-spacing: 5px;
  // }
  // div:last-child{
  //   width: 100%;
  //   height: auto;
  //   font-size: 46px;
  //   font-weight: bold;
  //   /*color: #00242A;*/
  //   margin-top: 15px;
  //   line-height: 46px;
  //   color:#00B7D0;
  // }
}
.inp{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 960px;
  margin:0 auto;
  .inp_item{
    width: 470px;
    height: 66px;
    display: flex;
    flex-flow: row nowrap;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    align-items: center;
    padding: 0 28px;
    box-sizing: border-box;
    >div:first-child{
      width: auto;
      height: auto;
      font-size: 18px;
      font-weight: 300;
      line-height: 50px;
      color: #212121;
      opacity: 1;
      position: relative;
      white-space: nowrap;
    }
    .xing{
      color: #FF6A6F;
      font-size: 24px;
      margin-top:12px;
    }
    >input,select,label{
      border: none;
      flex: 1;
      outline: 0;
      margin-left: 10px;
      font-size: 18px;
      height: 100%;
      color: #212121;
    }
    textarea{
      border: none;
      resize:none;
      flex: 1;
      outline: 0;
      margin-left: 10px;
      font-size: 24px;
      height: 100%;
      line-height: 30px;
      color: #212121;
    }
    label{
      line-height: 80px;
      cursor:pointer;
      // border:1px solid red;
    }
  }
  .inp_item+.inp_item{
    margin-left:20px;
  }
}
.text-area {
  width: 960px;
  // border: 1px solid red;
}
.text-area  .inp_item{
    width: 1200px;
    height: 226px;
    display: flex;
    flex-flow: row nowrap;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    align-items: flex-start;
    padding: 0 28px;
    box-sizing: border-box;
    padding: 25px;
    box-sizing: border-box;
}
.text-area  .inp_item > div:first-child{
  line-height: 24px;
}
.inp+.inp{
  margin-top: 32px;
}
.btn{
  width: 240px;
  height: 66px;
  line-height: 66px;
  margin:76px auto 136px auto;
  background: #00B7D0;
  border-radius: 4px;
  font-size: 26px;
  font-weight: 400;
  color: #F0F0F0;
  opacity: 1;
  text-align: center;
}
.inp_file{
  .inp_item+.inp_item{
    width: 280px;
    input{
      width: 0;
    }
  }
  #file1{
    display: none;
  }
  label{
    width: 180px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    background: #00B7D0;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>
<template>
  <div class="main">
    <navs  :active="8"></navs>
    <img src="../assets/images/exploit/开发套件-en.jpg" class="banner">
    <div class="mast_title">
<!--      <div style=" text-transform: uppercase;">application</div>-->
      <div>APPLICATION</div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>Full name</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>Telephone</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>Mail</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>Country</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>Company</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>Position</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp inp_file">
      <div class="inp_item">
        <div>Industry</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>Upload attachments</div>
        <div class="xing">*</div>
        <input type="text" id="wjm" readonly>
      </div>
      <label for="file1">SELECT FILE</label>
        <input type="file" id='file1'  style="line-height: 80px;">
    </div>
    <div class="inp">
      <div class="inp_item"  style="flex:1;">
        <div>Select the development kit</div>
        <div class="xing">*</div>
        <select>
          <option></option>
          <option> DYNAP™-CNN </option>
          <option> Speck</option>
        </select>
      </div>
    </div>
    <div class="inp text-area">
      <div class="inp_item">
        <div>Application purpose</div>
        <div class="xing" style="margin-top:4px;">*</div>
        <textarea > </textarea>
        <!-- <input type="text"> -->
      </div>
    </div>
    <div class="btn">Send</div>
    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navsen.vue"
import bases from "@/components/basesen.vue"
export default {
  components: {
    navs,
    bases
  },
}
</script>

