<template>
  <div class="home-cont">
    <header class="header" :class="{ isfixed: isfixed }">
      <div>
        <img
          class="logo"
          src="../assets/image/SynSense logo-02@2x.png"
          alt=""
        />
        <div>
          <div class="text choose">首页</div>
          <div class="text">核心技术</div>
          <div class="text">解决方案</div>
          <div class="text">产品矩阵</div>
          <div class="text">媒体中心</div>
          <div class="text">类脑社区</div>
          <div class="text">关于我们</div>
          <div class="text">商务合作</div>
          <div class="text">开发套件</div>
          <div class="circle"></div>
          <div class="text">CN</div>
          <img class="search" src="../assets/image/搜索@2x.png" alt="" />
        </div>
      </div>
    </header>
    <div class="swiper-block">
      <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions">
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/banner01@2x.png" />
        </swiper-slide>
        <swiper-slide>
          <img class="swiperimg" src="../assets/image/banner02@2x.png" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
    <div class="about-us">
      <div class="about-us-cont" ref="aboutUsCont">
        <div class="about-us-left">
          <div class="title">
            <p>About us</p>
            <p>SynSense</p>
          </div>
          <p class="text1">
            SynSense时识科技是全球领先的类脑智能与应用解决方案提供商。
          </p>
          <p class="text2">
            以苏黎世大学类脑科学为基础，聚焦边缘计算，
            提供横跨感知与计算的智能应用解决方案。
          </p>
          <img class="img1" src="../assets/image/查看更多@2x.png" alt="" />
          <img class="img2" src="../assets/image/icon01@2x.png" alt="" />
        </div>
        <div class="bg-blcok" ref="bgBlcok">
          <img src="../assets/image/价值链接世界@2x.png" alt="" />
        </div>
      </div>
    </div>
    <div class="basemap-block">
      <swiper
        style="width: 1200px;maring-auto"
        ref="mySwiper"
        :options="swiperOptions"
      >
        <swiper-slide>
          <img src="../assets/image/智能玩具@2x.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/image/智慧养殖@2x.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/image/智能家居@2x.png" />
        </swiper-slide>
        <swiper-slide>
          <img src="../assets/image/智能安防@2x.png" />
        </swiper-slide>
      </swiper>
    </div>
    <div class="stories-block">
      <div class="title">
        <p>success stories</p>
        <p>应用场景</p>
      </div>
      <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions2">
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智能玩具1-未选中@2x.png" alt="" />
            <img src="../assets/image/智能玩具1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智慧养殖1-未选中@2x.png" alt="" />
            <img src="../assets/image/智慧养殖1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智能家居1-未选中@2x.png" alt="" />
            <img src="../assets/image/智能家居1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智能安防1-未选中@2x.png" alt="" />
            <img src="../assets/image/智能安防1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <ul class="features-list">
      <li>
        <img class="basemap" src="../assets/image/30@2x.png" alt="" />
        <div class="mask">
          <p>智能视觉处理</p>
          <p>
            SynSense时识科技开发事件驱动的神经形态处理器，用于实时视觉处理。SynSense时识科技低功耗。
          </p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />
        </div>
      </li>
      <li>
        <img class="basemap" src="../assets/image/20@2x.png" alt="" />
        <div class="mask">
          <p>语音信号处理</p>
          <p>
            SynSense时识科技开发基于脉冲神经网络（SNN）的低功耗的语音识别与口令识别。
          </p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />
        </div>
      </li>
      <li>
        <img class="basemap" src="../assets/image/10@2x.png" alt="" />
        <div class="mask">
          <p>智能服务助手</p>
          <p>
            SynSense时识科技开发基于脉冲神经网络（SNN）的低功耗的语音识别与口令识别。
          </p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />
        </div>
      </li>
      <li>
        <img class="basemap" src="../assets/image/17@2x.png" alt="" />
        <div class="mask">
          <p>身体信号检测（&智能可穿戴设备）</p>
          <p>SynSense时识科技开发用于储层神经形态计算的硬件解决方案。</p>
        </div>
        <div class="search-block">
          <img class="searchIcon" src="../assets/image/搜索@2x.png" alt="" />
        </div>
      </li>
    </ul>
    <div class="btnmore">查看全部项目</div>
    <div class="news-block">
      <div class="news-block-cont">
        <div class="title">
          <p>latest mews</p>
          <p>最新资讯</p>
        </div>
        <ul class="news-list clearfix">
          <li>
            <div class="img-block">
              <img
                src="../assets/image/微信截图_20220111165000@2x.png"
                alt=""
              />
              <div></div>
            </div>
            <p>SynSense时识科技获全国颠覆性技术创新大赛优胜项目</p>
            <p>2022.1.5 09:36</p>
          </li>
          <li>
            <div class="img-block">
              <img src="../assets/image/VCG41523357070@2x.png" alt="" />
              <div></div>
            </div>
            <p>SynSense时识科技获全国颠覆性技术创新大赛优胜项目</p>
            <p>2022.1.5 09:36</p>
          </li>
          <li>
            <div class="img-block">
              <img
                src="../assets/image/微信截图_20220111164159@2x.png"
                alt=""
              />
              <div></div>
            </div>
            <p>
              2021 Great Tech Awards
              正式揭晓，SynSense时识科技获“杰出科技创新奖”
            </p>
            <p>2022.1.5 09:36</p>
          </li>
        </ul>
      </div>
    </div>
    <div class="Partner">
      <div class="title">
        <p>Partner</p>
        <p>合作伙伴</p>
      </div>
      <div class="imgs">
        <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions1">
          <swiper-slide>
            <img src="../assets/image/logo01@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo02@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo03@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo04@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo05@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo01@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
            <img src="../assets/image/logo02@2x.png" alt="" />
          </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="achievement-block">
      <div>
        <input type="text" placeholder="请输入您的邮箱" />
        <img src="../assets/image/发送@2x.png" alt="" />
      </div>
    </div>
    <img class="footer" src="../assets/image/footer@2x.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "Home",
  components: {},
  data() {
    return {
      isfixed: false,
      tablelistIndex: 0,
      swiperOptions: {
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".rightIcon",
          prevEl: ".leftIcon",
        },
        speed: 300,
        // on: {
        //   // 监听滑动切换事件，返回swiper对象
        //   slideChange: () => {
        //     const i = this.swiper.activeIndex
        //     this.changepicture(i)
        //   }
        // }
        // Some Swiper option/callback...
      },
      swiperOptions1: {
        slidesPerView: 4,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
      swiperOptions2: {
        slidesPerView: 3,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop);
    const that = this;
    window.onresize = () =>
      (() => {
        that.setBGblock();
      })();
    this.setBGblock();
  },
  methods: {
    scrollToTop() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 30) {
        this.isfixed = true;
      } else {
        this.isfixed = false;
      }
    },
    setBGblock() {
      this.$refs.bgBlcok.style.width = `${
        380 + this.$refs.aboutUsCont.getBoundingClientRect().left
      }px`;
    },
  },
};
</script>

<style lang="less" scoped>
.home-cont {
  position: relative;
}
.header {
  background-color: rgba(0, 0, 0, 0);
  color: #ffffff;
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  padding: 17px 0px;
  width: 100%;
  > div {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .logo {
    width: 210px;
    height: 57px;
  }
  .text {
    margin-right: 24px;
  }
  .choose {
    color: #0086cd;
    position: relative;
    &::after {
      content: "";
      height: 3px;
      position: absolute;
      width: 50%;
      transform: translateX(-50%);
      border-radius: 10px;
      left: 50%;
      bottom: -10px;
      background-color: #0086cd;
    }
  }
  .circle {
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background-color: #ffffff;
    margin-right: 8px;
  }
  .search {
    width: 16px;
  }
}
.isfixed {
  position: fixed;
  top: 0px;
  background-color: #000000;
}
.swiper-block {
  .swiperimg {
    width: 100%;
  }
  .swiper-pagination {
    width: 100%;
    bottom: 15%;
    /deep/.swiper-pagination-bullet {
      width: 26px;
      height: 26px;
      background-color: #ffffff;
      opacity: 1;
      margin: 0px 29px;
    }
    /deep/.swiper-pagination-bullet-active {
      background-color: #0086cd;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
        border: 4px solid #0086cd;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
      }
    }
  }
}
.title {
  text-align: center;
  p:nth-child(1) {
    color: #0086cd;
    font-size: 20px;
    margin-bottom: 5px;
  }
  p:nth-child(2) {
    color: #00242a;
    font-size: 46px;
    font-weight: bold;
    font-family: Source Han Sans CN;
  }
}
.about-us {
  width: 100%;
  background-color: #f8f8f8;
  height: 870px;
  overflow: hidden;
  .about-us-cont {
    width: 1200px;
    height: 100%;
    margin: auto;
    display: flex;
    position: relative;
    .about-us-left {
      width: 820px;
      padding-right: 190px;
      box-sizing: border-box;
      color: #17181a;
      font-size: 18px;
      .title {
        text-align: left;
        margin-top: 122px;
      }
      .text1 {
        margin-top: 38px;
      }
      .text2 {
        margin-top: 25px;
      }
      .img1 {
        width: 180px;
        margin-top: 139px;
      }
      .img2 {
        width: 477px;
        margin-top: 69px;
      }
    }
    .bg-blcok {
      position: absolute;
      width: 380px;
      height: 100%;
      left: 820px;
      background-color: rgba(0, 0, 0, 0.8);
      img {
        width: 57%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
      }
    }
  }
}
.basemap-block {
  width: 100%;
  height: 798px;
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  position: relative;
  background-image: url("../assets/image/底图@2x.png");
  box-sizing: border-box;
  padding-top: 198px;
  img {
    width: 100%;
    height: 422px;
  }
}
.stories-block {
  width: 1200px;
  height: 771px;
  margin: auto;
  .title {
    margin-top: 201px;
    margin-bottom: 105px;
  }
  .stories-img-block {
    width: 390px;
    cursor: pointer;
    img {
      width: 390px;
    }
    img:nth-child(1) {
      display: block;
    }
    img:nth-child(2) {
      display: none;
    }
    &:hover {
      img:nth-child(1) {
        display: none;
      }
      img:nth-child(2) {
        display: block;
      }
    }
  }
}
.features-list {
  display: flex;
  li {
    flex: 1;
    position: relative;
    .basemap {
      width: 100%;
    }
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      background-color: rgba(0, 0, 0, 0.5);
      left: 0px;
      top: 0px;
      color: #ffffff;
      opacity: 1;
      transition: all 1s;
      p {
        margin: auto;
      }
      p:nth-child(1) {
        width: 292px;
        max-width: 100%;
        height: 84px;
        text-align: center;
        font-size: 30px;
        margin-top: 207px;
      }
      p:nth-child(2) {
        width: 354px;
        max-width: 90%;
        text-align: center;
        font-size: 20px;
        margin-top: 57px;
      }
    }
    .search-block {
      width: 156px;
      height: 156px;
      border-radius: 100%;
      background-color: #0086cd;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      opacity: 0;
      img {
        width: 50px;
      }
    }
    &:hover {
      .mask {
        opacity: 0;
      }
      .search-block {
        transition: all 1s;
        opacity: 1;
      }
    }
  }
}
.btnmore {
  width: 208px;
  height: 54px;
  border: 2px solid #0086cd;
  border-radius: 27px;
  font-size: 22px;
  font-family: PingFang SC;
  line-height: 54px;
  text-align: center;
  color: #0086cd;
  margin: 71px auto;
}
.news-block {
  width: 100%;
  height: 750px;
  margin: auto;
  margin-top: 39px;
  overflow: hidden;
  .news-block-cont {
    width: 1200px;
    margin: auto;
    .title {
      text-align: left;
    }
    .news-list {
      display: flex;
      position: relative;
      left: 0px;
      margin-top: 92px;
      li {
        width: 482px;
        height: 400px;
        margin-right: 22px;
        background-color: red;
        flex-shrink: 0;
        border-radius: 10px;
        background-color: #f8f8f8;
        overflow: hidden;
        .img-block {
          position: relative;
          width: 100%;
          height: 272px;
          img {
            width: 100%;
            height: 272px;
          }
          div {
            position: absolute;
            left: 0px;
            top: 0px;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            opacity: 1;
          }
        }
        &:hover {
          cursor: pointer;
          background-color: #ffffff;
          box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
          .img-block {
            div {
              opacity: 0;
            }
          }
        }
        p {
          padding: 0px 20px;
        }
        p:nth-child(2) {
          color: #1d1d1f;
          margin-top: 25px;
          word-break: keep-all; /* 不换行 */
          white-space: nowrap; /* 不换行 */
          overflow: hidden;
          text-overflow: ellipsis;
        }
        p:nth-child(3) {
          color: #6e6e73;
          font-size: 12px;
          margin-top: 42px;
        }
      }
    }
  }
}
.Partner {
  width: 100%;
  height: 558px;
  background: linear-gradient(135deg, #ffffff 0%, #f8f8f8 100%);
  padding-top: 140px;
  box-sizing: border-box;
  .imgs {
    width: 1200px;
    margin: auto;
    margin-top: 116px;
    img {
      width: 300px;
      margin-left: 46px;
    }
  }
}
.achievement-block {
  width: 1200px;
  height: 330px;
  margin: 127px auto;
  background-image: url("../assets/image/订阅-底@2x.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 202px;
  box-sizing: border-box;
  > div {
    width: 730px;
    height: 62px;
    padding: 4px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    margin: 0px auto;
    border-radius: 8px;
    input {
      flex: 1;
      height: 54px;
      line-height: 54px;
      padding: 0px 23px;
      border: none;
    }
    img {
      width: 150px;
      height: 54px;
    }
  }
}
.footer {
  width: 100%;
}
</style>
