<style scoped lang="less">
.main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.banner{
  width: 100%;
  height: auto;
  margin-top: -75px;
}
.mast_title{
  width: 100%;
  height: auto;
  text-align: center;
  margin: 135px 0 80px 0;
  div{
    width: 100%;
    margin-top: 15px;
    height: 36px;
    font-size: 36px;
    font-weight: bold;
    line-height: 36px;
    color: #00B7D0;
    opacity: 1;
  }
}
.inp{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 960px;
  margin:0 auto;
  .inp_item{
    width: 470px;
    height: 66px;
    display: flex;
    flex-flow: row nowrap;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    align-items: center;
    padding: 0 28px;
    box-sizing: border-box;
    >div:first-child{
      width: auto;
      height: auto;
      font-size: 18px;
      font-weight: 300;
      line-height: 50px;
      color: #212121;
      opacity: 1;
      position: relative;
      white-space: nowrap;
    }
    .xing{
      color: #FF6A6F;
      font-size: 18px;
      margin-top: 6px;
    }
    >input,label{
      border: none;
      flex: 1;
      outline: 0;
      margin-left: 10px;
      font-size: 18px;
      height: 100%;
      color: #212121;
    }
    label{
      line-height: 66px;
      cursor:pointer;
      // border:1px solid red;
    }
  }
  .inp_item+.inp_item{
    margin-left:20px;
  }
}
.inp+.inp{
  margin-top: 20px;
}
.btn{
  width: 240px;
  height: 66px;
  line-height: 66px;
  margin:76px auto 136px auto;
  background: #00B7D0;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 400;
  color: #F0F0F0;
  opacity: 1;
  text-align: center;
}

.inp_file{
  .inp_item{
    width: 280px;
    input{
      width: 0;
    }
  }
  #file1{
    display: none;
  }
  label{
    width: 180px;
    height: 66px;
    line-height: 66px;
    text-align: center;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 300;
    margin-left: 10px;
    background: #00B7D0;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    cursor: pointer;
  }
}
</style>
<template>
  <div class="main">
    <navs :active='7'></navs>
    <img src="../assets/images/coop/商务合作.jpg" class="banner">
    <div class="mast_title">
      <div>商务合作</div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>姓名</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>电话</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>邮箱</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>国家</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>公司</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>职位</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp">
      <div class="inp_item">
        <div>所属行业</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
      <div class="inp_item">
        <div>合作意向方向</div>
        <div class="xing">*</div>
        <input type="text">
      </div>
    </div>
    <div class="inp inp_file">
      <div class="inp_item">
        <div>上传附件</div>
        <div class="xing">*</div>
        <input type="text" id="wjm" readonly>
      </div>

      <label for="file1">选择文件</label>
      <input type="file" id='file1'  style="line-height: 80px;">
      <!-- <input type="file" id='file1'  style="line-height: 80px;" onchange="document.getElementById('wjm').value=this.files[0].name"> -->
    </div>
    <div class="btn">发送</div>
    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navs.vue"
import bases from "@/components/bases.vue"
export default {
  components: {
    navs,
    bases
  },
}
</script>

