<style scoped lang="less">

.main{
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.t_b {
  width:1920px;
  height:auto;
}
.dx_2 {
    animation: dx 2s linear;
}
.opc_3 {
    animation: opc 3s linear;
}

@keyframes dx {
    0% {
        transform: scale(1.5);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes opc {
    0% {
        opacity: 0;
    }
    60%{
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}


  .marbtn-136{
    margin-bottom: 130px;
  }

.title{
  text-align: center;
  /*margin: 94px 80px;*/
  height: 30px;
  font-size: 30px;
  font-weight: bold;
  color: #00242A;
  opacity: 1;
  p:nth-child(1){
    /*color: #00B7D0;*/
    /*font-size: 20px;*/
    /*margin-bottom: 5px;*/
    /*color:#00B7D0;*/
    height: 36px;
    font-size: 36px;
    font-weight: bold;
    line-height: 0px;
    color: #00B7D0;
    opacity: 1;
  }
  p:nth-child(2){
    color: #00242A;
    font-size: 40px;
    font-weight: bold;
  }
}

@keyframes lrs {
  0%{
    left: -120%;
  }
  100%{
    left: -10%;
  }
}
@keyframes lrs2 {
  0%{
    left: 100%;
  }
  100%{
    left: -10%;
  }
}
@keyframes color1 {
  0%{
    color: #0086CD;
  }
  100%{
    color: #FFFFFF;
  }
}



.yyly3{
  width: 100%;
  height: auto;
  text-align: center;
  position: relative;
  margin-top: 30px;
  font-size: 32px;
  font-weight: 300;
  line-height: 68px;
  color: #00242A;
}

.stories-block-para{
  width: 100%;
  height: auto;
  // border: 1px solid red;
  padding-top: 94px;
  // overflow: hidden;
  // /* background-attachment: fixed; */
  // background-size:auto 114%;
  // background-repeat: no-repeat;
  // background-position: 700% -70px;
  // background-image: url('../assets/image/tiny speck@2x.png');
}

.swiper-block {
  position: relative;
  .banner-center {
    width: 1200px;
    height: 267px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -600px;
    margin-top: -133.5px;
    z-index: 10;
  }
  .swiperimg {
    width: 100%;
  }
  .swiper-pagination {
    width: 100%;
    bottom: 15%;
    /deep/.swiper-pagination-bullet {
      width: 26px;
      height: 26px;
      background-color: #ffffff;
      opacity: 1;
      margin: 0px 29px;
    }
    /deep/.swiper-pagination-bullet-active {
      background-color: #0086cd;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        width: 40px;
        height: 40px;
        border-radius: 100%;
        background-color: rgba(0, 0, 0, 0);
        box-sizing: border-box;
        border: 4px solid #0086cd;
        transform: translateX(-50%) translateY(-50%);
        left: 50%;
        top: 50%;
      }
    }
  }
}
.item-row1{
  width: 960px;
  margin: 0px auto;
  margin-top: 80px;
  .item{
    height: 430px;
    display: flex;
    justify-content:space-between;
    margin-bottom: 30px;
    // border: 1px solid red;
    .item-child{
      .bot-area {
        width: 100%;
        height: 94px;
        text-align: center;
        line-height: 94px;
        font-size: 24px;
        font-weight: bold;
        color: rgb(0, 36, 42);
        opacity: 1;
      }
      width: 49%;
      position: relative;
      img{
        width: 100%;
        height: 100%;
      }
      .item-child-text-center{
        position: absolute;
        top: 90px;
        left: 0;
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 36px;
        color: #00242A;
        opacity: 1;
      }
      .item-child-text-left{
        position: absolute;
        top: 63px;
        left: 0;
        width: 100%;
        padding-left: 61px;
        box-sizing: border-box;
        text-align: left;
        font-size: 24px;
        font-weight: bold;
        line-height: 36px;
        color: #00242A;
        opacity: 1;
      }
      p {
        padding: 36px 41px 60px;
        font-size: 14px;
        font-weight: 300;
        line-height: 32px;
        color: #FFFFFF;
        opacity: 1;
      }
    }
    .text-child{
      padding-top: 127px;
      padding-left: 60px;
      padding-right: 71px;
      box-sizing: border-box;
      text-align: center;
      p:first-child{
        margin: 0;
        padding:0 0 40px 0;
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        color: #00242A;
        opacity: 1;
       }
      p:last-child{
        padding: 0;
        font-size: 18px;
        font-weight: 300;
        line-height: 38px;
        color: #003F52;
        opacity: 1;
      }
    }
  }
}

.item-row2{
  width: 960px;
  margin: 0px auto;
  margin-top: 80px;
  flex-wrap: wrap;
    display: flex;
    justify-content:space-between;

  .item2{
    width: 49%;
    height: 430px;
    background: #F4F4F4;
    box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.16);
    opacity: 1;
    border-radius: 20px;
    margin-bottom: 30px;
    div:first-child>img{
      width: 470px;
      height: auto;
      opacity: 1;
      border-radius: 16px 16px 0px 0px;
    }
    .title{
      font-size: 24px;
      font-weight: bold;
      line-height: 36px;
      color: #00242A;
      opacity: 1;
      margin-top: 35px;
    }
    .bottoms{
      display: flex;
      justify-content:space-between;
      padding: 21px 34px;
      box-sizing: border-box;
      background:white;
      /*margin-top:24px;*/
      border-radius: 0 0 18px 18px;
      span{
        display: inline-block;
        width: 180px;
        height: 54px;
        border: 2px solid #00B7D0;
        opacity: 0.8;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 500;
        color: #00B7D0;
        opacity: 1;
        text-align: center;
        line-height: 54px;
        }
      span:hover {
          cursor:pointer;
      }
    }
  }
}
.yyly4{

  margin-top: 100px;
  text-align: center;
  .mix {
    width: 960px;
    margin: 0px auto;
    margin-top:80px;
    height:466px;
    background: linear-gradient(135deg, #FFFFFF 0%, #F4F4F4 100%);
    opacity: 1;
    border-radius: 20px;
    display:flex;
    flex-flow: row;
    justify-content: space-between;
    box-sizing: border-box;
    .text {
      box-sizing: border-box;
      width: 50%;
      padding:72px 57px;
      font-size: 14px;
      font-weight: 300;
      line-height: 38px;
      color: #003F52;
      opacity: 1;
    }
    .img {
      width:50%;
      height:auto;
      img {
        width:480px;
      }
    }
  }
  .btn {
    width: 270px;
    height: 66px;
    margin: 0 auto;
    margin-bottom: 140px;
    margin-top: 140px;
    background: #00B7D0;
    opacity: 1;
    border-radius: 0px;
    font-size: 24px;
    font-weight: bold;
    line-height: 66px;
    color: #FFFFFF;
    opacity: 1;
    box-shadow: 4px 4px 0 #003F52;
  }

  .btn:hover {
    cursor: pointer;
  }
  .title{
      font-size: 30px;
      height: 30px;
      line-height: 30px;
      font-weight: bold;
      color: #00242A;
      opacity: 1;
      margin-bottom: 50px;
    text-transform: uppercase;
  }
  .desc{
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 63px;
    color: #00242A;
    opacity: 1;
  }
  .help{
    font-size: 19px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 63px;
    color: #00242A;
    opacity: 1;
  }
  .img2-5{
    margin: 100px 0px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
.item-row3{
  width: 100%;
  margin: 0px auto;
  /*padding: 0px 70px;*/
  box-sizing: border-box;
  .table-row{
    width: 100%;
    .th1{
      display: flex;
      justify-content: space-between;
      div{
        width: 30%;
        text-align: center;
        border-bottom: 2px solid #D2D2D7;
        padding-bottom: 50px;
        box-sizing: border-box;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        line-height: 0px;
        color: #00242A;
        opacity: 1;
      }
    }
  }
}
.item-row4{
  box-sizing: border-box;
  width: 100%;
  background: #00B7D0;
  opacity: 1;
  margin: 100px auto;
  .condition {
    padding-top: 140px;
    margin: 0 auto;
    height: 30px;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
    color: #FFFFFF;
    opacity: 1;
  }
  .items {
    box-sizing: border-box;
    margin: 0 auto;
    padding-top: 80px;
    padding-bottom: 50px;
    width:756px;

    height: 24px;
    font-size: 24px;
    font-weight: bold;
    line-height: 24px;

    display:flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    color: #FFFFFF;
    opacity: 1;
  }

  .hori_line_container {
    margin: 0 auto;
    box-sizing: border-box;
    width:960px;
    display:flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    .hori_line {
      background: #FFFFFF;
      width: 300px;
      height: 1px;
    }
  }

  .middle_content {
    width: 960px;
    height:504px;
    margin: 0 auto;
    display: flex;
    flex-flow:row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    .hard_demand {
      box-sizing: border-box;
      display: flex;
      flex-flow:column;
      justify-content: space-between;
      align-items: center;
      width:300px;
      height:100%;
      padding: 50px 0;
      .icon_text_container {
        height:70px;
        font-size: 14px;
        font-weight: 300;
        color: #FFFFFF;
        opacity: 1;
        display: flex;
        flex-flow: column;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 40px;
          height: 40px;
        }
        .text {
          line-height:14px;
          height:14px;
        }
      }
    }
    .soft_demand {
      height:49px;
      width:300px;
      margin-top:58px;
      display:flex;
      flex-flow: column;
      justify-content: space-between;
      align-items: center;
      color: #FFFFFF;

      div:nth-child(1){
        height: 21px;
        font-size: 20px;
        font-weight: normal;
        line-height: 21px;
        text-transform: uppercase;
      }
      div:nth-child(2) {
        height: 15px;
        font-size: 14px;
        font-weight: normal;
        line-height: 15px;
      }
    }

    .down_area {
      width:300px;
      display:flex;
      flex-flow:column;
      justify-content: flex-start;

      .down_content {
        display: flex;
        flex-flow:column;
        width:100%;
        margin-top:58px;
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF;
        opacity: 1;
        .txt1 {
          height: 21px;
          font-size: 20px;
          font-weight: normal;
          line-height: 21px;
          text-transform: uppercase;
        }
        .txt2 {
          height: 14px;
          font-size: 14px;
          font-weight: 300;
          line-height: 14px;
          margin-top:15px;
        }
        .down_btn {
          margin-top:13px;
          width:58px;
          height:24px;
          background: #003F52;
          opacity: 1;
          border-radius: 4px;
          .btn_name {
            height: 24px;
            font-size: 14px;
            font-weight: 300;
            line-height: 24px;
            color: #FFFFFF;
          }
        }
        .down_btn:hover {
          cursor: pointer;
        }
      }
      .down_content:nth-child(2) {
        margin-top:62px;
      }
    }

  }





  /*div:first-child{*/
  /*  background: linear-gradient(135deg, #FFFFFF 0%, #F4F4F4 100%);*/
  /*  opacity: 1;*/
  /*  border-radius: 10px;*/
  /*  position: relative;*/
  /*  .title4{*/
  /*    position: absolute;*/
  /*    top:220px;*/
  /*    left: 79px;*/
  /*    font-size: 36px;*/
  /*    font-weight: bold;*/
  /*    line-height: 36px;*/
  /*    color: #00242A;*/
  /*    opacity: 1;*/
  /*  }*/
  /*}*/
  .item4-child{
    width: 100%;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    div{
      width: 49%;
    }
  }
}

.bg-linear{
  background: linear-gradient(135deg, #FFFFFF 0%, #F4F4F4 100%);
  border-radius: 20px;
}

.tab {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
}
  .tab .item {
    display:block;
    width:480px;
    text-align:center;
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: 300;
    line-height: 0px;
    color: #848484;
    opacity: 1;
  }

  .tab .active {
    width:480px;
    height: 32px;
    font-size: 30px;
    font-weight: bold;
    line-height: 0px;
    color: #00242A;
    opacity: 1;
  }


  .tab .item .bot{
    display:none;
    /*visibility: hidden;*/
    height: 32px;
    font-size: 32px;
    font-weight: bold;
    line-height: 0px;
    color: #00242A;
    opacity: 1;
  }

  .tab .active .bot {
    display:block;
    width: 46px;
    height: 12px;
    background: #F37442;
    opacity: 1;
    border-radius: 10px;
    margin-left:214px;
    margin-top:-20px;
  }

  .tab .item:hover {
    cursor:pointer;
  }

#block1 {
  background: url(../assets/images/goods/01@2xch.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  div {
    background: #003F52;
    opacity: 0;
    border-radius: 16px;
    color:white;
    padding: 70px;
    font-size: 18px;
    font-weight: 300;
    line-height: 36px;
  }
  div:hover {
    opacity: 0.8;
  }
}
#block2 {
  background: url(../assets/images/goods/02@.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width:100%;
  margin-right: 1%;
  div {
    box-sizing: border-box;
    background: #003F52;
    opacity: 0;
    border-radius: 16px;
    color:white;
    padding: 33px;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
  div:hover {
    opacity: 0.8;
  }
}

#block3 {
  background: url(../assets/images/goods/03@2x.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width:100%;
  margin-left:1%;
  div {
    box-sizing: border-box;
    background: #003F52;
    opacity: 0;
    border-radius: 16px;
    color:white;
    padding: 33px;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
  div:hover {
    opacity: 0.8;
  }
}
#block4 {
  background: url(../assets/images/goods/04@2x.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width:100%;
  margin-right:1%;
  div {
    box-sizing: border-box;
    background: #003F52;
    opacity: 0;
    border-radius: 16px;
    color:white;
    padding: 33px;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
  div:hover {
    opacity: 0.8;
  }
}
#block5 {
  background: url(../assets/images/goods/05@2x.png);
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
  width:100%;
  margin-left: 1%;
  div {
    box-sizing: border-box;
    background: #003F52;
    opacity: 0;
    border-radius: 16px;
    color:white;
    padding: 33px;
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
  div:hover {
    opacity: 0.8;
  }
}
</style>
<template>
  <div class="main">
    <navs :active='3'></navs>
    <img style="width:100%;" src="../assets/images/goods/产品矩阵.jpg" />
    <div class="stories-block-para">
      <div class="stories-block">
        <div class="title">
          <p>硬件设计</p>
        </div>
        <div class="item-row1">
          <div class='item bg-linear' id="block1">
            <div>
              Speck是全球首款“感算一体”动态视觉智能SoC。实现了基于异步逻辑范式的大规模脉冲卷积神经网络（SCNN）芯片架构。芯片最多可配置高达32万脉冲神经元并且芯片内部集成了动态视觉传感器（DVS）为其提供实时高效的动态视觉输入。Speck创举性的集动态视觉传感和事件驱动运算于一体， 提供高度实时、集成和低功耗的动态视觉解决方案，在传统应用场景下，Speck可在mW级的超低功耗、mS级的实时响应下，完成智能场景分析。可以广泛应用于移动设备、物联网（IOT）、智能家电、智能安防、智慧养殖等领域。
            </div>
          </div>


          <div class='item'>
            <div class='item-child bg-linear' id="block2">
              <div class='item bg-linea'>
                DYNAP-CNN是全球首颗动态视觉专用处理器，是可扩展的、可完全配置、事件驱动的神经形态处理器。每个芯片具有100万个ReLU脉冲神经元，用于实现脉冲卷积神经网络（SCNN）。这项技术非常适合永远在线、低功耗、低延迟、事件驱动的传感器信息处理。借助用于动态视觉传感器的专用接口，它可以直连世界上大多数动态视觉传感器，从而实现模型的无缝集成和原型的快速制作。 DYNAP-CNN是完全可配置的，并支持各种类型的CNN层（如ReLU，Cropping，Padding和Pooling）和网络模型（如LeNet，ResNet和Inception）。它具有广泛可编程性，可提供对模型的完全控制。 此外，DYNAP-CNN具有可扩展性，可在多个互连的DYNAP-CNN上实现无限制层数的深度神经网络的实现。
              </div>
            </div>

            <div class='item-child bg-linear' id="block3">
              <div class='item bg-linea'>
                XYLO是世界领先的低维度类脑处理器，基于第三代人工神经网络的、超低功耗的、always-on低维度信号专用AI处理器。芯片内部集成了模拟前端预处理，可对低维度自然（模拟）信号直接进行进行预处理；内建神经网络，支持脉冲神经网络（SNN）和储存池计算技术等。特别适合各种mems麦克风、温度传感器、压力传感器、振动传感器、加速度传感器、陀螺仪及肌电、心电等信号的智能处理。
              </div>
            </div>

          </div>
          <div class='item'>
            <div class='item-child bg-linear' id="block4">
              <div class='item bg-linea'>SynSense时识科技新一代DYNAP-SE2系列神经形态芯片将其前几代产品无可挑剔的能效与低延迟、实时端到端响应的新功能结合。集成的生物信号放大器使DYNAP-SE2非常适合远程医疗与可穿戴设备应用。单芯片包含1024个自适应的指数积分和发射模拟信号的低功耗脉冲神经元，以及超过65,000个增强突触，具有可配置的延迟，重量和短期可塑性。创新的异步低延迟通信基础结构（已获得专利）使每个神经元都可以通过中继神经元与周围多达230,000个神经元进行通信，并具有无限扩展性，可实现真正的大规模网络。</div>
            </div>

            <div class='item-child bg-linear' id="block5">
              <div class='item bg-linea'>全新一代神经形态芯片DYNAP-SEL具有1,000个模拟低功率脉冲神经元和多达80,000个可配置的突触连接，包括8,000个集成脉冲学习规则的突触。DYNAP-SEL的扇入和扇出功能可与在哺乳动物皮质中观察到的连接架构相媲美，并允许使用DYNAP-SEL模拟具有生物学现实连接性的网络。SynSense时识科技的技术可以在DYNAP-SEL上以极低的功耗实时实现带有片上学习和大型扇入、扇出网络连接的脉冲神经网络。</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="stories-block-para">
      <div class="stories-block">
        <div class="title">
          <p>软件支持</p>
        </div>
        <div class="item-row2">
            <div class="item2">
              <div>
                <img class="swiperimg" src="../assets/images/goods/st01@2x.png" />
              </div>
<!--              <div class='title'>SANMA</div>-->
              <div  class='bottoms'>
                <span @click="goToLink('https://synsense-sys-int.gitlab.io/samna/')">
                  Documentation
                </span>
                <span @click="goToLink('https://synsense-sys-int.gitlab.io/samna/')">
                  Repository
                </span>
              </div>
            </div>
            <div class="item2">  <div>
                <img class="swiperimg" src="../assets/images/goods/st02@2x.png" />
              </div>
<!--              <div class='title'>ROCKPOOL</div>-->
              <div  class='bottoms'>
                <span @click="goToLink('https://synsense.gitlab.io/rockpool/')">
                  Documentation
                </span>
                <span @click="goToLink('https://gitlab.com/synsense/rockpool')">
                  Repository
                </span>
              </div></div>
            <div class="item2">  <div>
                <img class="swiperimg" src="../assets/images/goods/st03@2x.png" />
              </div>
<!--              <div class='title'>SINABS</div>-->
              <div  class='bottoms'>
                <span @click="goToLink('https://synsense.gitlab.io/sinabs/')">
                  Documentation
                </span>
                <span @click="goToLink('https://gitlab.com/synsense/sinabs')">
                  Repository
                </span>
              </div></div>
            <div class="item2">
              <div>
                <img class="swiperimg" src="../assets/images/goods/st04@2x.png" />
              </div>
<!--              <div class='title'>TONIC</div>-->

              <div  class='bottoms'>
                <span @click="goToLink('https://tonic.readthedocs.io/en/latest/about/release_notes.html')">
                  Documentation
                </span>
                <span @click="goToLink('https://github.com/synsense/tonic')">
                  Repository
                </span>
              </div>
            </div>
        </div>
      </div>

    </div>
    <div class="stories-block-para">
      <div class="tab">
        <div :class=c1 @click="goToCont1">
          <div>
            <div style="height:60px">
              DYNAP™-CNN开发板
            </div>
            <div class="bot">
            </div>
          </div>
        </div>
        <div :class=c2 @click="goToCont2">
          <div>
            <div style="height:60px;">
              SPECK TINY开发板
            </div>
            <div class="bot"></div>
          </div>


        </div>
      </div>

        <div class="yyly4">
          <div ref="content1" :style="{display: showContent1? 'block': 'none'}">
            <p class="title">DYNAP™-CNN开发板</p>
            <div class="mix"  >
              <div class="text">
                DYNAP™-CNN开发套件利用时识科技DYNAP™-CNN芯片的强大能力，提供毫瓦级可编程卷积视频处理能力。该套件可以在毫瓦级功耗内完成实时面部检测、 实时手势识别、实时目标分类等视觉任务。
                <br>
                DYNAP™-CNN开发套件支持直连或通过PC连接DVS相机。利用时识科技开源Python库SINABS轻松搭建高达9层的卷积神经网络， 并且最少仅需一行代码即可在DYNAP™CNN开发套件上完成网络部署。
              </div>
              <div class="img">
                <img src="../assets/images/goods/mix.png">
              </div>
            </div>
<!--            <div class="item-row4">-->
<!--              <img class="swiperimg" src="../assets/images/goods/3-6.png" style="width:100%;"/>-->
<!--            </div>-->
            <div class="item-row4" style="height:1083px;">
              <div class="condition">使用条件</div>
              <div class="items">
                <span>硬件要求</span>
                <span>软件要求</span>
                <span>安装软件</span>
              </div>
              <div class="hori_line_container">
                <div class="hori_line"></div>
                <div class="hori_line"></div>
                <div class="hori_line"></div>
              </div>
              <div class="middle_content" style="height:617px;">
                <div class="hard_demand">
                  <div class="icon_text_container">
                    <img class="icon" src="../assets/images/goods/icon-内存.png">
                    <span class="text">至少 4GB 物理内存</span>
                  </div>
                  <div class="icon_text_container">
                    <img class="icon" src="../assets/images/goods/icon-USB.png">
                    <span class="text">1个USB3.0  Type-A接口</span>
                  </div>
                  <div class="icon_text_container">
                    <img class="icon" src="../assets/images/goods/icon-显示器.png">
                    <span class="text">一个显示器</span>
                  </div>
                  <div class="icon_text_container">
                    <img class="icon" src="../assets/images/goods/icon-01.png">
                    <span class="text">拥有集成或独立显卡</span>
                  </div>
                  <div>
                    <div style="height: 21px;
                                font-size: 20px;
                                font-weight: normal;
                                color:white;
                                line-height: 21px;"
                    >Inivation DVXplorer Lite</div>
                    <div style="font-size: 14px;
                                font-weight: 300;
                                line-height: 32px;
                                color: #FFFFFF;
                      ">当前支持的DVS相机型号（可选）</div>
                  </div>
                </div>
                <div class="soft_demand_container">
                  <div class="soft_demand">
                    <div>Ubuntu</div>
                    <div>Ubuntu 18.04/20.04</div>
                  </div>
                  <div class="soft_demand" style="margin-top:63px;">
                    <div>arch</div>
                    <div>Arch</div>
                  </div>
                  <div class="soft_demand" style="margin-top:66px;">
                    <div>mac</div>
                    <div>Mac >= 10.15</div>
                  </div>
                  <div class="soft_demand" style="margin-top:70px;">
                    <div>python</div>
                    <div>Python >= 3.5</div>
                  </div>
                </div>

                <div class="down_area">
                  <div class="down_content">
                    <div class="txt1">
                      samna
                    </div>
                    <div class="txt2">
                      开发套件驱动
                    </div>
                  </div>
                  <div class="down_content" style="margin-top:40px;">
                    <div class="txt1">
                      Sinabs
                    </div>
<!--                    <div class="txt1" style="margin-top:8px;">-->
<!--                      sinabs-->
<!--                      sinabs-dynapcnn-->
<!--                    </div>-->
                    <div class="txt2">
                      安装脉冲卷积神经网络开发工具
                    </div>
<!--                    <div class="down_btn" @click="goToLink('https://sinabs.ai/index.html')">-->
<!--                      <div class="btn_name">下载</div>-->
<!--                    </div>-->
                  </div>
                  <div class="down_content" style="margin-top:52px;">
                    <div class="txt1">
                      aermanager
                    </div>
                    <div class="txt2">
                      AER数据处理工具
                    </div>
                  </div>
                </div>
              </div>
              <div class="hori_line_container">
                <div class="hori_line"></div>
                <div class="hori_line"></div>
                <div class="hori_line"></div>
              </div>

            </div>
            <div class="btn" @click="goToLink('/dev-kit')">
              申请套件
            </div>
          </div>
      </div>
      <div class="yyly4">
        <div ref="content2" :style="{display: showContent2? 'block': 'none'}">
          <p class="title">Speck tiny开发板</p>
          <div class="mix"  >
            <div class="text" style="padding-top:141px;">
              Speck Tiny 开发板搭载时识科技先进的Speck芯片，在U盘大小的空间内能够实现实时监测、手势识别、物体分类等任务，并将卷积图像处理能力的功耗降到了毫瓦级。利用时识科技开源库Sinabs和工具链Samna可以轻松搭建高达9层的卷积神经网络并使用片上DVS传感器。
            </div>
            <div class="img">
              <img src="../assets/images/goods/mix2.png">
            </div>
          </div>
          <div class="item-row4" style="height:970px;">
            <div class="condition">使用条件</div>
            <div class="items">
              <span>硬件要求</span>
              <span>软件要求</span>
              <span>安装软件</span>
            </div>
            <div class="hori_line_container">
              <div class="hori_line"></div>
              <div class="hori_line"></div>
              <div class="hori_line"></div>
            </div>
            <div class="middle_content">
              <div class="hard_demand">
                <div class="icon_text_container">
                  <img class="icon" src="../assets/images/goods/icon-内存.png">
                  <span class="text">至少 4GB 物理内存</span>
                </div>
                <div class="icon_text_container">
                  <img class="icon" src="../assets/images/goods/icon-USB.png">
                  <span class="text">1个USB3.0  Type-A接口</span>
                </div>
                <div class="icon_text_container">
                  <img class="icon" src="../assets/images/goods/icon-显示器.png">
                  <span class="text">一个显示器</span>
                </div>
                <div class="icon_text_container">
                  <img class="icon" src="../assets/images/goods/icon-01.png">
                  <span class="text">拥有集成或独立显卡</span>
                </div>
              </div>
              <div class="soft_demand">
                <div>Ubuntu</div>
                <div>Ubuntu 18.04/20.04</div>
              </div>
              <div class="down_area">
                <div class="down_content">
                  <div class="txt1">
                    samna
                  </div>
                  <div class="txt2">
                    开发套件驱动
                  </div>
                  <div class="down_btn" @click="goToLink('https://synsense-sys-int.gitlab.io/samna/')">
                    <div class="btn_name">下载</div>
                  </div>
                </div>
                <div class="down_content">
                  <div class="txt1">
                    Sinabs
                  </div>
                  <div class="txt2">
                    脉冲卷积神经网络开发工具
                  </div>
                  <div class="down_btn" @click="goToLink('https://sinabs.ai/index.html')">
                    <div class="btn_name">下载</div>
                  </div>
                </div>

              </div>
            </div>
            <div class="hori_line_container">
              <div class="hori_line"></div>
              <div class="hori_line"></div>
              <div class="hori_line"></div>
            </div>

          </div>

          <div class="btn" @click="goToLink('/dev-kit')">
            申请套件
          </div>
        </div>
      </div>


    </div>
<!--    <div class="stories-block-para">-->
<!--      -->
<!--    </div>-->
<!--    <div class="stories-block-para">-->
<!--      -->
<!--    </div>-->

    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navs.vue"
import bases from "@/components/bases.vue"
export default {
  data() {
    return {
      swiperOptions: {
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".rightIcon",
          prevEl: ".leftIcon",
        },
        speed: 300,
        // on: {
        //   // 监听滑动切换事件，返回swiper对象
        //   slideChange: () => {
        //     const i = this.swiper.activeIndex
        //     this.changepicture(i)
        //   }
        // }
        // Some Swiper option/callback...
      },
      showContent1:true,
      showContent2:false,
      c1:['item', 'active'],
      c2:['item'],
    }
  },
  methods: {
    goToLink(value) {
      window.open(value);
    },
    goToCont1() {
      this.c1 = ['item', 'active']
      this.c2 = ['item']
      this.showContent1 = true;
      this.showContent2 = false;
    },
    goToCont2() {
      this.c1 = ['item'];
      this.c2 = ['item', 'active'];
      this.showContent1=false;
      this.showContent2=true;
    },
  },
  components: {
    navs,
    bases
  },
}
</script>

