<style lang="less" scoped>
  .home-cont {
    position: relative;
    width: 100%;
    overflow-x:hidden;
    .hmap {
      width: 100%;
      /*height: 870px;*/
      padding-bottom:140px;
      background: linear-gradient(180deg, #FFFFFF 0%, #F4F4F4 100%);
      opacity: 1;
      border-radius: 0px;
      p {
        text-align: center;
        padding-bottom:80px;
        height: 36px;
        font-size: 36px;
        font-weight: bold;
        line-height: 36px;
        color: #00B7D0;
        opacity: 1;
      }
    }
    .text1 {
      text-align: center;
      width: 960px;
      margin: 0 auto;
      padding: 136px 0;
      background-color: #fff;
      > p:nth-child(1) {
        font-weight: bold;
        color: #00242a;
        font-size: 46px;
      }
      > p:nth-child(2) {
        font-size: 14px;
        font-weight: 300;
        color: #00242a;
        letter-spacing: 1.5em;
        opacity: 0.4;
        margin-top: 20px;
      }
      > p:nth-child(3) {
        margin-top: 60px;
        font-size: 18px;
        font-weight: 300;
        line-height: 38px;
        color: #00242a;
      }
    }
    .ban {
      width: 960px;
      height: 540px;
      margin: 0 auto;
      margin-bottom: 136px;
    }
    .ckgd{
      display:inline-block;text-align:center;height:35px;line-height:35px;width:106px;background: #00B7D0;font-size: 16px;
      font-weight: 300;
      border-radius: 4px;margin-left:85px;
      color: #FFFFFF;
    }
    .head {
      margin-bottom: 80px;
      text-align: center;
      text-transform: uppercase;
      > p:first-child {
        font-size: 36px;
        font-weight: bold;
        color: #00b7d0;
        /*letter-spacing: 10px;*/
        position: relative;
        left: 3px;
      }
      > p:nth-child(2) {
        font-size: 46px;
        font-weight: bold;
        color: #00242a;
      }
    }
    .cooperation {
      width: 100%;
      height: auto;
      background-color: white;
      padding: 136px 0;
      text-align: center;
      /*margin-bottom: 138px;*/

      .items {
        display: flex;
        width: 1200px;
        margin: 0 auto;
        justify-content: space-between;

        > div {
          display:inline-block;
          width: 390px;
          height: 130px;
          border-radius: 8px;
          overflow: hidden;
          font-size: 18px;
          font-weight: 300;
          color: #8f8f8f;
          padding: 40px 24px;
          box-sizing: border-box;
          text-align: left;
          border-top: 10px solid #00b7d0;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
          .blue {
            width: 100%;
            height: 10px;
            background: #00b7d0;
          }
          img {
            width: 150px;
            height: 40px;
            margin-left: 0;
            margin-bottom: 10px;
          }
        }
      }
    }
    .tips {
      // padding-top: 136px;
      display: flex;
      justify-content: space-between;
      width: 960px;
      margin: 0 auto;
      margin-top: 140px;
      > div {
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        cursor: pointer;
        padding: 23px 0;
        box-sizing: border-box;
        height: 120px;
        width: 19.3%;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        color: #777777;
        /*margin-bottom: 136px;*/
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.16);
        border-top: 10px solid #2b5c9d;
        overflow: hidden;
        border-radius: 10px;
        .head {
          margin-bottom: 21px;
          font-size: 18px;
          font-weight: 500;
          color: #3e3e3e;
        }
      }
      > div:nth-child(2) {
        border-top: 10px solid #0086cd;
      }
      > div:nth-child(3) {
        border-top: 10px solid #7868a7;
      }
      > div:nth-child(4) {
        border-top: 10px solid #f79a6c;
      }
      > div:nth-child(5) {
        border-top: 10px solid #94C47E;
      }
    }
    .map {
      width: 960px;
      margin: 0 auto;
      > div:nth-child(1) {
        display: flex;
        justify-content: space-between;
        .tab {
          width: 350px;
          box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          overflow: hidden;
          transition: all, 1s;
          border-top: 10px solid #00b7d0;
          .active {
            border-radius: 10px;
            overflow: hidden;
            height: auto;
            padding-top:15px;
            padding-bottom:15px;
            > div {
              padding-right: 34px;
              display: block;
              font-size: 14px;
              line-height:24px;
              color: #00242a;
              font-weight: normal;
              margin-top:-6px;
            }
          }
          > div {
            height: 66px;
            font-size: 18px;
            font-weight: bold;
            line-height: 60px;
            color: #00242a;
            text-align: left;
            border-bottom: 1px solid rgba(0, 0, 0, 0.16);
            padding-left: 24px;
            padding-right: 24px;
            box-sizing: border-box;
            position: relative;
            > div {
              display: none;
            }
            img {
              width: 20px;
              height: 9px;
              position: absolute;
              right: 37px;
              top: calc(50% - 4px);
              cursor: pointer;
            }
          }
        }
        .m {
          width: 596px;
          height:470px;
          // height: 100%;
          // background-color: chocolate;
        }
      }
    }
    .join {
      width: 100%;
      margin: 0 auto;
      margin-top: 140px;
      background: linear-gradient(180deg, #ffffff 0%, #f4f4f4 100%);
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding-bottom: 138px;
      >div:nth-child(2){
        border-top: 10px solid #00b7d0;
      }
      .item {
        width: 1200px;
        height: 78px;
        line-height: 78px;
        background: white;
        display: flex;
        justify-content: flex-start;
        margin: 0 auto;
        margin-bottom: 2px;
        > div:first-child {
          width: 340px;
          margin-right: 32px;
          margin-left: 60px;
          text-align: left;
        }
        img {
          width: 18px;
          height: 18px;
          margin: auto 0;
          margin-right: 3px;
        }
        .op {
          margin-left: auto;
          margin-right: 60px;
          width: 20px;
          height: 9px;
        }
      }
    }
  }
.banner{
  width: 100%;
  height: auto;
}
.descs{
  margin: 0px auto;
  width: 960px;
  font-size: 18px;
  font-weight: normal;
  line-height: 36px;
  color: #003F52;
  opacity: 1;
}
.guanggao{
  width: 100%;
  min-width: 1200px;
}
  .adds{
    // width: 100%;
    width: 960px;
    margin: 0px auto;
    background: url('../assets/images/about_b/accomplishment-achievement-adult-1059120@2x.png');
    background-size: 100% 100%;
    height: 285px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border: none !important;

    >div:first-child{
      position: relative;
      box-sizing: border-box;
      padding-left:23px;
      padding-right:38px;
      width: 732px;
      height: 66px;
      line-height:66px;
      font-size:18px;
      color: #00242A;
      background: rgba(255,255,255,0.9);
      border-radius: 10px;
      span {
        position: absolute;
        top:0;
        left:23px;
        width: 70%;
      }
      img {
        position: absolute;
        top:50%;
        margin-top:-5px;
        right:38px;
      }
      .select_content {
        position: absolute;
        box-sizing: border-box;
        top:68px;
        left: 0;
        height: 400px;
        width:732px;
        background: white;
        border-radius: 10px;
        overflow-y: auto;
        .sel_item {
          box-sizing: border-box;
          width:100%;

          .sel_item_title {
            width:100%;
            padding-left:23px;
            padding-right:30px;
            box-sizing: border-box;
            height:66px;
            display:flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            span:nth-child(1) {
              position: static;
              width:80%;
              overflow:hidden;
            }
            span:nth-child(1):hover {
              cursor:auto;
            }
            span:nth-child(2) {
              position: static;
              width:20%;
              text-align: right;
            }
            span:nth-child(2):hover {
              cursor: pointer;
            }
          }
          .sel_item_title:hover {
            background: #cccccc;
          }

          .sel_item_content {
            width:100%;
            padding-left:23px;
            padding-right:30px;
            padding-bottom: 20px;
            box-sizing: border-box;
            .duty_title, .require_title{
              height: 14px;
              font-size: 14px;
              font-weight: bold;
              line-height: 14px;
              color: #003F52;
              opacity: 1;
            }
            .duty_item, .require_item {
               margin-top:20px;
               font-size: 14px;
               font-weight: 300;
               line-height: 18px;
               color: #003F52;
               opacity: 1;
             }
            .require_title {
              margin-top:20px;
            }

          }
          .sel_item_content:hover {
            background: #cccccc;
          }
        }
        .sel_item:hover {
          background: #ccc;
        }
      }
      /*/deep/.el-select{*/
      /*  width: 100%;*/
      /*  height: 100%;*/
      /*  background-color: transparent !important;*/
      /*}*/
      /*/deep/.el-input{*/
      /*  width: 100%;*/
      /*  height: 100%;*/
      /*  background-color: transparent !important;*/
      /*}*/
      /*/deep/.el-input__inner{*/
      /*  width: 100%;*/
      /*  height: 100%;*/
      /*  background-color: transparent !important;*/
      /*  border: none;*/
      /*  font-size: 24px;*/
      /*  font-weight: 400;*/
      /*  line-height: 0px;*/
      /*  color: #00242A;*/
      /*}*/

      /*/deep/ .el-select .el-select-dropdown__item {*/
      /*  height: 66px;*/
      /*  display:block;*/
      /*}*/

      /*/deep/ .el-select .el-select-dropdown__item:hover {*/
      /*  height:auto;*/

      /*}*/
    }
    /*.el-select-dropdown__item.selected {*/
    /*  width: 100%;*/
    /*}*/
    .btn{
      width: 142px;
      height: 66px;
      line-height: 66px;
      text-align: center;
      background: rgba(0,183,208,0.9);
      border-radius: 10px;
      margin-left: 7px;
      font-size: 24px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
  /*.adds{*/
  /*  // width: 100%;*/
  /*  width: 960px;*/
  /*  margin: 0px auto;*/
  /*  background: url('../assets/images/about_b/accomplishment-achievement-adult-1059120@2x.png');*/
  /*  background-size: 100% 100%;*/
  /*  height: 285px;*/
  /*  display: flex;*/
  /*  flex-flow: row nowrap;*/
  /*  align-items: center;*/
  /*  justify-content: center;*/
  /*  border: none !important;*/
  /*  >div:first-child{*/
  /*    width: 732px;*/
  /*    height: 66px;*/
  /*    background: rgba(255,255,255,0.9);*/
  /*    border-radius: 10px;*/
  /*    /deep/.el-select{*/
  /*      width: 100%;*/
  /*      height: 100%;*/
  /*      background-color: transparent !important;*/
  /*    }*/
  /*    /deep/.el-input{*/
  /*      width: 100%;*/
  /*      height: 100%;*/
  /*      background-color: transparent !important;*/
  /*    }*/
  /*    /deep/.el-input__inner{*/
  /*      width: 100%;*/
  /*      height: 100%;*/
  /*      background-color: transparent !important;*/
  /*      border: none;*/
  /*      font-size: 18px;*/
  /*      font-weight: 400;*/
  /*      line-height: 0px;*/
  /*      color: #00242A;*/
  /*    }*/
  /*  }*/
  /*  .btn{*/
  /*    width: 142px;*/
  /*    height: 66px;*/
  /*    line-height: 66px;*/
  /*    text-align: center;*/
  /*    background: rgba(0,183,208,0.9);*/
  /*    border-radius: 10px;*/
  /*    margin-left: 7px;*/
  /*    font-size: 18px;*/
  /*    font-weight: 400;*/
  /*    color: #FFFFFF;*/
  /*  }*/
  /*}*/
  .stabs{
    width: 960px;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding-bottom: 128px;
    div{
      /*flex:1;*/
      /*font-size: 30px;*/
      /*font-weight: 300;*/
      /*line-height: 30px;*/
      /*color: #878787;*/
      /*opacity: 1;*/
      /*width: 0px;*/
      /*height: auto;*/
      cursor: pointer;
      position: relative;

      height: 54px;
      width:134px;
      font-size: 20px;
      font-weight: 300;
      line-height: 27px;
      color: #878787;
      opacity: 1;
    }
    .active{

      font-size: 20px;
      font-weight: bold;
      color: #00242A;
    }
    .active::before{
      content: '';
      position: absolute;
      bottom: -36px;
      left: 50%;
      margin-left: -23px;
      width: 40px;
      height: 10px;
      background: #F37442;
      opacity: 1;
      border-radius: 10px;
    }
  }
  .imgs {
    width: 960px;
    margin: auto;
    .swiper-slide {
      height: 126px;
      padding-top:6px;
    }
    img {
      width: 220px;
      height: 114px;
      margin-left: 0;
      background: #FFFFFF;
      opacity: 1;
      border-radius: 8px;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      /*margin-bottom: 10px;*/
    }
    .aa {
      display:inline-block;
      width: 192px;
      height: 90px;
      border-radius: 8px;
      overflow: hidden;
      font-size: 18px;
      font-weight: 300;
      color: #8f8f8f;
      padding: 40px 24px;
      box-sizing: border-box;
      text-align: left;
      border-top: 10px solid #00b7d0;
      box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
      .blue {
        width: 100%;
        height: 10px;
        background: #00b7d0;
      }
      // img {
      //   width: 150px;
      //   height: 40px;
      //   margin-left: 0;
      //   margin-bottom: 10px;
      // }
    }
  }

</style>
<template>
  <div class="home-cont">
    <navs :active='6'></navs>
    <img src="../assets/images/about_b/关于我们-en.jpg" class="banner">

    <div class="text1">
      <div class="head">
        <p>COMPANY PROFILE</p>
      </div>
      <div class="descs" style="margin-top: 36px">
        SynSense (formerly aiCTX) was established in 2017, and is the world's leading supplier of neuromorphic intelligence and application solutions.SynSense focuses on the research and development of neuromorphic intelligence, based on 20+ years of world’s leading experience of  University of Zürich and ETH  Zürich. Centering on the application scenes of edge computing，SynSense has the full-stack service, and is the only neuromorphic technology company that involves both sensing and computing in the world.
        <br /><br />
        SynSense makes a significant breakthough in commercial neuromorphic chips, which is a crucial step towards cognitive intelligence and intelligent connectivity. SynSense will build the cognition ecology of intelligent connectivity，lead the development of neuromorphic intelligence in the world, and create well-being for the humans in the future.
      </div>

    </div>
    <img class="ban" src="../assets/images/about_b/1-1.png" alt="" />

    <img class="guanggao" src="../assets/images/about_b/1-2en.png" alt="" />
    <div class="head" style="margin-top:80px;margin-bottom:0px;">
      <p>cooperative partners</p>
    </div>
    <div class="cooperation">
      <!-- <div class="head"> -->
      <!-- <p>PARTNER</p> -->
      <!-- <p>COOPERATIVE PARTNER</p>
      <p>合作伙伴</p> -->
      <!-- </div> -->
      <div class="stabs">
        <div :class="stabs==0?'active':''" @click="sliderSwitch(0)">Investment
          Institutions</div>
        <div :class="stabs==1?'active':''" @click="sliderSwitch(1)">Industrial
          Ecology</div>
        <div :class="stabs==2?'active':''" @click="sliderSwitch(2)">Academic
          Cooperation</div>
      </div>
      <!-- <div class="items">
        <div>
          <div style="display:inline-block">
            <img class="ban" style="display:inline-block;" src="../assets/images/about_b/1-1@2x.png" alt="" />
            <span class="ckgd">查看更多</span>
          </div>
        </div>
        <div>
          <div>
            <img class="ban" src="../assets/images/about_b/1-2@2x.png" alt="" style="display:inline-block"/>
            <span class="ckgd">查看更多</span>
          </div>
        </div>
        <div>
          <div>
            <img class="ban" src="../assets/images/about_b/1-3@2x.png" alt="" style="display:inline-block"/>
            <span class="ckgd">查看更多</span>
          </div>
        </div>
      </div> -->
      <div class="imgs" >
        <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions3">
          <swiper-slide v-for="(item, i) in slides" :key="i">
            <img class="ban2" style="display:inline-block;" :src="item" alt="" />
          </swiper-slide>
        </swiper>
      </div>
      <!-- <div class="imgs" v-if="stabs==2">
        <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions3">
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-1@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-2@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-3@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-4@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-5@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-6@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-7@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-8@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-9@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-10@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-11@2x.png" alt="" />
          </swiper-slide>
          <swiper-slide>
              <img class="ban2" style="display:inline-block;" src="../assets/images/cooper/3-12@2x.png" alt="" />
          </swiper-slide>
        </swiper>
      </div> -->
    </div>
    <div class="head">
      <p>CONTACT US</p>
    </div>

    <div class="hmap">
      <div class="map">
        <div>
          <div class="tab">
            <div :class="{ active: mtab == 1 }" @click="goLocation(5)">
              Zürich
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(1)"
              />
              <div>Thurgauerstrasse 40, 8050 Zürich, Switzerland</div>
            </div>
            <div :class="{ active: mtab == 2 }" @click="goLocation(0)">
              Chengdu
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(2)"
              />
              <div>No. 1999-8-5, Yizhou Avenue, Gaoxin District, Chengdu, Sichuan, PR China</div>
            </div>
            <div :class="{ active: mtab == 3 }" @click="goLocation(1)">
              Shanghai
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(3)"
              />
              <div>No. 302, Building 21, ZJ AI Island, Pudong Xin District, Shanghai, PR China</div>
            </div>
            <div :class="{ active: mtab == 4 }" @click="goLocation(2)">
              Shenzhen
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(4)"
              />
              <div>Room 1701, Galaxy Development Center, Fuhua 3rd Road, Futian Central District, Shenzhen，PR China</div>
            </div>
            <div :class="{ active: mtab == 5 }" @click="goLocation(3)">
              Suzhou
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(5)"
              />
              <div>No.398 Ruoshui Road, Suzhou Industrial Park, Suzhou City, Jiangsu Province, PR China</div>
            </div>
            <div :class="{ active: mtab == 6 }" @click="goLocation(4)">
              Nanjing
              <img
                src="../assets/image/about_b/down.png"
                alt=""
                @click="tabs(6)"
              />
              <div>No. 22-98, Dangui Road, Pukou District, Nanjing, PR China</div>
            </div>
          </div>
          <div class="m" id="container"></div>
        </div>
      </div>
      <div class="tips">
        <div>
          <div class="head">Business</div>
          <div>business@synsense.ai</div>
        </div>
        <div>
          <div class="head" style="margin-top:8px; margin-bottom:10px;">Sales</div>
          <div>sales@synsense.ai</div>
          <div style="margin-top:10px; font-size:14px;">+86-0755-8382-9305</div>
        </div>
        <div>
          <div class="head">Marketing</div>
          <div>media@synsense.ai</div>
        </div>
        <div>
          <div class="head">Recruitment</div>
          <div>hr.cn@synsense.ai</div>
        </div>
        <div>
          <div class="head">After-sale Service</div>
          <div>fae@synsense.ai</div>
        </div>
      </div>
    </div>

    <div class="join">
      <div class="head">
        <p>JOIN US</p>
      </div>

      <!-- <img src="../assets/images/about_b/adden.png" alt="" class="adds"/> -->
<!--      <div class="adds">-->
<!--      <div>-->
<!--        <el-select v-model="value" placeholder="AI Software Engineer">-->
<!--          <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value">-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </div>-->
<!--      <div class="btn">Learn more</div>-->
<!--    </div>-->
      <div class="adds">
        <div class="">
          <span ref="selectText"></span>
          <img @click="goSwitch" src="../assets/images/about_b/down_arr.png" width="20" height="9">
          <div class="select_content" ref="selectContent" style="display: none;">
            <div class="sel_item" v-for="(item, i) in options" :key="i"  @click="goPicker(i)">
              <div class="sel_item_title">
                <span>{{ item.label }}</span>
                <span @click.prevent.stop="goSlide(i)">{{item.isShow ? 'COLLAPSE' : 'EXPAND' }}</span>
              </div>
              <div class="sel_item_content" v-if="item.isShow">
                <div class="duty_title">DUTY</div>
                <div v-for="(value, index) in item.duty" class="duty_item">{{value}}}</div>
                <!--                <div>2、负责管理维护我们的开源Python库，以及内部库</div>-->
                <!--                <div>3、负责构建算法开发软件工具链，确保算法开发过程顺利执行</div>-->
                <!--                <div>4、期望您能利用您的机器学习经验和背景，优化我们的算法开发流程</div>-->
                <!--                <div>5、编写代码、单元测试和文档，确保我们的Python库达到世界一流水平</div>-->
                <!--                <div>6、搭建CI和部署环境</div>-->
                <div class="require_title">REQUIRES</div>
                <div v-for="(rValue, index) in item.requires" class="require_item">{{rValue}}}</div>
              </div>
            </div>
          </div>
          <!--          <el-select v-model="value" placeholder="请选择" class="sel">-->
          <!--          <el-option-->
          <!--            v-for="item in options"-->
          <!--            :key="item.value"-->
          <!--            :label="item.label"-->
          <!--            :value="item.value">-->
          <!--            <div style="display:block;            background: #FFFFFF;-->
          <!-- height:auto;width:100%;">-->
          <!--              <div style="height:176px;">-->
          <!--                <span style="-->
          <!--            float:left;-->
          <!--            width:90%;-->
          <!--            height: 66px;-->
          <!--            opacity: 1;-->
          <!--            line-height:66px;-->
          <!--            font-size: 18px;-->
          <!--            color: #00242A;-->
          <!--">{{item.label}}</span>-->
          <!--                <span style="-->
          <!--              float:right;-->
          <!--              height:66px;-->
          <!--              font-size:18px;-->
          <!--              line-height:66px;-->
          <!--              color: #00242A;-->
          <!--            ">-->
          <!--                  展开-->
          <!--                </span>-->
          <!--              </div>-->
          <!--              <div>1112sdfasdfasdfdsdas2333</div>-->
          <!--            </div>-->



          <!--          </el-option>-->
          <!--        </el-select>-->
        </div>
      </div>
      <!-- <div class="item"> -->
      <!-- </div> -->

    </div>
    <div class="stories-block" style="display: none">
      <swiper style="width: 100%" ref="mySwiper" :options="swiperOptions2">
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智能玩具1-未选中@2x.png" alt="" />
            <img src="../assets/image/智能玩具1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智慧养殖1-未选中@2x.png" alt="" />
            <img src="../assets/image/智慧养殖1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智能家居1-未选中@2x.png" alt="" />
            <img src="../assets/image/智能家居1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
        <swiper-slide>
          <div class="stories-img-block">
            <img src="../assets/image/智能安防1-未选中@2x.png" alt="" />
            <img src="../assets/image/智能安防1-选中@2x.png" alt="" />
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <bases></bases>
  </div>
</template>

<script>
import navs from "@/components/navsen.vue";
import bases from "@/components/basesen.vue"
export default {
  name: "Home",
  components: {
    navs,
    bases
  },
  data() {
    return {
      value: "",
      isfixed: false,
      options: [
        {
          value: '选项1',
          label: 'SW Engineer for ML — Zürich',
          isShow:false,
          duty: [
            'Collaborate with others in our research team, both in Zürich and in China',
            'Take ownership of our public open-source python packages, as well as our in-house packages',
            'Be responsible for our SW Engineering pipeline, making sure that development processes are followed correctly',
            'Use your ML experience and background to suggest better algorithms and approaches',
            'Write code, unit tests and documentation to ensure our public and internal packages are world-class',
            'Manage CI and deployment infrastructure',
          ],
          requires: [
            'Expert Python',
            'C++ experience',
            'SW Engineering background',
            'Experience with unit testing frameworks for Python',
            'Experience with documentation frameworks for Python',
            'Good spoken and written English ability',
            'Fluent with Git and version control tools like gitlab',
            'Ability to work locally in Zürich or in China',
          ],
        },{
          value: '选项21',
          label: 'Research Engineer for SynSense Zürich',
          isShow:false,
          duty:[
            'Our future lab at SynSense Zürich is responsible for integrating in-memory computing and neuromorphic computing together, with next-generation memory devices. We are looking for candidates who are motivated to develop next-generation low-power AI processors. You will work with other team members that develops our event-based neuromorphic and in-memory computing simulator with PyTorch based Python libraries. You will perform individual research into energy-efficient neuromorphic and in-memory computing algorithms and circuits, evaluate them, and refine them as necessary. You will also review other team-member’s output (algorithms, code and circuit design) and contribute to our development processes and documentation.',

      'SynSense is a small but growing startup, with many young and highly motivated ex-academic employees. We have a very open-minded approach to our work. Your opinions and experience won’t just be highly valued — they will be crucial for us to advance as a company.',

      'We are a highly diverse and international team, and strongly encourage applicants that will increase our diversity. We are an equal-opportunity employer.',

      'This position is for an open-ended full-time contract.',

      'Interested? Please apply to: jobs_zh@synsense.ai',
          ],
          requires: [
            'MSc in computer science, electrical engineering or equivalent, PhD preferred',
            'You have research experience with developing hardware-based spiking neural networks.',
          'Or, you have research experience with developing in-memory computing systems',

          'You are comfortable working in an agile team',
          'You have good spoken and written English ability',
          'You have a Swiss or EU passport, or Swiss residence with existing right to work (B/C permit)',
          ],
        },{
          value: '选项31',
          label: 'Algorithms position Zürich',
          isShow:false,
          duty:[
            'Work with our in-house neuromorphic hardware to build real-time ML application demonstrators and prototype products',
            'Collaborate with others in our research team, both in Zürich and in China',
            'Share your past experience with neural network models and architectures so we can all grow our expertise',
            'Guide our hardware design engineers in tuning future neuromorphic hardware designs to match application needs',
            'Write white papers and documentation to communicate your results within SynSense and to clients and investors',
            'Write a bunch of Python code',
            'Collaborate\' with external research partners, both academic and commercial, to fulfil tasks under externally-funded projects',
            'Stay up-to-date with current ML trends and research, while designing new architectures',
            'Have the possibility to travel from time to time, for collaboration or to conferences',
          ],
          requires:[
            'Expert coding skills (Python preferred)',
            'Good spoken and written English ability',
            'Experience with training deep neural networks',
          ],
        },{
          value: '选项41',
          label: 'FPGA prototyping engineer',
          isShow:false,
          duty:[
            'Work with the chip design and system integration teams to build event processing pipelines on FPGAs.',
            'Develop prototype FPGA systems for interfacing with new chip architectures.',
            'Develop example prototype FPGA solutions for customers using our novel products.',
            'Discuss requirements for product design with other teams and senior staff',
          ],
          requires:[
            'You have experience managing automated design flow projects for FPGA using Xilinx tools.',
            'You have experience with event based processing.',
            'You have knowledge of asynchronous systems.',
            'You have experience with embedded Linux on SoC-FPGA systems.',
            'You have experience with FPGA designs containing multiple clock domains.',
            'You are comfortable hacking on freshly-built silicon, and convincing it to do what you want.',
            'You have prior experience working with neuromorphic hardware.',
            'You’re fun.',
          ],
        },{
          value: '2',
          label: 'Senior Test Development Engineer for SynSense Zürich',
          isShow:false,
          duty:[
            'Generation of test programs for engineering and mass production',
            'Test hardware development and production tester functionality/operation',
            'Involvement in the characterization of new IC products',
            'Test data collection and data analysis',
            'Yield enhancement and test time reduction',
            'IC failure debugging',
            'Leading and managing sub-contractors',
          ],
          requires:[
            'B.Sc/M.Sc in Electrical Engineering',
            '5 years of experience in the semiconductor industry',
            'Proven knowledge of IC test engineering and test program development',
            'Strong experience with programming languages such as C/C++/Python',
            'Experience generating test patterns and analyzing and debugging test failures',
            'Automated Test Equipment (ATE) knowledge such as Advantest, LTX, Credence, Teradyne',
          ],
        },{
          value: '3',
          label: 'Software Engineer for SynSense Zürich— Developer-tooling and libraries',
          isShow:false,
          duty:[
            'Our system integration team at SynSense Zürich is responsible for developing low level firmware, drivers, and higher level developer libraries and tooling for our low-power ML inference hardware. \n' +
            'We develop the tools that are used by our customers and in-house ML engineers to develop applications that utilize our hardware. This includes embedded software and drivers for communicating directly with our devices and higher-level libraries for working with event based data. Our task is to make developing applications for our novel computing hardware easy and familiar to ML engineers and data scientists who are used to working with libraries such as PyTorch and Tensorflow.\n' +
            'We are looking for candidates who are motivated to develop libraries and applications that primarily work with event-based data. You will be part of the team that develops our higher-level software utilities for working with event streams, filtering, visualization and interfacing with PyTorch based Python libraries. You will work with other members of the team to design event-based APIs, implement them, test them, and refactor them as necessary. You will also review other team-member’s code and contribute to our development processes and documentation as well as our CI/CD.\n' +
            'SynSense is a small but growing startup, with many young and highly motivated ex-academic employees. We have a very open-minded approach to our work. Your opinions and experience won’t just be highly valued — they will be crucial for us to advance as a company.\n' +
            'We are a highly diverse and international team, and strongly encourage applicants that will increase our diversity. We are an equal-opportunity employer.\n' +
            'This position is for an open-ended full-time contract.\n' +
            'Sounds interesting? Send us your application to jobs_zh@synsense.ai, including the job title in the Subject.'
          ],
          requires:[
            'You have a university degree in computer science, software/electrical engineering or equivalent',
            'You have 5+ years of experience writing modern C++ applications',
            'You have experience with git',
            'You are comfortable working on Linux systems',
            'You are comfortable working in an agile team',
            'You have good spoken and written English ability',
            'You have a Swiss or EU passport, or Swiss residence with existing right to work (B/C permit)',
          ],
        }],
      stabs:0,
      isfixed: false,
      mtab: 1,
      tablelistIndex: 0,
      swiperOptions: {
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        pagination: {
          el: ".swiper-pagination",
        },
        navigation: {
          nextEl: ".rightIcon",
          prevEl: ".leftIcon",
        },
        speed: 300,
        // on: {
        //   // 监听滑动切换事件，返回swiper对象
        //   slideChange: () => {
        //     const i = this.swiper.activeIndex
        //     this.changepicture(i)
        //   }
        // }
        // Some Swiper option/callback...
      },
      swiperOptions1: {
        slidesPerView: 4,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
      swiperOptions2: {
        slidesPerView: 3,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 1000,
      },
      swiperOptions3: {
        slidesPerView: 4,
        loop: true, // 循环切换
        // grabCursor: true, //设置为true时，鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        // setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，
        // 开启这个设定会在Wrapper上添加等于slides相加的宽或高，
        // 在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: false, // 自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化。
        autoplay: true, // 可选选项，自动滑动
        observeParents: false, // 当改变swiper的样式（例如隐藏/显示）或者修改swiper的子元素时，自动初始化swiper
        speed: 500,
        observer:true,
        observerParents:false
      },
      slides: [],
      center: [
        [104.071097,30.536551],
        [121.605045,31.178305],
        [114.061539,22.533728],
        [120.671057,31.301196],
        [118.55277,31.954632],
        [8.551858,47.415059],
      ],
      slidesOne: [
        require('../assets/images/cooper/11-1@2x.png'),
        require('../assets/images/cooper/11-2@2x.png'),
        require('../assets/images/cooper/11-3@2x.png'),
        require('../assets/images/cooper/11-4@2x.png'),
        require('../assets/images/cooper/11-5@2x.png'),
        require('../assets/images/cooper/11-6@2x.png'),
        require('../assets/images/cooper/11-7@2x.png'),
        require('../assets/images/cooper/11-8@2x.png'),
        require('../assets/images/cooper/11-9@2x.png'),
        require('../assets/images/cooper/11-10@2x.png'),
      ],
      slidesTwo: [
        require('../assets/images/cooper/2-1@2x.png'),
        require('../assets/images/cooper/2-2@2x.png'),
        require('../assets/images/cooper/2-3@2x.png'),
        require('../assets/images/cooper/2-4@2x.png'),
        require('../assets/images/cooper/2-5@2x.png'),
        require('../assets/images/cooper/2-6@2x.png'),
        require('../assets/images/cooper/2-7@2x.png'),
        require('../assets/images/cooper/2-8@2x.png'),
        require('../assets/images/cooper/2-9@2x.png'),
        require('../assets/images/cooper/2-10@2x.png'),
        require('../assets/images/cooper/2-11@2x.png'),
        require('../assets/images/cooper/2-12@2x.png'),
        require('../assets/images/cooper/2-13@2x.png'),
        require('../assets/images/cooper/2-14@2x.png'),
      ],
      slidesThree: [
        require('../assets/images/cooper/3-1@2x.png'),
        require('../assets/images/cooper/3-2@2x.png'),
        require('../assets/images/cooper/3-3@2x.png'),
        require('../assets/images/cooper/3-4@2x.png'),
        require('../assets/images/cooper/3-5@2x.png'),
        require('../assets/images/cooper/3-6@2x.png'),
        require('../assets/images/cooper/3-7@2x.png'),
        require('../assets/images/cooper/3-9@2x.png'),
        require('../assets/images/cooper/3-8@2x.png'),
        require('../assets/images/cooper/3-10@2x.png'),
        require('../assets/images/cooper/3-11@2x.png'),
        require('../assets/images/cooper/3-12@2x.png'),
      ]
    };
  },
  mounted() {
    this.sliderSwitch(this.stabs);
    window.addEventListener("scroll", this.scrollToTop);
    // const that = this;
    // window.onresize = () =>
    //   (() => {
    //     that.setBGblock();
    //   })();
    // this.setBGblock();

    window._AMapSecurityConfig = {
      serviceHost: "http://yslk.jiu.mzdxj.cn/_AMapService",
      // 例如 ：serviceHost:'http://1.1.1.1:80/_AMapService',
    };

    var map = new AMap.Map("container", {
      resizeEnable: true,
      // center: [113.263298, 23.123622],
      center: this.center[5],
      zoom: 16,
      vectorMapForeign:'style_zh_cn',
    });

    var marker = new AMap.Marker({
      position: new AMap.LngLat(this.center[5][0], this.center[5][1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
      // title: '1577号',
    });

    map.add(marker);
    //实时路况图层
    var trafficLayer = new AMap.TileLayer.Traffic({
      zIndex: 10,
    });
    trafficLayer.setMap(map);
  },
  methods: {
    goPicker(index) {
      this.$refs.selectText.innerHTML = this.options[index].label;
      this.$refs.selectContent.style.display = "none";
      this.selShow = 0;
    },
    goSlide(index) {
      let temp = !this.options[index].isShow;

      for(let i = 0 ; i < this.options.length; i++) {
        this.options[i].isShow = false;
      }
      this.options[index].isShow = temp;

    },
    goSwitch() {
      if(this.selShow) {
        this.$refs.selectContent.style.display = "none";
        this.selShow = 0;
        for(let i = 0 ; i < this.options.length; i++) {
          this.options[i].isShow = false;
        }
      } else {
        this.$refs.selectContent.style.display = "block";
        this.selShow = 1;
      }
    },
    goLocation(idx) {
      console.log(1);
      // this.center = [114.061539,22.533728];
      // this.center = [113.263298, 23.123622];
      // center: [113.263298, 23.123622],

      var map = new AMap.Map("container", {
        resizeEnable: false,
        center: this.center[idx],
        // center: [113.263298, 23.123622],
        zoom: 16,
        vectorMapForeign:'style_zh_cn',
      });
      // window.map = map;

      var marker = new AMap.Marker({
        position: new AMap.LngLat(this.center[idx][0], this.center[idx][1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        // title: '1577号',
      });

      map.add(marker);
      //实时路况图层
      var trafficLayer = new AMap.TileLayer.Traffic({
        zIndex: 10,
      });
      trafficLayer.setMap(map);
    },
    tabs(a) {
      this.mtab = a;
    },
    scrollToTop() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop >= 30) {
        this.isfixed = true;
      } else {
        this.isfixed = false;
      }
    },
    sliderSwitch(i){

      this.stabs = i;
      if(i === 1){
        this.slides = this.slidesTwo;
      }
      if(i === 2){
        this.slides = this.slidesThree;
      }
      if(i === 0){
        this.slides = this.slidesOne;
      }
      this.$refs.mySwiper.swiper.destroy()   //先销毁
      this.$refs.mySwiper.mountInstance()
      console.log(this.slides);
    },
    setBGblock() {
      this.$refs.bgBlcok.style.width = `${
        380 + this.$refs.aboutUsCont.getBoundingClientRect().left
      }px`;
    },
  },
};
</script>


